import { FunctionComponent } from 'react';
import { Typography, Box } from '@mui/material';
import { ControlProps, FormProps } from 'models/form';
import { ResendEmailRequest } from 'data/types/userTypes';
import { Input } from '../../UI/Input';

type Props = {
	classes: any;
	recoverEmail: (email : any) => void;
	formProps: FormProps;
	email: any;

	controlProps: Omit<ControlProps, 'name'>;
};

const PasswordRecoveryView: FunctionComponent<Props> = (props) => (

	<Box ml={2} mt={20}>
		<Typography
			fontFamily={'Krub'}
			fontSize={24}
			color='#FF6651'
			fontWeight='bold'
			variant='h1'
			align='left'
		>
			Por favor, verificá tu email
		</Typography>
		<Input
			controllerProps={{
				control: props.controlProps.control,
				defaultValue:
				props.controlProps.defaultValue.email,
				name: 'email',
			}}
			type='hidden'
			errors={props.formProps.errors}/>
		<Typography
			fontFamily={'Krub'}
			fontSize={18}
			color='#80777E'
			fontWeight='bold'
			variant='body2'
			align='left'
			mt={4}
		>
			Te enviamos un mensaje a
		</Typography>
		<Typography
			fontFamily={'Krub'}
			fontSize={20}
			color='#FF6651'
			variant='body2'
			align='left'
			mt={1}
		>
			{props.email}
		</Typography>
		<Typography
			fontFamily={'Krub'}
			fontSize={16}
			color='#80777E;'
			fontWeight='normal'
			variant='body2'
			align='left'
			width={320}
			mt={2}
		>
			Restablece tu contrasera haciendo click en el enlace que aparece en el email.
		</Typography>
		{ <Typography
			fontFamily={'Krub'}
			fontSize={18}
			color='#80777E;'
			fontWeight='bold'
			variant='body2'
			align='left'
			width={320}
			mt={4}
		>
			¿No recibiste el email?
			<span
				className={props.classes.linkEmail}
				onClick={props.formProps.handleSubmit(props.recoverEmail)}
			>
				Reenviar el email
			</span>
		</Typography> }
	</Box>
);

export default PasswordRecoveryView;
