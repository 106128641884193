const namesRoutes = {
	signIn: '/signIn',
	register: '/register',
	recoverPassword:'/recoverPassword',
	resetPassword: '/resetPassword/:code',
	changePasswordSuccessfully : '/changePasswordSuccessfully',
	registerLibrary: '/registerLibrary/:code',
	registerDistributors: '/registerDistributors',
	home: '/',
	catalog: '/catalog',
	cart: '/cart',
	orders: '/orders',
	orderDetail: '/orderDetail/:orderId',
	viewItem: '/viewItem/:id',
	aboutDeUtiles: '/aboutDeUtiles',
	faq: '/faq',
	termsandconditions: '/termsAndConditions',
	privacyPolicies: '/privacyPolicies',
};

export default namesRoutes;
