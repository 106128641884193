import { Autocomplete as AutocompleteMUI, TextFieldProps } from '@mui/material';
import { ControlProps } from 'models/form';
import { FunctionComponent, Fragment } from 'react';
import { Input } from './Input';

type Props = {
    controllerProps: ControlProps;
    errors?: any;
    onNewValue?: (d: {label: string, id: string}) => void;
    borderRadius?: {
        borderTopLeftRadius: string;
        borderBottomRightRadius: string;
        borderTopRightRadius: string;
        borderBottomLeftRadius: string;
    };
    options: {label: string, id: string}[];
    onClear?: (d: {label: string, id: string}) => void;
} & TextFieldProps;

export const Autocomplete: FunctionComponent<Props> = ({
    ...props
}) => {

    return (
        <Fragment>
            <AutocompleteMUI
                disablePortal
                options={props.options}
                defaultValue={props.controllerProps?.defaultValue}
                onChange={(d, e, r) => { (r == "selectOption" && props.onNewValue && props.onNewValue(e as any)) || (r == "clear" && props.onClear && props.onClear(e as any)) }}
                renderInput={
                    (params: any) =>
                        <Input
                            {...params}
                            controllerProps={props.controllerProps}
                            errors={props.errors}
                            placeholder={props.placeholder}
                        />
                }
            />
        </Fragment>
    );
};
