import { Box } from '@mui/material';


export const ErrorView = () => {
	return (
		<Box
			sx={{
				backgroundColor: 'rgb(255,0,0,0.6)',
				color: 'white',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '180px',
				borderRadius: '15px',
				height: '80px',
			}}
		>
			Por favor complete su informacion de usuario con el mail recibido.
		</Box>
	);
};
