import React, { useEffect, useState } from 'react';
import { RightSectionRegisterDistributorsView } from './view';
import { rulesEmail, rulesPassword } from 'utils/validationRules';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAllDistributors, useRegisterDistributor } from './hooks';
import { useAppSelector } from 'data/global/hooks';
import { selectDistributor } from 'data/global/features/DistributorSlice/distributorSlice';
import { StatusRequest } from 'data/types';
import { selectUser, setUserLocation } from 'data/global/features/UserSlice/userSlice';
import { useDispatch } from 'react-redux';
import { buenosAiresLocations } from './buenosAiresLocations';
import { ErrorView } from './ErrorView';

export const RightSectionRegisterDistributors: React.FC = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	useGetAllDistributors();

	const { location } = useAppSelector(selectUser);

	const dispatch = useDispatch();

	const registerDistributor = useRegisterDistributor();

	const { distributors, putDistributorStatusRequest, distributorStatusError } =
		useAppSelector(selectDistributor);

	useEffect(() => {
		const savedLocation = localStorage.getItem('location');
		if (savedLocation) {
			dispatch(setUserLocation(savedLocation));
		}
	}, []);

	useEffect(() => {
		localStorage.setItem('location', location);
	}, [location, distributors]);

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const rules = {
		rulesEmail: rulesEmail(t),
		rulesPassword: rulesPassword(t),
	};

	let filteredDistributors = distributors;

	if (buenosAiresLocations.includes(location)) {
		filteredDistributors = distributors.filter(distributor => distributor.distributorName !== 'Four Witch');
	}

	const defaultValue = filteredDistributors || {
		distributor1: '',
		distributor2: '',
		distributor3: '',
		distributor4: '',
	};

	const options = filteredDistributors.map((distributor) => {
		return {
			label: distributor.distributorName,
			id: distributor.distributorID,
		};
	});

	useEffect(() => {
		if (putDistributorStatusRequest === StatusRequest.fulfilled) {
			window.location.href = '/';
		} else if (putDistributorStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [putDistributorStatusRequest]);

	return (
		<>
			{filteredDistributors.length > 0 ? (
				<RightSectionRegisterDistributorsView
					formProps={{ handleSubmit, errors, isSubmitting, isValid }}
					controlProps={{ control, defaultValue, rules }}
					distributors={filteredDistributors}
					registerDistributor={registerDistributor}
					error={distributorStatusError}
					loading={loading}
					options={options}
				/>
			) : (
                <ErrorView/>
			)}
		</>
	);
};
