import { Box, Grid, Typography } from "@mui/material";
import { FunctionComponent } from "react";

type Props = {
};

export const LeftSectionRegisterDistributors: FunctionComponent<Props> = ({...props}) => (
    <Box pt={10}>
		<Grid container>
			<Grid item xs={12}>
				<Typography variant='h3' color='secondary' fontWeight={1000}>
					Excelente!
				</Typography>
			</Grid>
            <Grid item xs={12}>
				<Typography variant='h4' color='secondary'>
					Ya sos parte de la comunidad
				</Typography>
			</Grid>
            <Grid item xs={10} pr={2} pt={3}>
				<Typography variant='h6' color='red'>
                    Seleccioná hasta 4 distribuidores de tu confianza para ver sus cotizaciones en el catálogo.
				</Typography>
			</Grid>
            <Grid item xs={10} pr={2} pt={3}>
				<Typography variant='body1'>
                    No se te ocurren cuatro?
				</Typography>
                <Typography variant='body1'>
                    No te preocupes. <b>DeÚtiles seleccionará a los restantes según la ubicación de tu librería</b> para que puedas aprovechar al máximo nuestros beneficios.
				</Typography>
			</Grid>
		</Grid>
	</Box>
);