import { useState } from 'react';
import { ViewColumnOrderDetailDistributor } from './view';
import { FunctionComponent } from 'react';
import { GetSubOrderAsync } from 'data/global/features/OrdersSlice/ordersSlice';
import { useAppDispatch } from 'data/global/hooks';
interface Props {
	subOrder?: any;
	status?: string;
	facturacion?: string;
	items: number;
	total?: any;
	entrega?: string;
}

export const ColumnOrderDetailDistributor: FunctionComponent<Props> = ({
	subOrder,
	status,
	facturacion,
	items,
	total,
	entrega,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useAppDispatch();

	const getSuborderItems = (subOrder: string) => {
		dispatch(GetSubOrderAsync({ subOrderId: subOrder }));
	};

	return (
		<ViewColumnOrderDetailDistributor
			setOpenModal={setOpenModal}
			openModal={openModal}
			subOrder={subOrder}
			status={status}
			facturacion={facturacion}
			items={items}
			total={total}
			entrega={entrega}
			getSuborderItems={getSuborderItems}
		/>
	);
};
