import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

type Props = {};

export const ErrorText: FunctionComponent<Props> = (props) => (
	<Grid
		direction="row"
		container
		alignItems="center"
		mt={1}
		ml={2}
		>
		<i
			className='icon-warning'
			style={{
				color: "#E01638",
			}}
		></i>
		<Typography color="#E01638" ml={1}>

			{props.children}
		</Typography>
	</Grid>
);
export default ErrorText;
