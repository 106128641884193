import { useAppDispatch } from 'data/global/hooks';
import { GetProductAsync } from 'data/global/features/ProductsSlice/productsSlice';

export const useGetProductById = () => {
	const dispatch = useAppDispatch();

	const getProductById = (id: string): void => {
		dispatch(GetProductAsync({masterProductID: id}));
	};

	return getProductById;
};

export default useGetProductById;
