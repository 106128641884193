export const regexSpecialChracter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const rulesName = (texts: any) => ({
	required: texts('error.required'),
	minLength: {
		value: 3,
		message: texts('error.min_character', { numero: 3 }),
	},
});

export const rulesEmail = (texts: any) => ({
	required: texts('error.required'),
	pattern: {
		value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
		message: texts('error.email_invalid'),
	},
});

export const rulesPassword = (texts: any) => ({
	required: texts('error.required'),
	pattern: {
		value:
			/(?=(.*[0-9]))(?=.*[a-z])(?=(.*)).{6,}/gim,
		message: texts('error.password_invalid'),
	},
	minLength: {
		value: 6,
		message: texts('error.min_character', { numero: 6 }),
	},
});

export const rulesCUIT = (texts: any) => ({
	required: texts('error.required'),
	pattern: {
		value: /^(20|23|24|27|30|33|34)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/g,
		message: 'CUIT invalido',
	},
});

export const patternCUIT = /^(?:\d{2}-\d{8}-\d|\d{11})$/

export const validationCUIT = { required: "CUIT requerido", pattern: { value: patternCUIT, message: "CUIT inválido" } }
