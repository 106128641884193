import InputBase from '@mui/material/InputBase';
import { InputBaseProps, InputAdornment } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { FunctionComponent, Fragment } from 'react';

type Props = {
	onSend: (data: string) => void
} & InputBaseProps;

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: '8px',
	border: '1px solid #EDEBED',
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	width: '100%',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	width: '100%',
	paddingRight: '1em',
	backgroundColor: 'white',
	'& .MuiInputBase-input': {
		padding: '12px 2em 12px 1em',
	},
}));

export const SearchBar: FunctionComponent<Props> = ({ ...props }) => {
	return (
		<Search>
			<StyledInputBase
				placeholder='Buscar'
				inputProps={{ 'aria-label': 'search' }}
				onKeyDown={(e) => { if(e.nativeEvent.key === 'Enter') { props.onSend((e.target as any).value) } }}
				defaultValue={props.value}
				endAdornment={
					<InputAdornment position='end'>
						<i className='icon-search'></i>
					</InputAdornment>
				}
			></StyledInputBase>
		</Search>
	);
};
