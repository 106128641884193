import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { NavBarHome } from 'components';
import { ColumnOrderDetailDistributor } from 'components/ColumnOrderDetailDistributor';
import { GetOrderResponse } from 'data/types/ordersTypes';
import { orderBy } from 'lodash';
import { Fragment, FunctionComponent } from 'react';

type Props = {
	order: GetOrderResponse | null;
};

export const ViewOrderDetail: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Box sx={{ backgroundColor: '#F7F5F7' }} height='95vh'>
			<NavBarHome></NavBarHome>
			<Grid container direction={'row'} bgcolor='white' pt={4} px={8}>
				<Grid item xs={4}>
					<Typography>Detalle Cotización</Typography>
					<Typography fontSize={25} fontWeight={700}>
						{props.order?.orderID.slice(-10)}
					</Typography>
				</Grid>
				<Grid item xs={8} pt={3}>
					<Grid container height={70} bgcolor='#9179E8'>
						{props.order?.distributorList.map((distributor: string) => {
							return (
								<Grid
									item
									xs={3}
									bgcolor='#5C41BD'
									color='white'
									key={distributor}
								>
									<Typography mt={2} ml={2}>
										{distributor}
									</Typography>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
			<Grid container direction={'row'} pt={4} px={8}>
				<Grid item xs={4}>
					<Grid container direction={'column'}>
						<Grid item>
							<Grid container direction={'row'} mb={3}>
								<Box mr={2}>
									<i
										className='icon-calendar'
										style={{
											color: '#5C41BD',
											width: 20,
										}}
									></i>
								</Box>
								<Box>
									<Typography>Creación</Typography>
									<Typography fontWeight={600}>
										{props.order?.creationDate}
									</Typography>
								</Box>
							</Grid>
							<Grid container direction={'row'} mb={3}>
								<Box mr={2}>
									<i
										className='icon-cart'
										style={{
											color: '#5C41BD',
											width: 20,
										}}
									></i>
								</Box>
								<Box>
									<Typography>Total items</Typography>
									<Typography fontWeight={600}>
										{props.order?.itemsInOrder}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<Grid container bgcolor='#EDEBED' mt={-4}>
						{props.order?.distributors.map((distributor) => {
							return (
								<Grid
									item
									xs={3}
									bgcolor='white'
									px={2}
									pt={2}
									key={distributor.distributorID}
								>
									<ColumnOrderDetailDistributor
										subOrder={distributor.subOrderID}
										status={distributor.status}
										facturacion={distributor.facturacion}
										items={distributor.items}
										total={distributor.totalDistributor}
										entrega={distributor.entrega}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
			<Grid container direction={'row'} bgcolor='white' px={8}>
				<Grid item xs={4}>
					<Typography color={'#5C41BD'} fontSize={20} fontWeight={600} mt={1}>
						Total por mayorista
					</Typography>
					<Typography fontSize={15} fontWeight={700} color={'#27C451'}>
						Delivery
					</Typography>
				</Grid>
				<Grid item xs={8}>
					<Grid container height={70} bgcolor='#9179E8'>
						{props.order?.distributors.map((distributor) => {
							return (
								<Grid
									item
									xs={3}
									bgcolor='#5C41BD'
									color='white'
									key={distributor.distributorID}
								>
									<Typography align='right' pr={3} pt={1} fontWeight='bold'>
										${Number(distributor.totalDistributor).toFixed(2)}
									</Typography>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				height={70}
				bgcolor={'#FF6651'}
				color={'white'}
				pt={3}
				pr={8}
				direction='row-reverse'
			>
				<Typography display={'inline'} fontSize={20} fontWeight={700}>
					{props.order?.totalPrice.toFixed(2)}
				</Typography>
				<Typography display={'inline'} mr={1} mt={0.3} fontWeight={600}>
					$
				</Typography>
				<Typography display={'inline'} mr={1} mt={0.3} fontWeight={600}>
					Total
				</Typography>
			</Grid>
		</Box>
	</Fragment>
);
