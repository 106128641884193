import { FunctionComponent, Fragment } from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';

type Props = {};

const BreadCrumbsViews: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Breadcrumbs
			separator={
				<i className='icon-arrow-forward' style={{ color: '#FF6651' }}></i>
			}
		>
			<Link href='/' color='inherit' underline='none'>
				deÚtiles
			</Link>
			{/* aca deberiamos enviar la categoria que fue seleccionada */}
			<Typography>Papel y embalajes</Typography>
		</Breadcrumbs>
	</Fragment>
);

export default BreadCrumbsViews;
