import { FunctionComponent } from 'react';
import { AppBar, Grid } from '@mui/material';
import logo from 'fonts/logo.svg';
import logoRegister from 'fonts/logoRegister.svg';
import logoRegisterLibrary from 'fonts/logoRegisterLibrary.svg';

type Props = {
	navRegisterLibrary?: Boolean;
};

const NavBarRegister: FunctionComponent<Props> = (props) => (
	<AppBar position='static' color='transparent' elevation={0}>
		<Grid container display='flex' justifyContent='space-between' mt={3} mb={1}>
			<Grid item xs={2} ml={4}>
				{props.navRegisterLibrary ? (
					<img
						src={logoRegisterLibrary}
						alt='logo'
						style={{
							width: '158px',
							height: '35px',
						}}
					></img>
				) : (
					<img
						src={logoRegister}
						alt='logo'
						style={{
							width: '158px',
							height: '35px',
						}}
					></img>
				)}
			</Grid>
			<Grid item xs={2}>
				<img
					src={logo}
					alt='logo'
					style={{
						width: '158px',
						height: '35px',
					}}
				></img>
			</Grid>
		</Grid>
	</AppBar>
);

export default NavBarRegister;
