import { FunctionComponent } from 'react';
import { Button as ButtonMUI, ButtonProps } from '@mui/material';

type Props = {
} & ButtonProps;

export const Button: FunctionComponent<Props> = ({ ...props }) => (
	<ButtonMUI {...props}>
		{props.children}
	</ButtonMUI>
);
