import { Fragment, FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import { NavBarHome } from 'components';

type Props = {};

export const ViewAboutDeUtiles: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome />
		<Grid container>
			<Grid item xs={10} m={6} mt={12}>
				<Typography
					variant='h1'
					fontSize={30}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
				>
					Sobre deÚtiles
				</Typography>
			</Grid>
			<Grid item xs={10} ml={6}>
				<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
					deÚtiles es una plataforma que tiene el objetivo de ayudar a
					digitalizar y eficientizar la industria de librerías. Es un canal
					digital para que librerías y distribuidores puedan agilizar sus
					compras y ventas respectivamente. Siendo una herramienta de ayuda para
					hacer más fácil el día a día de la industria. Una iniciativa que nace
					por y para la industria de librerías.
				</Typography>
			</Grid>
		</Grid>
	</Fragment>
);

export default ViewAboutDeUtiles;
