import { FunctionComponent, Fragment } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Category } from 'data/types/categoriesTypes';

type Props = { category: Category };

const CategoriesVisitedView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Grid container direction='column' alignItems='center'>
			<Paper
				elevation={0}
				square={true}
				sx={{
					width: '159px',
					height: '159px',
					marginRigth: '20px',
					borderRadius: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: '#E8E3FA',
				}}
			>
				<img
					src={props.category.photo}
					width='100px'
					height='100px'
					alt='categories'
					style={{ borderRadius: '100%' }}
				></img>
			</Paper>
			<Typography
				fontFamily='Krub'
				fontSize='20px'
				fontWeight='bold'
				color='#5C41BD'
			>
				{props.category.categoryName}
			</Typography>
		</Grid>
	</Fragment>
);

export default CategoriesVisitedView;
