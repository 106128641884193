import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	CreateUserRequest,
	CreateUserResponse,
	ResendEmailRequest,
	ResendEmailResponse,
	ValidationUserResponse,
	ValidationUserRequest,
	UserMeRequest,
	UserMeResponse,
	RecoverPasswordUserRequest,
	RecoverPasswordResponse, ValidationCodeRequest, ValidationCodeResponse, ChangePasswordRequest, ChangePasswordResponse,
} from 'data/types/userTypes';
import { Either } from 'models/either';

export const createUser = async (
	request: CreateUserRequest
): Promise<Either<Failure, CreateUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<CreateUserRequest, CreateUserResponse>({
			url: '/api/public/v1/library-users/signup',
			request,
			secure: false,
		})
	);
};

export const resendEmail = async (
	request: ResendEmailRequest
): Promise<Either<Failure, ResendEmailResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<ResendEmailRequest, ResendEmailResponse>({
			url: '/api/public/v1/library-users/email',
			request,
		})
	);
};

export const validationUser = async (
	request: ValidationUserRequest
): Promise<Either<Failure, ValidationUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<ValidationUserRequest, ValidationUserResponse>({
			url: '/api/public/v1/library-users/validation',
			request,
			secure: false,
		})
	);
};

export const validationCode = async (
	request: ValidationCodeRequest
): Promise<Either<Failure, ValidationCodeResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<ValidationCodeRequest, ValidationCodeResponse>({
			url: '/api/public/v1/library-users/validateRecover',
			request,
			secure: false,
		})
	);
};

export const changePassword = async (
	request: ChangePasswordRequest
): Promise<Either<Failure, ChangePasswordResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<ChangePasswordRequest, ChangePasswordResponse>({
			url: '/api/public/v1/library-users/password',
			request,
			secure: false,
		})
	);
};

export const getUserMe = async (): Promise<Either<Failure, UserMeResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<UserMeRequest, UserMeResponse>({
			url: '/api/v1/library-users/me',
			secure: true,
		})
	);
};

export const recoverPassword = async (
	request: RecoverPasswordUserRequest
): Promise<Either<Failure, RecoverPasswordResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<RecoverPasswordUserRequest, RecoverPasswordResponse>({
			url: '/api/public/v1/library-users/recover',
			request,
			secure: false,
		})
	);
};
const UsersApi = {
	createUser,
	resendEmail,
	validationUser,
	getUserMe,
	recoverPassword,
	validationCode,
	changePassword
};

export default UsersApi;
