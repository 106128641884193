import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import {
	GetProductCatalogResponse,
	GetProductCatalogRequest,
} from 'data/types/catalogTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import catalogApi from 'data/api/catalogApi';

type TCatalogSlice = {
	getProductCatalogResponseRequest: GetProductCatalogResponse | null;
	getProductCatalogStatusRequest: StatusRequest;
	getProductCatalogStatusError?: Failure;
};

const initialState: TCatalogSlice = {
	getProductCatalogResponseRequest: null,
	getProductCatalogStatusRequest: StatusRequest.initial,
	getProductCatalogStatusError: undefined,
};

export const GetProductCatalogAsync = createAsyncThunk<
	GetProductCatalogResponse,
	GetProductCatalogRequest,
	{ rejectValue: Failure }
>('catalog/GetProductCatalogAsync', async (request: GetProductCatalogRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetProductCatalogResponse> =
			await catalogApi.getProductCatalog(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const CatalogSlice = createSlice({
	name: 'catalog',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(GetProductCatalogAsync.pending, (state) => {
			state.getProductCatalogStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetProductCatalogAsync.fulfilled, (state, action) => {
			state.getProductCatalogStatusRequest = StatusRequest.fulfilled;
			state.getProductCatalogResponseRequest = action.payload;
		});
		builder.addCase(GetProductCatalogAsync.rejected, (state, action) => {
			state.getProductCatalogStatusRequest = StatusRequest.rejected;
			state.getProductCatalogStatusError = action.payload;
		});
	},
});

export const selectCatalog = (state: RootState) => state.catalog;
export const { } = CatalogSlice.actions;
export default CatalogSlice.reducer;
