import React, { FunctionComponent } from 'react';
import ItemProductModalView from './view';
import useStyles from './useStyles';
import { ProductDescriptionOrder } from 'components/ModalDescriptionOrder/view';

type Props = {
	product: ProductDescriptionOrder
};

export const ItemProductModal: FunctionComponent<Props> = (props) => {
	const classes = useStyles();
	return <ItemProductModalView product={props.product}/>;
};
