import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import { GetBrandsRequest, GetBrandsResponse } from 'data/types/brandsTypes';
import { Either } from 'models/either';

export const getBrands = async (
	request: GetBrandsRequest
): Promise<Either<Failure, GetBrandsResponse>> => {
	const showInHome = request.showInHome ?? false;
	const categoryId = request.categoryId ?? '';
	return BaseRepository.toEither(() =>
		BaseApi.get<GetBrandsRequest, GetBrandsResponse>({
			url: `/api/public/v1/brands?showInHome=${showInHome}&categoryId=${categoryId}`,
			secure: false,
		})
	);
};

const brandsApi = {
	getBrands,
};

export default brandsApi;
