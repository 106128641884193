import { FunctionComponent, useEffect } from 'react';
import { ViewModalDescriptionOrder } from './view';
import { selectOrders } from 'data/global/features/OrdersSlice/ordersSlice';
import { useAppSelector } from 'data/global/hooks';
import useStyles from './useStyles';

type Props = {
	openModal: boolean;
	setOpenModal: any;
	items: number;
};

export const ModalDescriptionOrder: FunctionComponent<Props> = (props) => {
	const classes = useStyles();

	const { getSubOrderResponseRequest } = useAppSelector(selectOrders);

	useEffect(() => {}, [getSubOrderResponseRequest]);

	return (
		<ViewModalDescriptionOrder
			products={getSubOrderResponseRequest}
			setOpenModal={props.setOpenModal}
			openModal={props.openModal}
			classes={classes}
			items={props.items}
		/>
	);
};
