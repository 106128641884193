import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	card: {
		minWidth: '100px',
		width: '100%',
		height: '75px',
		background: 'white',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end',
		alignItems: 'center',
		position: 'relative',
		fontSize: '20px',
		paddingRight: '15px',
		paddingLeft: '15px',
		// margin: '1vw',
		cursor: 'pointer',
		'&:hover': {
			boxShadow: '0px 0px 0px 2px #5c41bd',
		},
	},
	cardVoid: {
		minWidth: '100px',
		height: '75px',
		background: '#EDEBED',
		paddingRight: '15px',
		paddingLeft: '15px',
	},
	textFixed: {
		display: 'flex',
		alignItems: 'baseline',
		color: '#5c41bd',
	},
	cardAside: {
		top: '0px',
		right: '0px',
		zIndex: 1,
		position: 'absolute',
		width: '50px',
		textAlign: 'right',
		color: 'white',
		height: '55px',
		overflow: 'hidden',
		lineHeight: 1,
		'&::after': {
			top: '-25px',
			right: '-25px',
			width: '50px',
			height: '50px',
			content: '""',
			display: 'block',
			zIndex: '-1',
			position: 'absolute',
			transform: 'rotate(45deg)',
			background: '#5C41BD',
		},
	},
	cardSpan: {
		fontSize: '16px',
		margin: '0px 2px',
	},
}));
export default useStyles;
