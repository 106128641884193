import { OfflineBolt } from '@mui/icons-material';
import {
	Button,
	Grid,
	Typography,
	Alert,
	CircularProgress,
} from '@mui/material';
import {
	Distributor,
	PutDistributorRequest,
} from 'data/types/distributorsTypes';
import { ControlProps, FormProps } from 'models/form';
import { Fragment, FunctionComponent, useState } from 'react';
import { Autocomplete } from 'UI';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	distributors: Distributor[];
	registerDistributor: (request: PutDistributorRequest) => void;
	loading: boolean;
	options: any;
};

export const RightSectionRegisterDistributorsView: FunctionComponent<Props> = ({
	...props
}) => {
	const [dist, setDist] = useState({a: props.options[0]?.id,b:props.options[1]?.id,c:props.options[2]?.id,d:props.options[3]?.id});
	return (
		<Fragment>
			{props.loading ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress />
				</div>
			) : (
				<Fragment>
					<Grid container pt={10}>
						<Grid item xs={12} mt={2} px={2}>
							<Autocomplete
								options={props.options}
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.options[0],
									name: 'distributor1',
								}}
								errors={props.formProps.errors}
								placeholder='Distribuidor 1'
								onNewValue={(d) => {
									setDist(prevDist => ({ ...prevDist, a: d.id }));
								}}
								onClear={() => {
									setDist(prevDist => ({ ...prevDist, a: undefined }))
								}}
							/>
						</Grid>
						<Grid item xs={12} mt={2} px={2}>
							<Autocomplete
								options={props.options}
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.options[1],
									name: 'distributor2',
								}}
								errors={props.formProps.errors}
								placeholder='Distribuidor 2'
								onNewValue={(d) => {
									setDist(prevDist => ({ ...prevDist, b: d.id }));
								}}
								onClear={() => {
									setDist(prevDist => ({ ...prevDist, b: undefined }))
								}}
							/>
						</Grid>
						<Grid item xs={12} mt={2} px={2}>
							<Autocomplete
								options={props.options}
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.options[2],
									name: 'distributor3',
								}}
								errors={props.formProps.errors}
								placeholder='Distribuidor 3'
								onNewValue={(d) => {
									setDist(prevDist => ({ ...prevDist, c: d.id }));
								}}
								onClear={() => {
									setDist(prevDist => ({ ...prevDist, c: undefined }))
								}}
							/>
						</Grid>
						<Grid item xs={12} mt={2} px={2}>
							<Autocomplete
								options={props.options}
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.options[3],
									name: 'distributor4',
								}}
								errors={props.formProps.errors}
								placeholder='Distribuidor 4'
								onNewValue={(d) => {
									setDist(prevDist => ({ ...prevDist, d: d.id }));
								}}
								onClear={() => {
									setDist(prevDist => ({ ...prevDist, d: undefined }))
								}}
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent='flex-end' pr={2} pt={10}>
						<Button
							variant='contained'
							size='small'
							style={{
								textTransform: 'none',
							}}
							sx={{ borderRadius: '20px' }}
							onClick={props.formProps.handleSubmit(() => {
								const distFiltered = Object.fromEntries(Object.entries(dist).filter(([_, v]) => v != null))
								props.registerDistributor({
									distributorIdList: Object.values(distFiltered),
								});
								localStorage.removeItem('location');
							})}
							disableElevation
						>
							{props?.error?.error && (
								<Alert severity='error' style={{ marginTop: '1.5em' }}>
									{props?.error?.error}
								</Alert>
							)}
							<Typography color='white' variant='body2' p={0.8}>
								Finalizar selección
							</Typography>
						</Button>
					</Grid>
				</Fragment>
			)}
		</Fragment>
	);
	
}
