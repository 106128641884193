import {
	GetOrderAsync,
	selectOrders,
} from 'data/global/features/OrdersSlice/ordersSlice';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import React, { useEffect } from 'react';
import { ViewOrderDetail } from './view';
import { useParams } from 'react-router-dom';

export const OrderDetail: React.FC = () => {
	const dispatch = useAppDispatch();
	const params = useParams();

	useEffect(() => {
		dispatch(GetOrderAsync({ id: params.orderId || '' }));
	}, []);

	const { getOrderResponseRequest, getOrderStatusRequest } =
		useAppSelector(selectOrders);

	return <ViewOrderDetail order={getOrderResponseRequest} />;
};
