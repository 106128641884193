import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	RegisterLibraryRequest,
	RegisterLibraryResponse,
} from 'data/types/libraryTypes';
import { Either } from 'models/either';

export const registerLibrary = async (
	request: RegisterLibraryRequest
): Promise<Either<Failure, RegisterLibraryResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<RegisterLibraryRequest, RegisterLibraryResponse>({
			url: '/api/v1/library-users/post-verify',
			request,
			secure: true,
		})
	);
};

const libraryApi = {
	registerLibrary,
};

export default libraryApi;
