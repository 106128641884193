import { AxiosError } from 'axios';
import { ApiCallFunction, Failure } from 'data/types';
import { Either, makeLeft, makeRight } from 'models/either';

export const toEither = async <T>(
	apiCallFunction: ApiCallFunction<T>
): Promise<Either<Failure, T>> => {
	try {
		const response = await apiCallFunction();
		if (!response.error) return makeRight(response.data!);
		return makeLeft({
			error: true,
			status: response.status,
			message: response.message,
		});
	} catch (error) {
		const e = error as unknown as AxiosError<Failure>;
		if (e.isAxiosError && e.response) {
			const response = e.response.data;
			return makeLeft(response);
		}
		return makeLeft({
			error: true,
			status: 0,
			message: (error as Error).toString(),
		});
	}
};
