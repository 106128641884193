import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetProductCatalogRequest,
	GetProductCatalogResponse,
} from 'data/types/catalogTypes';
import { Either } from 'models/either';

export const getProductCatalog = async (req: GetProductCatalogRequest): Promise<
	Either<Failure, GetProductCatalogResponse>
> => {
	let baseURL = '/api/public/v1/catalog';
	let params = [];
	if(req.category) {
		params.push(`category=${req.category}`);
	}
	if(req.brand) {
		params.push(`brand=${req.brand}`);
	}
	if(req.query) {
		params.push(`query=${req.query}`);
	}
	if(req.page) {
		params.push(`page=${req.page}`);
	}
	if(params.length > 0) {
		baseURL += `?${params.join('&')}`;
	}
	return BaseRepository.toEither(() =>
		BaseApi.get<GetProductCatalogRequest, GetProductCatalogResponse>({
			url: baseURL,
			secure: true
		})
	);
};


const BannersApi = {
	getProductCatalog,
};

export default BannersApi;