import { FunctionComponent, Fragment } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Category, GetCategoriesResponse } from 'data/types/categoriesTypes';

type Props = {
	handleClose?: (e: any) => void;
	categories?: GetCategoriesResponse | null;
	open?: any;
	anchorEl?: any;
};

const MenuCategoriesView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Menu
			id='long-menu'
			MenuListProps={{
				'aria-labelledby': 'long-button',
			}}
			anchorEl={props.anchorEl}
			open={props.open}
			onClose={props.handleClose}
			PaperProps={{
				style: {
					maxHeight: '336px',
					width: '276px',
				},
			}}
		>
			<MenuItem
					key="full-catalog"
					onClick={props.handleClose}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						color: '#80777E',
					}}
				>
					<a href={`/catalog`} style={{
						color: 'rgba(0, 0, 0, 0.87)',
						textDecoration: 'none',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
					}}>
						Catalogo completo
						<i
							className='icon-chevron-right'
							style={{
								fontSize: '2rem',
								color: '#5C41BD',
							}}
						></i>
					</a>
			</MenuItem>
			{props.categories && props.categories.map((category: Category) => (
				<MenuItem
					key={category.categoryID}
					onClick={props.handleClose}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						color: '#80777E',
					}}
				>
					<a href={`/catalog?category=${category.categoryID}`} style={{
						color: 'rgba(0, 0, 0, 0.87)',
						textDecoration: 'none',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
					}}>
						{category.categoryName}
						<i
							className='icon-chevron-right'
							style={{
								fontSize: '2rem',
								color: '#5C41BD',
							}}
						></i>
					</a>
				</MenuItem>
			))}
		</Menu>
	</Fragment>
);

export default MenuCategoriesView;
