import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import UsersApi from 'data/api/usersApi';
import { Failure, StatusRequest } from 'data/types';
import {
	CreateUserRequest,
	CreateUserResponse,
	ResendEmailRequest,
	ResendEmailResponse,
	ValidationUserRequest,
	ValidationUserResponse,
	UserMeRequest,
	UserMeResponse,
	RecoverPasswordResponse,
	RecoverPasswordUserRequest,
	ValidationCodeRequest,
	ValidationCodeResponse,
	ChangePasswordRequest, ChangePasswordResponse,
} from 'data/types/userTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TuserSlice = {
	createUserStatusRequest?: StatusRequest;
	createUserStatusError?: Failure;
	resendEmailStatusRequest?: StatusRequest;
	resendEmailStatusError?: Failure;
	recoverPasswordStatusRequest?: StatusRequest;
	recoverPasswordStatusError?: Failure;
	validationStatusRequest?: StatusRequest;
	validationStatusError?: Failure;
	validationCodeStatusRequest?: StatusRequest;
	validationCodeStatusError?: Failure;
	changePasswordStatusRequest?: StatusRequest;
	changePasswordStatusError?: Failure;
	userMeStatusRequest?: StatusRequest;
	userMeStatusError?: Failure;
	userMeResponse?: UserMeResponse | null;
	location: string;
};

const initialState: TuserSlice = {
	createUserStatusRequest: StatusRequest.initial,
	createUserStatusError: undefined,
	resendEmailStatusRequest: StatusRequest.initial,
	resendEmailStatusError: undefined,
	recoverPasswordStatusRequest: StatusRequest.initial,
	recoverPasswordStatusError: undefined,
	validationStatusRequest: StatusRequest.initial,
	validationStatusError: undefined,
	validationCodeStatusRequest: StatusRequest.initial,
	validationCodeStatusError: undefined,
	changePasswordStatusRequest: StatusRequest.initial,
	changePasswordStatusError:undefined,
	userMeStatusRequest: StatusRequest.initial,
	userMeStatusError: undefined,
	userMeResponse: undefined,
	location: '',
};

export const CreateUserAsync = createAsyncThunk<
	CreateUserResponse,
	CreateUserRequest,
	{ rejectValue: Failure }
>('user/CreateUserAsync', async (request: CreateUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, CreateUserResponse> =
			await UsersApi.createUser(request);
		localStorage.setItem('email', request.email);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutResendEmailAsync = createAsyncThunk<
	ResendEmailResponse,
	ResendEmailRequest,
	{ rejectValue: Failure }
>('user/PutResendEmailAsync', async (request: ResendEmailRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ResendEmailResponse> =
			await UsersApi.resendEmail(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response putResendEmail');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const ValidationUserAsync = createAsyncThunk<
	ValidationUserResponse,
	ValidationUserRequest,
	{ rejectValue: Failure }
>(
	'user/ValidationUserAsync',
	async (request: ValidationUserRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ValidationUserResponse> =
				await UsersApi.validationUser(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				const { jwt } = response;
				console.log(response);
				localStorage.setItem('jwt', jwt);
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const ValidationCodeAsync = createAsyncThunk<
	ValidationCodeResponse,
	ValidationCodeRequest,
	{ rejectValue: Failure }
>(
	'user/ValidationCodeAsync',
	async (request: ValidationCodeRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ValidationCodeResponse> =
				await UsersApi.validationCode(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				return response;
			}
			const error = unwrapEither(eitherResponse);
			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);


export const ChangePasswordAsync = createAsyncThunk<
	ChangePasswordResponse,
	ChangePasswordRequest,
	{ rejectValue: Failure }
>(
	'user/ChangePasswordAsync',
	async (request: ChangePasswordRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ChangePasswordResponse> =
				await UsersApi.changePassword(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				return response;
			}
			const error = unwrapEither(eitherResponse);
			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);


export const RecoverUserPasswordAsync = createAsyncThunk<
	RecoverPasswordResponse,
	RecoverPasswordUserRequest,
	{ rejectValue: Failure }
	>(
	'user/RecoverUserPasswordAsync',
	async (request: RecoverPasswordUserRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, RecoverPasswordResponse> =
				await UsersApi.recoverPassword(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response);
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const GetUserMeAsync = createAsyncThunk<
	UserMeResponse,
	UserMeRequest,
	{ rejectValue: Failure }
>('auth/GetAUserMeAsync', async (request: UserMeRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, UserMeResponse> =
			await UsersApi.getUserMe();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response getUserMe');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const setUserLocation = createAction<string>('user/setLocation');

export const UserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetCreateStatusRequest: (state) => {
			state.createUserStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(CreateUserAsync.pending, (state) => {
			state.createUserStatusRequest = StatusRequest.pending;
			state.createUserStatusError = undefined;
		});
		builder.addCase(CreateUserAsync.fulfilled, (state, action) => {
			state.createUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(CreateUserAsync.rejected, (state, action) => {
			state.createUserStatusRequest = StatusRequest.rejected;
			state.createUserStatusError = action.payload;
		});
		builder.addCase(PutResendEmailAsync.pending, (state) => {
			state.resendEmailStatusRequest = StatusRequest.pending;
			state.resendEmailStatusError = undefined;
		});
		builder.addCase(PutResendEmailAsync.fulfilled, (state, action) => {
			state.resendEmailStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutResendEmailAsync.rejected, (state, action) => {
			state.resendEmailStatusRequest = StatusRequest.rejected;
			state.resendEmailStatusError = action.payload;
		});
		builder.addCase(RecoverUserPasswordAsync.pending, (state) => {
			state.recoverPasswordStatusRequest = StatusRequest.pending;
			state.recoverPasswordStatusError = undefined;
		});
		builder.addCase(RecoverUserPasswordAsync.fulfilled, (state, action) => {
			state.recoverPasswordStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(RecoverUserPasswordAsync.rejected, (state, action) => {
			state.recoverPasswordStatusRequest = StatusRequest.rejected;
			state.recoverPasswordStatusError = action.payload;
		});
		builder.addCase(ValidationUserAsync.pending, (state) => {
			state.validationStatusRequest = StatusRequest.pending;
			state.validationStatusError = undefined;
		});
		builder.addCase(ValidationUserAsync.fulfilled, (state, action) => {
			state.validationStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(ValidationUserAsync.rejected, (state, action) => {
			state.validationStatusRequest = StatusRequest.rejected;
			state.validationStatusError = action.payload;
		});
		builder.addCase(ValidationCodeAsync.pending, (state) => {
			state.validationCodeStatusRequest = StatusRequest.pending;
			state.validationCodeStatusError = undefined;
		});
		builder.addCase(ValidationCodeAsync.fulfilled, (state, action) => {
			state.validationCodeStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(ValidationCodeAsync.rejected, (state, action) => {
			state.validationCodeStatusRequest = StatusRequest.rejected;
			state.validationCodeStatusError = action.payload;
		});
		builder.addCase(GetUserMeAsync.pending, (state) => {
			state.userMeStatusRequest = StatusRequest.pending;
			state.userMeStatusError = undefined;
		});
		builder.addCase(GetUserMeAsync.fulfilled, (state, action) => {
			state.userMeStatusRequest = StatusRequest.fulfilled;
			state.userMeResponse = action.payload;
		});
		builder.addCase(GetUserMeAsync.rejected, (state, action) => {
			state.userMeStatusRequest = StatusRequest.rejected;
			state.userMeStatusError = action.payload;
		});
		builder.addCase(ChangePasswordAsync.pending, (state) => {
			state.changePasswordStatusRequest = StatusRequest.pending;
			state.changePasswordStatusError = undefined;
		});
		builder.addCase(ChangePasswordAsync.fulfilled, (state, action) => {
			state.changePasswordStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(ChangePasswordAsync.rejected, (state, action) => {
			state.changePasswordStatusRequest = StatusRequest.rejected;
			state.changePasswordStatusError = action.payload;
		});
		builder.addCase(setUserLocation, (state, action) => {
			state.location = action.payload;
		});
	},
});

export const selectUser = (state: RootState) => state.user;
export const { resetCreateStatusRequest } = UserSlice.actions;
export default UserSlice.reducer;
