import { FunctionComponent, Fragment } from 'react';
import { PropsCartCard } from '.';

type Props = {
	classes: any;
	inputs: PropsCartCard;
};

const CardCartView: FunctionComponent<Props> = (props) => (
	<Fragment>
		{!props.inputs.void ? (
			<div className={props.classes.card}>
				{props.inputs.selected ? (
					<aside className={props.classes.cardAside}>
						<i
							className='icon-check'
							style={{
								color: 'white',
								fontSize: '15px',
								position: 'absolute',
								top: '3px',
								right: '3px',
							}}
						></i>
					</aside>
				) : (
					<></>
				)}
				<div className={props.inputs.selected ? props.classes.textFixed : ''}>
					<span className={props.classes.cardSpan}>$</span>
					{props.inputs.price?.toFixed(2)}
					<span className={props.classes.cardSpan}>c/u</span>
				</div>
			</div>
		) : (
			<div className={props.classes.cardVoid}></div>
		)}
	</Fragment>
);

export default CardCartView;
