import { InputAdornment, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent, Fragment } from 'react';
import { CloseButtonInput, TextField } from 'UI';
import { Controller, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ControlProps } from 'models/form';
import { ErrorMessage } from '@hookform/error-message';
import ErrorText from './ErrorText';

type Props = {
	controllerProps: ControlProps;
	errors: any;
	borderRadius?: {
		borderTopLeftRadius: string;
		borderBottomRightRadius: string;
		borderTopRightRadius: string;
		borderBottomLeftRadius: string;
	};
	setValue?: UseFormSetValue<FieldValues>;
	getValues?: UseFormGetValues<FieldValues>;
} & TextFieldProps;

let InputStyled = styled(TextField, { shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "errorInput" })<{
	borderRadius?: {
		borderTopLeftRadius: string;
		borderBottomRightRadius: string;
		borderTopRightRadius: string;
		borderBottomLeftRadius: string;
	};
	errorInput: boolean;
}>(({ theme, borderRadius, errorInput }) => ({
	'& .MuiOutlinedInput-root': {
		borderTopLeftRadius: borderRadius?.borderTopLeftRadius ?? '21px',
		borderBottomRightRadius:
			borderRadius?.borderBottomRightRadius ?? '21px',
		borderTopRightRadius: borderRadius?.borderTopRightRadius ?? '21px',
		borderBottomLeftRadius:
			borderRadius?.borderBottomLeftRadius ?? '21px',
		color: errorInput ? "#E01638" : theme.palette.secondary.main,
		backgroundColor: errorInput ? "#FCE6EA" : '#E8E3FA',
		'& fieldset': {
			borderColor: 'transparent',
		},
		'&:hover fieldset': {
			borderColor: theme.palette.secondary.main,
		},
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.secondary.main,
		},
	},
}));

export const Input: FunctionComponent<Props> = ({
	variant = 'outlined',
	size = 'small',
	color = 'secondary',
	controllerProps,
	setValue,
	getValues,
	...props
}) => {

	return (
		<Fragment>
			<Controller
				render={({ field: { onChange, onBlur, value, ref } }) => (
					<InputStyled
						variant={variant}
						size={size}
						color={color}
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						errorInput={props.errors[controllerProps.name]}
						InputProps={{
							...props.InputProps,
							endAdornment: (
								<InputAdornment position="end">
									<CloseButtonInput
										nameInput={controllerProps.name}
										refInput={props.inputRef}
										getValues={getValues}
										setValue={setValue}
									/>
								</InputAdornment>
							),
						}}
						{...props}
					>
						{props.children}
					</InputStyled>
				)}
				control={controllerProps.control}
				defaultValue={controllerProps.defaultValue}
				name={controllerProps.name}
				rules={controllerProps.rules}
			/>
			<ErrorMessage
				errors={props.errors}
				name={controllerProps.name}
				as={<ErrorText />}
			/>
		</Fragment>
	);
};
