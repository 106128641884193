import { useAppDispatch } from 'data/global/hooks';
import { ChangePasswordAsync, ValidationCodeAsync } from '../../../data/global/features/UserSlice/userSlice';
import { ChangePasswordRequest } from '../../../data/types/userTypes';

const useChangePassword = () => {
	const dispatch = useAppDispatch();

	const changePassword = (passwordRequest: ChangePasswordRequest): void => {
		dispatch(ChangePasswordAsync(passwordRequest));
	};

	return changePassword;
};

export default useChangePassword;
