import {
	GetPublicKeyAsync,
	selectAuth,
} from 'data/global/features/AuthSlice/authSlice';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import React from 'react';
import { ViewPrivacyPolicies } from './view';

export const PrivacyPolicies: React.FC = () => {
	return <ViewPrivacyPolicies />;
};
