import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	passwordTitle: {
		font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/var(--unnamed-line-spacing-20) var(--unnamed-font-family-krub)',
		letterSpacing: 'var(--unnamed-character-spacing-0)',
		textAlign: 'left',
		color: '#FF6651',
	},
	passwordText: {
		letterSpacing: 'var(--unnamed-character-spacing-0)',
		textAlign: 'left',
		font: 'normal normal medium 14px/20px Krub',
		color: '#80777E',
	},
	policies: {
		font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 12px/16px var(--unnamed-font-family-krub)',
		color: 'var(---5c41bd-violeta)',
		textAlign: 'left',
		letterSpacing: '0px',
	},
	textPolicies: {
		font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 12px/16px var(--unnamed-font-family-krub)',
		letterSpacing: 'var(--unnamed-character-spacing-0)',
		color: 'var(--unnamed-color-80777e)',
		textAlign: 'left',
	},
}));
export default useStyles;
