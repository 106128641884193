import { Grid, GridSize } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import Background01 from './Cinta01.svg'
import Background03 from './Cinta03.svg'
import Background04 from './Cinta04.svg'

type Props = {
    leftSection: ReactNode,
    rightSection: ReactNode,
    rightSize?: GridSize,
    leftSize?: GridSize,
    backgroundImage?: 'Background01' | 'Background03' | 'Background04';
};

function getBackground(backgroundImage?: 'Background01' | 'Background03' | 'Background04'): string {
    if (backgroundImage === 'Background01') {
        return Background01;
    }

    if (backgroundImage === 'Background03') {
        return Background03;
    }

    if (backgroundImage === 'Background04') {
        return Background04;
    }
   
    return Background01;
}
export const Layout: FunctionComponent<Props> = ({  rightSize = 6, leftSize = 6, ...props }) => (
    <div style={{
        backgroundImage: `url(${ getBackground(props.backgroundImage)})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%'
    }}>
        {props.children}
        <Grid container px={10}>
            <Grid item xs={leftSize}>
                {props.leftSection}
            </Grid>
            <Grid item xs={rightSize}>
                {props.rightSection}
            </Grid>
        </Grid>
    </div>
);