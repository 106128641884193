
import { useAppDispatch } from 'data/global/hooks';
import { SendOrderAsync } from 'data/global/features/OrdersSlice/ordersSlice';
import { SendOrderRequest } from 'data/types/ordersTypes';

export const useSendCart = () => {
	const dispatch = useAppDispatch();

	const sendCart = (req: SendOrderRequest): void => {
		dispatch(SendOrderAsync(req));
	};

	return sendCart;
};

export default useSendCart;
