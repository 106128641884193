import { FunctionComponent } from 'react';
import { Box } from '@mui/material';

type Props = {
	children?: React.ReactNode;
	index: number;
	value: number;
};

export const TabPanel: FunctionComponent<Props> = ({ ...props }) => (
	<div
		role='tabpanel'
		hidden={props.value !== props.index}
		id={`simple-tabpanel-${props.index}`}
		aria-labelledby={`simple-tab-${props.index}`}
	>
		{props.value === props.index && <Box sx={{ p: 3 }}>{props.children}</Box>}
	</div>
);
export default TabPanel;
