import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import { GetBrandsRequest, GetBrandsResponse } from 'data/types/brandsTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import brandsApi from 'data/api/brandsApi';

type TBrandsSlice = {
	brands: GetBrandsResponse | null;
	getBrandsStatusRequest: StatusRequest;
	getBrandsStatusError?: Failure;
};

const initialState: TBrandsSlice = {
	brands: null,
	getBrandsStatusRequest: StatusRequest.initial,
	getBrandsStatusError: undefined,
};

export const GetBrandsAsync = createAsyncThunk<
	GetBrandsResponse,
	GetBrandsRequest,
	{ rejectValue: Failure }
>('brands/GetBrandsAsync', async (request: GetBrandsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetBrandsResponse> =
			await brandsApi.getBrands(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const BrandsSlice = createSlice({
	name: 'brands',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(GetBrandsAsync.pending, (state) => {
			state.getBrandsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetBrandsAsync.fulfilled, (state, action) => {
			state.getBrandsStatusRequest = StatusRequest.fulfilled;
			state.brands = action.payload;
		});
		builder.addCase(GetBrandsAsync.rejected, (state, action) => {
			state.getBrandsStatusRequest = StatusRequest.rejected;
			state.getBrandsStatusError = action.payload;
		});
	},
});

export const selectBrands = (state: RootState) => state.brands;
export const {} = BrandsSlice.actions;
export default BrandsSlice.reducer;
