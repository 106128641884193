import React, { FunctionComponent } from 'react';
import CardCartView from './view';
import useStyles from './useStyles';

export interface PropsCartCard {
	selected?: boolean,
	void?: boolean,
	price?: number
}

export const CardCart: FunctionComponent<PropsCartCard> = (props) => {
	const classes = useStyles();
	return <CardCartView classes={classes} inputs={props}/>;
};
