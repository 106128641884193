import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetMyCartRequest,
	GetMyCartResponse,
	AddCartRequest,
	AddCartResponse,
	QttyMasterProductInCartRequest,
	QttyMasterProductInCartResponse,
} from 'data/types/cartTypes';
import { Either } from 'models/either';

export const getCart = async (): Promise<
	Either<Failure, GetMyCartResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetMyCartRequest, GetMyCartResponse>({
			url: '/api/v1/cart',
		})
	);
};

export const addCart = async (
	request: AddCartRequest
): Promise<Either<Failure, AddCartResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<AddCartRequest, AddCartResponse>({
			url: '/api/v1/cart',
			request,
		})
	);
};

export const getQttyCart = async (
	request: QttyMasterProductInCartRequest
): Promise<Either<Failure, QttyMasterProductInCartResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<
			QttyMasterProductInCartRequest,
			QttyMasterProductInCartResponse
		>({
			url: `/api/v1/cart/${request.masterProductId}`,
		})
	);
};

const CartApi = {
	getCart,
	addCart,
	getQttyCart,
};

export default CartApi;
