import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
	policies: {
		font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 12px/16px var(--unnamed-font-family-krub)',
		color: 'var(---5c41bd-violeta)',
		textAlign: 'left',
		letterSpacing: '0px',
		marginLeft: '2px',
	},
}));
export default useStyles;
