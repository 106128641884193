import { useAppDispatch } from 'data/global/hooks';
import { GetAllDistributorsAsync } from 'data/global/features/DistributorSlice/distributorSlice';
import { useEffect } from 'react';

export const useGetAllDistributors = () => {
	const dispatch = useAppDispatch();

    const getAllDistributors = (): void => {
        dispatch(GetAllDistributorsAsync({}));
    };

    useEffect(()=>{
        getAllDistributors();
    },[]);

};

export default useGetAllDistributors;