import { useAppDispatch } from 'data/global/hooks';
import { PutDistributorAsync } from 'data/global/features/DistributorSlice/distributorSlice';
import { PutDistributorRequest } from 'data/types/distributorsTypes';

export const useRegisterDistributor = () => {
	const dispatch = useAppDispatch();

	const registerDistributor = (request: PutDistributorRequest): void => {
		dispatch(PutDistributorAsync(request));
	};

	return registerDistributor;
};

export default useRegisterDistributor;
