import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import JSEncrypt from 'jsencrypt';
import {
	GetCategoriesRequest,
	GetCategoriesResponse,
	GetCategoryRequest,
	GetCategoryResponse
} from 'data/types/categoriesTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import CategoriesApi from 'data/api/categoriesApi';

type TCategoriesSlice = {
	categories: GetCategoriesResponse | null;
	GetCategoriesStatusRequest: StatusRequest;
	GetCategoriesStatusError?: Failure;
	GetCategoryStatusRequest: StatusRequest;
	GetCategoryStatusError?: Failure;

};

const initialState: TCategoriesSlice = {
	categories: null,
	GetCategoriesStatusRequest: StatusRequest.initial,
	GetCategoriesStatusError: undefined,
	GetCategoryStatusRequest: StatusRequest.initial,
	GetCategoryStatusError: undefined,

};

export const GetCategoriesAsync = createAsyncThunk<
	GetCategoriesResponse,
	GetCategoriesRequest,
	{ rejectValue: Failure }
>('cetegories/GetCategoriesAsync', async (request: GetCategoriesRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetCategoriesResponse> =
			await CategoriesApi.getCategories();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		//TODO manejar error
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetCategoryAsync = createAsyncThunk<
	GetCategoryResponse,
	GetCategoryRequest,
	{ rejectValue: Failure }
>('cetegories/GetCategoryAsync', async (request: GetCategoryRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetCategoryResponse> =
			await CategoriesApi.getCategory();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		//TODO manejar error
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const CategoriesSlice = createSlice({
	name: 'categories',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(GetCategoriesAsync.pending, (state) => {
			state.GetCategoriesStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetCategoriesAsync.fulfilled, (state, action) => {
			state.GetCategoriesStatusRequest = StatusRequest.fulfilled;
			state.categories = action.payload;
		});
		builder.addCase(GetCategoriesAsync.rejected, (state, action) => {
			state.GetCategoriesStatusRequest = StatusRequest.rejected;
			state.GetCategoriesStatusError = action.payload;
		});
		builder.addCase(GetCategoryAsync.pending, (state) => {
			state.GetCategoryStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetCategoryAsync.fulfilled, (state, action) => {
			state.GetCategoryStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(GetCategoryAsync.rejected, (state, action) => {
			state.GetCategoryStatusRequest = StatusRequest.rejected;
			state.GetCategoryStatusError = action.payload;
		});
	},
});

export const selectCategories = (state: RootState) => state.categories;
export const { } = CategoriesSlice.actions;
export default CategoriesSlice.reducer;
