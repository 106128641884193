import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	containerButton: {
		borderRadius: '20px',
		backgroundColor: '#5C41BD',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		height: '48px',
	},
}));
export default useStyles;
