import { Fragment, FunctionComponent } from 'react';
import { Alert, Box, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { NavBarHome } from 'components';
import { Button, Card } from '../../UI';
import { Input } from '../../UI/Input';
import { Link } from 'react-router-dom';

type Props = {};

export const ChangePasswordSuccesfullyView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Box>
			<Grid container justifyContent='center'>
				<Grid item xs={4}>
					<Card variant='outlined'>
						<CardHeader></CardHeader>
						<CardContent sx={{ mt: 10, mb: 6 }}>
							<Box pl={12} pt={2}>
								<Typography color='#FF6651' variant='h6' fontWeight='bold' align='left' mb={2}>
									Tu nueva contraseña se genero con exito
								</Typography>
							</Box>
							<Grid container justifyContent='flex-end' pr={16} pt={6}>

								<Button
									variant='contained'
									size='large'
									style={{
										textTransform: 'none',
									}}
									sx={{ borderRadius: '20px' }}
									disableElevation
									onClick={event => window.location.href = '/register'}>
									<Typography color='white' variant='body2' p={0.8}>
										Iniciar sessión
									</Typography>
								</Button>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	</Fragment>
);

export default ChangePasswordSuccesfullyView;
