import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import {
	GetProductsRequest,
	GetProductsResponse,
	GetProductRequest,
	GetProductResponse,
	GetHighlightProductRequest,
	GetHighlightProductResponse,
} from 'data/types/productsTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import ProductsApi from 'data/api/productsApi';

type TProductsSlice = {
	GetProductsResponseRequest: GetProductsResponse | null,
	GetProductResponseRequest: GetProductResponse | null,
	GetHighlightProductResponseRequest: GetHighlightProductResponse | null,
	GetProductsStatusRequest: StatusRequest;
	GetProductsStatusError?: Failure;
	GetProductStatusRequest: StatusRequest;
	GetProductStatusError?: Failure;
	GetHighlightProductStatusRequest: StatusRequest;
	GetHighlightProductStatusError?: Failure;
};

const initialState: TProductsSlice = {
	GetHighlightProductResponseRequest: null,
	GetProductsResponseRequest: null,
	GetProductResponseRequest: null,
	GetProductsStatusRequest: StatusRequest.initial,
	GetProductsStatusError: undefined,
	GetProductStatusRequest: StatusRequest.initial,
	GetProductStatusError: undefined,
	GetHighlightProductStatusRequest: StatusRequest.initial,
	GetHighlightProductStatusError: undefined,
};

export const GetProductsAsync = createAsyncThunk<
	GetProductsResponse,
	GetProductsRequest,
	{ rejectValue: Failure }
>(
	'products/GetProductsAsync',
	async (request: GetProductsRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, GetProductsResponse> =
				await ProductsApi.getProducts();
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				return response;
			}
			const error = unwrapEither(eitherResponse);
			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			//TODO manejar error
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const GetProductAsync = createAsyncThunk<
	GetProductResponse,
	GetProductRequest,
	{ rejectValue: Failure }
>('products/GetProductAsync', async (request: GetProductRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetProductResponse> =
			await ProductsApi.getProduct(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		//TODO manejar error
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetHighlightProductAsync = createAsyncThunk<
	GetHighlightProductResponse,
	GetHighlightProductRequest,
	{ rejectValue: Failure }
>(
	'cetegories/GetProductAsync',
	async (request: GetHighlightProductRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, GetHighlightProductResponse> =
				await ProductsApi.getHighlightProduct();
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				return response;
			}
			const error = unwrapEither(eitherResponse);
			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			//TODO manejar error
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const ProductsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(GetProductsAsync.pending, (state) => {
			state.GetProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetProductsAsync.fulfilled, (state, action) => {
			state.GetProductsStatusRequest = StatusRequest.fulfilled;
			state.GetProductsResponseRequest = action.payload;
		});
		builder.addCase(GetProductsAsync.rejected, (state, action) => {
			state.GetProductsStatusRequest = StatusRequest.rejected;
			state.GetProductsStatusError = action.payload;
		});
		builder.addCase(GetProductAsync.pending, (state) => {
			state.GetProductStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetProductAsync.fulfilled, (state, action) => {
			state.GetProductStatusRequest = StatusRequest.fulfilled;
			state.GetProductResponseRequest = action.payload
		});
		builder.addCase(GetProductAsync.rejected, (state, action) => {
			state.GetProductStatusRequest = StatusRequest.rejected;
			state.GetProductStatusError = action.payload;
		});
		builder.addCase(GetHighlightProductAsync.pending, (state) => {
			state.GetProductStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetHighlightProductAsync.fulfilled, (state, action) => {
			state.GetProductStatusRequest = StatusRequest.fulfilled;
			state.GetHighlightProductResponseRequest = action.payload;
		});
		builder.addCase(GetHighlightProductAsync.rejected, (state, action) => {
			state.GetProductStatusRequest = StatusRequest.rejected;
			state.GetProductStatusError = action.payload;
		});
	},
});

export const selectProducts = (state: RootState) => state.products;
export const {} = ProductsSlice.actions;
export default ProductsSlice.reducer;
