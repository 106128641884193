import React, { useRef, useState, useEffect } from 'react';
import RegisterFormView from './view';
import useStyles from './useStyles';
import { rulesEmail, rulesPassword } from 'utils/validationRules';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateUserFront, useValidatePasswordChecks } from './hooks';
import { selectUser } from 'data/global/features/UserSlice/userSlice';
import { useAppSelector } from 'data/global/hooks';
import { StatusRequest } from 'data/types';
export const RegisterForm: React.FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const createUser = useCreateUserFront();

	const { createUserStatusError, createUserStatusRequest } =
		useAppSelector(selectUser);

	//React Hook Form
	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
		watch,
		setValue,
		getValues,
	} = useForm({ mode: 'onBlur' });

	const isValidPassword = useValidatePasswordChecks(watch);

	const rules = {
		rulesEmail: rulesEmail(t),
		rulesPassword: rulesPassword(t),
	};

	const defaultValue = {
		name: '',
		lastName: '',
		email: '',
		password: '',
		password2: '',
	};
	const nameRef = useRef(null);
	const lastNameRef = useRef(null);
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const password2Ref = useRef(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (createUserStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [createUserStatusRequest]);

	return (
		<RegisterFormView
			classes={classes}
			formProps={{
				handleSubmit,
				errors,
				isSubmitting,
				isValid,
				setValue,
				getValues,
			}}
			createUser={createUser}
			error={createUserStatusError}
			controlProps={{ control, defaultValue, rules }}
			isValidPassword={isValidPassword}
			refInputs={{ nameRef, lastNameRef, emailRef, passwordRef, password2Ref }}
			loading={loading}
		/>
	);
};

export default RegisterForm;
