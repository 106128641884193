import React, { useEffect, useState } from 'react';
import { ViewViewItem } from './view';
import useStyles from './useStyles';
import { useGetProductById, useGetQttyCart } from './hooks';
import { useAppSelector } from 'data/global/hooks';
import { selectProducts } from 'data/global/features/ProductsSlice/productsSlice';
import { selectCart } from 'data/global/features/CartSlice/cartSlice';
import useAddToMyCart from 'pages/Cart/hooks/useAddToCart';
import { useParams } from 'react-router-dom';

export const ViewItem: React.FC = () => {
	const classes = useStyles();

	const { id } = useParams();

	let dataViewItem = null;
	const [addProduct, setAddProduct] = useState(false);
	const [loading, setLoading] = useState(false);
	const [qttyInCart, setQttyInCart] = useState(0);
	const [disabledButton, setDisabledButton] = useState(true);
	const [defaultDistributor, setDefaultDistributor] = useState(true);
	const [selectDistributor, setSelectDistributor] = useState({
		catalogProductId: '',
		state: false,
		price: 0,
	});

	const addToCart = useAddToMyCart();
	const {
		GetProductStatusRequest,
		GetProductStatusError,
		GetProductResponseRequest,
	} = useAppSelector(selectProducts);

	const { qttyCartStatusRequest, qttyCartStatusError, qttyCart } =
		useAppSelector(selectCart);

	const getProductById = useGetProductById();
	const getQttyCart = useGetQttyCart();

	useEffect(() => {
		if (id) {
			getProductById(id);
			getQttyCart(id);
			setLoading(false);
		}
	}, [loading]);

	useEffect(() => {
		if (qttyCartStatusRequest === 1 && GetProductStatusRequest === 1) {
			setQttyInCart(qttyCart!.qtty as number);
			setAddProduct(qttyCart!.qtty > 0);
			const distributorMinPrice =
				GetProductResponseRequest?.catalogProductFrontendList.find((cpfl) =>
					Math.min(cpfl.price)
				);
			setSelectDistributor({
				catalogProductId: distributorMinPrice!.catalogProductId,
				state: true,
				price: distributorMinPrice!.price,
			});
			const distributor =
				GetProductResponseRequest?.catalogProductFrontendList.find(
					(cpfl) => cpfl.catalogProductId === qttyCart!.catalogProductID
				);
			if (qttyCart!.qtty > 0) {
				setDisabledButton(false);
				setSelectDistributor({
					catalogProductId: defaultDistributor
						? distributorMinPrice!.catalogProductId
						: distributor!.catalogProductId,
					state: true,
					price: defaultDistributor
						? distributorMinPrice!.price
						: distributor!.price,
				});
			}
			setLoading(false);
		} else if (qttyCartStatusRequest === 2 && GetProductStatusRequest === 1) {
			console.log('Error loading get qtty', qttyCartStatusError);
			const distributorMinPrice =
				GetProductResponseRequest?.catalogProductFrontendList.find((cpfl) =>
					Math.min(cpfl.price)
				);
			setSelectDistributor({
				catalogProductId: distributorMinPrice!.catalogProductId,
				state: true,
				price: distributorMinPrice!.price,
			});
			setLoading(false);
		}
	}, [qttyCartStatusRequest, GetProductResponseRequest]);

	if (GetProductStatusRequest === 1) {
		dataViewItem = GetProductResponseRequest;
	} else if (GetProductStatusRequest === 2) {
		console.log('Error loading viewItem', GetProductStatusError);
	}

	const clickAddProduct = (catalogProductId: any) => {
		if (!localStorage.getItem('jwt')) {
			window.location.href = '/register';
			return;
		} else {
			addToCart({
				catalogProductId: catalogProductId,
				quantity: 1,
			});
			setAddProduct(true);
			setQttyInCart(1);
		}
	};

	const updateItem = (quantity: number, catalogProductId: any) => {
		addToCart({
			catalogProductId,
			quantity,
		});
		setLoading(true);
	};

	const seletedDistributor = (distributor: any, index: number) => {
		if (qttyInCart > 0) {
			setLoading(true);
			addToCart({
				catalogProductId: distributor.catalogProductId,
				quantity: qttyInCart,
			});
		}

		setDefaultDistributor(false);
		setSelectDistributor({
			catalogProductId: distributor.catalogProductId,
			state: true,
			price: distributor.price.toFixed(2),
		});
	};

	const handleQuantityConfirm = () => {
		updateItem(qttyInCart, selectDistributor.catalogProductId);
	};

	return (
		<ViewViewItem
			classes={classes}
			dataViewItem={dataViewItem}
			loading={loading}
			onClick={clickAddProduct}
			addProduct={addProduct}
			updateItem={updateItem}
			seletedDistributor={seletedDistributor}
			selectDistributor={selectDistributor}
			qttyInCart={qttyInCart}
			disabled={disabledButton}
			handleQuantityConfirm={handleQuantityConfirm}
			quantityValue={qttyInCart}
			quantitySetter={setQttyInCart}
		/>
	);
};
