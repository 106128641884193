import { Fragment, FunctionComponent } from 'react';
import {
	NavBarHome,
	Card,
	Footer,
	BreadCrumbs,
	SideBarCatalog,
	CartButton,
} from 'components';
import { Box, CircularProgress, Grid, Pagination } from '@mui/material';
import { Cpel, GetProductCatalogResponse } from 'data/types/catalogTypes';

type Props = {
	catalogResponse: GetProductCatalogResponse | null;
	onUpdateFilters: (ids: any[]) => void;
	loadingCart: boolean;
	page: number;
	query: string | undefined;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const CatalogView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome query={props.query} />
		<Grid
			container
			display='flex'
			justifyContent='center'
			sx={{
				backgroundColor: '#F7F5F7',

				paddingTop: '2em',
			}}
		>
			{/* <Grid item xs={10}>
				<BreadCrumbs />
			</Grid> */}
			<Grid item xs={12} display='flex' justifyContent='end' mr='20px'>
				<CartButton />
			</Grid>
		</Grid>

		<Grid
			container
			sx={{ backgroundColor: '#F7F5F7' }}
			display='flex'
			justifyContent='center'
		>
			<Grid item xs={3}>
				<SideBarCatalog
					onUpdateFilters={props.onUpdateFilters}
					category={props.catalogResponse?.categoryName}
					items={props.catalogResponse?.hits ? props.catalogResponse.hits : 0}
					itemsByBrand={props.catalogResponse?.itemCountByBrand}
				/>
			</Grid>
			<Grid
				item
				xs={6}
				sm={9}
				md={9}
				lg={8}
				display='flex'
				justifyContent='center'
			>
				{props.loadingCart ? (
					<Grid container columns={{ xs: 6, sm: 8, md: 12, lg: 9 }} mt={6}>
						<CircularProgress />
					</Grid>
				) : (
					<div>
						{props.query ? (
							<section>
								<b>Busqueda:</b>&nbsp; {props.query}
							</section>
						) : (
							<></>
						)}
						<div>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								sx={{
									backgroundColor: '#F7F5F7',
									paddingBottom: '0px',
								}}
								pb={10}
								pt={10}
							>
								<Pagination
									count={(props.catalogResponse?.cantPage ?? 0) + 1}
									page={props.page}
									color='secondary'
									size='large'
									onChange={(event, value) => {
										props.setPage(value);
									}}
								/>
							</Box>
						</div>
						<Grid container columns={{ xs: 6, sm: 8, md: 12, lg: 9 }}>
							{props.catalogResponse &&
								props.catalogResponse.cpel.map((cpel: Cpel) => {
									const catalogProductList = [
										...cpel.catalogProductFrontendList,
									];
									catalogProductList.sort((a, b) => a.price - b.price); // ordenamos por menor precio
									const catalogProduct = catalogProductList[0];
									return (
										<Grid
											item
											xs={4}
											sm={4}
											md={6}
											lg={3}
											key={catalogProduct.catalogProductId}
										>
											<Card
												catalogProduct={catalogProduct}
												masterPodruct={cpel.masterProductFrontend}
												qttyInCart={cpel.qttyInCart}
												priceInCart={cpel.priceInCart}
											/>
										</Grid>
									);
								})}
						</Grid>
						<div>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								sx={{
									backgroundColor: '#F7F5F7',
								}}
								pb={10}
								pt={10}
							>
								<Pagination
									count={(props.catalogResponse?.cantPage ?? 0) + 1}
									page={props.page}
									color='secondary'
									size='large'
									onChange={(event, value) => {
										props.setPage(value);
									}}
								/>
							</Box>
						</div>
					</div>
				)}
			</Grid>
		</Grid>
		{/* <Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			sx={{
				backgroundColor: '#F7F5F7',
			}}
			pb={10}
			pt={10}
		>
			<Pagination
				count={(props.catalogResponse?.cantPage ?? 0) + 1}
				page={props.page}
				color='secondary'
				size='large'
				onChange={(event, value) => {
					props.setPage(value);
				}}
			/>
		</Box> */}
		<Footer />
	</Fragment>
);
