import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	status: {
		primary: '#FF6651',
		secondary: '#5C41BD',
	},
	palette: {
		primary: {
			main: '#FF6651',
		},
		secondary: {
			main: '#5C41BD',
		},
		success: {
			main: '#27C451',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			xxl: 1910,
		},
	},
});

export default theme;
