import React, { FunctionComponent } from 'react';
import NavBarHomeView from './view';
import { useNavigate } from 'react-router-dom';

interface Props {
	login?: string | null;
	query?: string;
}

export const NavBarHome: FunctionComponent<Props> = ({ login, query }) => {

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const jwt = localStorage.getItem('jwt');

	const open = Boolean(anchorEl);

	const navigate = useNavigate();
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorElMenu);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElMenu(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorElMenu(null);
	};

	const handleSearch = (q: string) => {
		window.location.href = `/catalog?q=${q}`;
	}

	if(localStorage.getItem('jwt')) {
		const jwtBody = localStorage.getItem('jwt')!.split('.')[1];
		const jwtData = JSON.parse(atob(jwtBody));
		if(jwtData.exp - Math.round(Date.now()/1000) <= 0) {
			localStorage.removeItem('jwt');
			localStorage.removeItem('distributorsSelected');
            localStorage.removeItem('email');
		}
		else if (localStorage.getItem('distributorsSelected') === 'false') {
			navigate('/registerDistributors');
		}
	}

	return (
		<NavBarHomeView
			open={open}
			handleClose={handleClose}
			handleClick={handleClick}
			onSearch={handleSearch}
			anchorEl={anchorEl}
			openMenu={openMenu}
			handleCloseMenu={handleCloseMenu}
			handleClickMenu={handleClickMenu}
			anchorElMenu={anchorElMenu}
			query={query}
			login={jwt}
		/>
	);
};

export default NavBarHome;
