import { FunctionComponent, Fragment } from 'react';
import { NavBarHome, Footer } from 'components';
import { Box, Grid } from '@mui/material';
import { ItemOrder } from 'components/ItemOrder';
import { GetOrdersResponse, Order } from 'data/types/ordersTypes';

type Props = {
	orders: GetOrdersResponse | null;
	classes: any;
};

export const ViewOrders: FunctionComponent<Props> = (props) => (
	<Fragment>
		{props.orders ? (
			<Box sx={{ backgroundColor: '#F7F5F7' }}>
				<NavBarHome></NavBarHome>
				<Box
					color='black'
					bgcolor='white'
					fontSize={30}
					fontWeight={800}
					pl={10}
					pt={4}
					pb={1}
					mb={5}
				>
					Mis cotizaciones
				</Box>
				<Grid container display='flex' justifyContent='center' mb={15}>
					{props.orders &&
						props.orders.map((order: Order, index) => {
							return <ItemOrder order={order} key={order.orderID}></ItemOrder>;
						})}
				</Grid>

				<Footer />
			</Box>
		) : (
			<div className={props.classes.loading}>Cargando Cotizaciones...</div>
		)}
	</Fragment>
);
