import { Layout, LeftSectionRegisterDistributors, NavBarRegister, RightSectionRegisterDistributors } from 'components';
import { FunctionComponent } from 'react';

type Props = {
};

export const ViewRegisterDistributors: FunctionComponent<Props> = (props) => (
	<Layout leftSection={<LeftSectionRegisterDistributors />} rightSection={<RightSectionRegisterDistributors />} rightSize={6} leftSize={6} backgroundImage="Background04">
		<NavBarRegister></NavBarRegister>
	</Layout>
);