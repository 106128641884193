import { FunctionComponent } from 'react';
import { Tabs, Tab, TabPanel } from 'UI';
import { Box } from '@mui/material';
import { RegisterForm, FormLogin, VerifyEmail } from 'components';

type Props = {
	classes: any;
	handleChange?: (event: React.SyntheticEvent, value: any) => void;
	value: any;
	a11yProps?: any;
};

const RightFormView: FunctionComponent<Props> = (props) => (
	<div>
		<Box>
			<Box px={3}>
				<Tabs
					variant='fullWidth'
					value={props.value}
					onChange={props.handleChange}
				>
					<Tab label='Creá tu cuenta' {...props.a11yProps(0)}></Tab>
					<Tab label='Iniciá sesión' {...props.a11yProps(1)}></Tab>
				</Tabs>
			</Box>
			<TabPanel value={props.value} index={0}>
				<RegisterForm />
			</TabPanel>
			<TabPanel value={props.value} index={1}>
				<FormLogin />
			</TabPanel>
		</Box>
	</div>
);

export default RightFormView;
