import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import {FunctionComponent, useState} from 'react';
import { Input } from "./Input";
import { ControlProps } from 'models/form';

const GOOGLE_MAPS_API_KEY = 'AIzaSyA_fT5Z5XUTJcYgMKjmVBLp_hwScpmHHiQ';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface Terms {
  offset: number;
  value: string;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  terms: Terms[];
}

type Props = {
  setValueForm: UseFormSetValue<FieldValues>,
  errors: any,
  controllerProps: ControlProps
  onSelectLocation: (location: string) => void
}

export const GoogleMaps: FunctionComponent<Props> = ({ onSelectLocation, ...props }) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);
  const [selectedLocation, setSelectedLocation] = useState('');

  const handleSelectLocation = (location: string) => {
    setSelectedLocation(location);
    onSelectLocation(location);
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&region=ar&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const placesOptions = {
    types: ['geocode'],
    componentRestrictions: { country: "ar" }
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            {...request,...placesOptions},
            callback,
          );
        },
        200,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService(null, placesOptions);
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      fullWidth
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={'No hay ubicaciones para mostrar'}
      onChange={(event: any, newValue: PlaceType | null) => {
        if (newValue) {
          const placesService = new google.maps.places.PlacesService(document.createElement('div'))
          placesService.getDetails({placeId: newValue.place_id}, (PlaceResult, PlacesServiceStatus)=>{
            const lat = PlaceResult.geometry?.location.lat()
            const lng = PlaceResult.geometry?.location.lng()
            console.log(newValue)
            props.setValueForm("address.lat", lat)
            props.setValueForm("address.lng", lng)
            props.setValueForm("address.street", newValue.terms[0].value)
            props.setValueForm("address.city", newValue.terms[1]?.value || "")
            handleSelectLocation(newValue.terms[newValue.terms.length - 1]?.value);
          })
        }
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <Input
							controllerProps={props.controllerProps}
							errors={props.errors}
							type='text'
							placeholder='Indicanos tu ubicación'
              {...params}
						/>
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: any) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
