import React, { useEffect } from 'react';
import { FunctionComponent, useState } from 'react';
import CardView from './view';
import useStyles from './useStyles';
import { CatalogProduct, MasterPodruct } from 'data/types/catalogTypes';
import { useAddToMyCart } from './hooks';
import { selectCart } from 'data/global/features/CartSlice/cartSlice';

import { useAppSelector } from 'data/global/hooks';
interface Props {
	catalogProduct: CatalogProduct;
	masterPodruct: MasterPodruct;
	qttyInCart?: any;
	priceInCart?: any;
}

export const Card: FunctionComponent<Props> = (props) => {
	const classes = useStyles();
	const {
		qttyCartStatusRequest,
		qttyCartStatusError,
		qttyCart,
		AddCartStatusRequest,
	} = useAppSelector(selectCart);

	const updateCart = useAddToMyCart();
	const [qttyInCart, setQttyInCart] = useState(props.qttyInCart as number);
	const [catalogProductID, setCatalogProductID] = useState('');
	const [loading, setLoading] = useState(false);
  const [editingQuantity, setEditingQuantity] = useState(false);

	useEffect(() => {
		if (qttyCartStatusRequest === 1) {
			setCatalogProductID(qttyCart?.catalogProductID as string);
			setLoading(false);
		} else if (qttyCartStatusRequest === 2) {
			console.log('Error loading get qtty', qttyCartStatusError);
		}
	}, [qttyCartStatusRequest]);

	const clickAddProduct = () => {
		if (!localStorage.getItem('jwt')) {
			window.location.href = '/register';
		} else {
			updateCart({
				catalogProductId: props.catalogProduct.catalogProductId,
				quantity: 1,
			});
			setLoading(true);
			setQttyInCart(1);
		}
	};

	const clickUpdateProduct = (qtty: number, catalogProductId: any) => {
		updateCart({
			catalogProductId: catalogProductId,
			quantity: qtty,
		});
		setLoading(true);
		setQttyInCart(qtty);
	};

	useEffect(() => {
		if (AddCartStatusRequest === 1) {
			setLoading(false);
		}
	}, [AddCartStatusRequest]);

  const handleQuantityConfirm = () => {
    clickUpdateProduct(qttyInCart, props.catalogProduct.catalogProductId)
    setEditingQuantity(false);
  }

	return (
		<CardView
      catalogProduct={props.catalogProduct}
      masterPodruct={props.masterPodruct}
      onClick={clickAddProduct}
      clickUpdateProduct={clickUpdateProduct}
      qttyInCart={qttyInCart}
      catalogProductID={catalogProductID}
      classes={classes}
      loading={loading}
      priceInCart={props.priceInCart}
      qttyCartCatalog={props.qttyInCart}
      handleQuantityConfirm={handleQuantityConfirm}
      quantityValue={qttyInCart}
      quantitySetter={setQttyInCart} 
      editingQuantity={editingQuantity}
      setEditingQuantity={setEditingQuantity}    
    />
	);
};
