import React, { useState } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { regexSpecialChracter } from 'utils/validationRules';

export const useValidatePasswordChecks = (watch: UseFormWatch<FieldValues>) => {
	const [isValidPassword, setIsValidPassword] = useState({
		lengthValid: false,
		hasNumber: false,
		hasSpecialCharacter: false,
	});
	React.useEffect(() => {
		const subscription = watch(({ password }) => {
			let isValidPasswordAux = isValidPassword;
			if (/\d/.test(password)) {
				isValidPasswordAux = {
					...isValidPasswordAux,
					hasNumber: true,
				};
				setIsValidPassword(isValidPasswordAux);
			} else {
				isValidPasswordAux = {
					...isValidPasswordAux,
					hasNumber: false,
				};
				setIsValidPassword(isValidPasswordAux);
			}
			if (regexSpecialChracter.test(password)) {
				isValidPasswordAux = {
					...isValidPasswordAux,
					hasSpecialCharacter: true,
				};
				setIsValidPassword(isValidPasswordAux);
			} else {
				isValidPasswordAux = {
					...isValidPasswordAux,
					hasSpecialCharacter: false,
				};
				setIsValidPassword(isValidPasswordAux);
			}
			if (password.length >= 6) {
				isValidPasswordAux = {
					...isValidPasswordAux,
					lengthValid: true,
				};
				setIsValidPassword(isValidPasswordAux);
			} else {
				isValidPasswordAux = {
					...isValidPasswordAux,
					lengthValid: false,
				};
				setIsValidPassword(isValidPasswordAux);
			}
		});

		return () => subscription.unsubscribe();
	}, [isValidPassword, watch]);

	return isValidPassword;
};

export default useValidatePasswordChecks;
