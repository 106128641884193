import React, { useEffect, useRef, useState } from 'react';
import ResetPasswordView from './view';
import useStyles from './useStyles';
import {  rulesPassword } from 'utils/validationRules';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useChangePassword from './hooks/useChangePassword';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useValidatePasswordChecks } from '../RegisterForm/hooks';
import { useAppSelector } from '../../data/global/hooks';
import { selectUser } from '../../data/global/features/UserSlice/userSlice';
import { StatusRequest } from '../../data/types';

export const ResetPassword: React.FC = () => {
	const params = useParams();
	const classes = useStyles();
	const { t } = useTranslation();
	const changePassword = useChangePassword();
	const navigate = useNavigate();
	const { changePasswordStatusError, changePasswordStatusRequest } =
		useAppSelector(selectUser);
	//React Hook Form
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onBlur' });

	const isValidPassword = useValidatePasswordChecks(watch);

	const rules = {
		rulesPassword: rulesPassword(t),
	};
	const defaultValue = {
		newPassword: '',
		repeatPassword: '',
		code:params.code
	};

	const passwordRef = useRef(null);
	const password2Ref = useRef(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (changePasswordStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else if (changePasswordStatusRequest === StatusRequest.fulfilled && !changePasswordStatusError?.error) {
			setLoading(false);
			navigate('/changePasswordSuccessfully');
		}else  {
			setLoading(false);
		}
	}, [changePasswordStatusRequest]);

	return (
		<ResetPasswordView
			changePassword={changePassword}
			response={changePasswordStatusError}
			classes={classes}
			formProps={{
				handleSubmit,
				errors,
				isSubmitting,
				isValid,
				setValue,
				getValues,
			}}
			refInputs={{ passwordRef, password2Ref }}
			isValidPassword={isValidPassword}
			controlProps={{ control, defaultValue, rules }}
			loading={loading}
		/>
	);
};

export default ResetPassword;
