import { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Layout, NavBarRegister, RightFormRegister, VerifyEmail } from 'components';
import cotizaciones from 'fonts/cotizaciones.svg';
import promociones from 'fonts/promociones.svg';
import tiempo from 'fonts/tiempo.svg';
import { StatusRequest } from 'data/types';

type Props = {
	classes: any;
	createUserStatusRequest: StatusRequest
};

export const ViewRegister: FunctionComponent<Props> = (props) => (
	<Layout
		leftSection={LeftSection}
		rightSection={props.createUserStatusRequest === StatusRequest.fulfilled ? <VerifyEmail/> : <RightFormRegister />}
		leftSize={8}
		rightSize={4}
		backgroundImage='Background01'
	>
		<NavBarRegister></NavBarRegister>
	</Layout>
);

export const LeftSection = (
	<Box>
		<Grid container>
			<Grid item xs={8}>
				<Typography variant='h1' fontSize={50} color='white'>
					Por qué DeÚtiles te conviene
				</Typography>
			</Grid>
		</Grid>
		<Grid container pt={6} alignItems='center'>
			<Grid item xs={4} display='flex' justifyContent='end' mr={4}>
				<span
					style={{
						background: '#5C41BD 0% 0% no-repeat padding-box',
						width: '62px',
						height: '62px',
						borderRadius: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={cotizaciones}
						alt=''
						style={{
							background: '#5C41BD 0% 0% no-repeat padding-box',
							color: 'white',
							width: '40px',
						}}
					></img>
				</span>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='h6' color='white'>
					Obtenés múltiples cotizaciones para un mismo producto o pedido.
				</Typography>
			</Grid>
		</Grid>
		<Grid container pt={6} alignItems='center'>
			<Grid item xs={4} display='flex' justifyContent='end' mr={4}>
				<span
					style={{
						background: '#5C41BD 0% 0% no-repeat padding-box',
						width: '62px',
						height: '62px',
						borderRadius: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={tiempo}
						alt='icono-tiempo'
						style={{
							background: '#5C41BD 0% 0% no-repeat padding-box',
							color: 'white',
							width: '40px',
						}}
					></img>
				</span>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='h6' color='white'>
					Ahorrás tiempo para generar tus pedidos.
				</Typography>
			</Grid>
		</Grid>
		<Grid container pt={6} alignItems='center'>
			<Grid item xs={4} display='flex' justifyContent='end' mr={4}>
				<span
					style={{
						background: '#5C41BD 0% 0% no-repeat padding-box',
						width: '62px',
						height: '62px',
						borderRadius: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={promociones}
						alt=''
						style={{
							background: '#5C41BD 0% 0% no-repeat padding-box',
							color: 'white',
							width: '40px',
						}}
					></img>
				</span>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='h6' color='white'>
					Aprovechas las promociones de los fabricantes.
				</Typography>
			</Grid>
		</Grid>
	</Box>
);
