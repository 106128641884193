import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment, FunctionComponent } from 'react';
import { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

type Props = {
    setValue?: UseFormSetValue<FieldValues>;
    getValues?: UseFormGetValues<FieldValues>;
    refInput: any;
    nameInput: string;
};

export const CloseButtonInput: FunctionComponent<Props> = (props) => {
    return (props.getValues && props.getValues(props.nameInput) != "" && document.activeElement === props.refInput.current) ?
        <IconButton onClick={() => {
            if (props.setValue) {
                props.setValue(props.nameInput, "");
                props.refInput.current.focus()
            }
        }}>
            <Close />
        </IconButton> : <Fragment></Fragment>
};
export default CloseButtonInput;
