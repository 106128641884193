import { GetCategoriesAsync, selectCategories } from 'data/global/features/CategoriesSlice/categoriesSlice';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import MenuCategoriesView from './view';

interface Props {
	handleClick?: (e: any) => void;
	handleClose?: (e: any) => void;
	open?: any;
	anchorEl?: any;
}

export const MenuCategories: FunctionComponent<Props> = (props) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(GetCategoriesAsync({}));
	}, [])

	const {categories} = useAppSelector(selectCategories);
	
	return (
		<MenuCategoriesView
			open={props.open}
			handleClose={props.handleClose}
			categories={categories}
			anchorEl={props.anchorEl}
		/>
	);
};

export default MenuCategories;
