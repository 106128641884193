import { Fragment, FunctionComponent } from 'react';
import {
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavBarHome } from 'components';

type Props = {};

export const ViewFaQ: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome />
		<Grid container>
			<Grid item xs={10} m={6} mt={12}>
				<Typography
					variant='h1'
					fontSize={30}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
				>
					Preguntas Frecuentes
				</Typography>
			</Grid>
			<Grid item xs={10} ml={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Que es deÚtiles?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Un canal digital para que librerías y distribuidores puedan
							agilizar sus compras y ventas respectivamente.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Cómo me creo un usuario de librería?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Debes ingresar tu nombre completo, email, nombre de tu librería,
							razón social, cuit, dirección y teléfono de contacto.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Por qué debo ingresar todos estos datos?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Gracias a los datos presentados, nuestro equipo puede validar la
							veracidad real de la librería.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Por qué es necesario elegir 4 distribuidores?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Con tu selección de distribuidores podemos mostrarte la oferta
							disponible de tus distribuidores preferenciales y puedas escoger
							el mejor precio posible.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Puedo elegir el modo de entrega?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Dependiendo de cada distribuidor podrás seleccionar en el momento
							del checkout el método de entrega (pickup o delivery).
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Puedo contactarme con el equipo de deÚtiles?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Si contamos con un equipo disponible para ayudarte en todas tus
							consultas. Podes escribirnos a nuestro Whatsapp al +5491159364962
							o a nuestra casilla de correo info@deutiles.com
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							En caso de tener un problema con mi cotización, ¿qué hago?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							El equipo de soporte se contactará con vos para poder solucionar
							cualquier inconveniente para que tengas la mejor experiencia
							posible.
						</Typography>
					</AccordionDetails>
				</Accordion>
                <Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Qué costos tiene el uso de la plataforma?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							DeUtiles es 100% gratuito. Tanto registrarse como operar a través
							de nuestra plataforma es gratuito y no tiene costos asociados. 
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Quién se encarga de enviar el pedido?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							El mismo mayorista es quien te envía el pedido. Nosotros nos 
							encargamos de gestionar el envío para que el pedido te llegue el 
							día y horario que vos nos indiques.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿A quién le pago y cuáles son los métodos de pagos disponibles?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Actualmente contamos con dos métodos de pago: efectivo al momento 
							de la entrega o mediante transferencia bancaria antes del envío.
							En ambos casos, se le paga directamente al mayorista. Nosotros nos 
							vamos a encargar de proporcionar los datos necesarios para hacer 
							el pago una vez que la orden de compra esté confirmada.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Por que comprar a través de DeUtiles y no directamente al mayorista?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							A través de DeUtiles vas a poder comparar los precios de 
							distintos mayoristas desde un solo lugar y seleccionar los
							productos que más te convengan para tu negocio. De esta 
							forma ahorrá dinero, eficientizá tu tiempo y hacé una 
							compra inteligente. Un detalle no menor: La plataforma es
							100% gratuita.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Existen mínimos de entrega y costos de envío?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Los montos mínimos de entrega y el costo de envío los define 
							cada mayorista. Si tenés alguna duda sobre un mayorista en 
							particular, contactanos a través de nuestro Whatsapp o email 
							que nosotros te damos la información que necesites.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Cómo se realiza una compra?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							En primer lugar debes seleccionar los productos que queres 
							comprar y a que mayoristas. Luego, debes escoger entre pickup o 
							delivery y finalmente hacer click en generar cotización.
							Una vez que tu cotización es creada un integrante de nuestro 
							equipo se va a contactar con vos para ajustar los últimos detalles
							de tu orden. Cualquier cambio que quieras hacer en tu pedido se lo
							podes comunicar a esa persona sin problema!
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Los precios que vemos son finales?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Así es, los precios que ves en nuestra plataforma son finales e 
							incluyen IVA.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography
							fontSize={25}
							fontFamily={'Krub'}
							textAlign={'justify'}
							fontWeight={'bold'}
						>
							¿Cuales son los tiempos estimados de entrega?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography fontSize={25} fontFamily={'Krub'} textAlign={'justify'}>
							Una vez confirmada la orden, los pedidos son entregados en 
							promedio dentro de las 48 hs.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid>
		</Grid>
	</Fragment>
);

export default ViewFaQ;
