import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles((theme: Theme) => ({
	linkEmail: {
		fontWeight: 'normal',
		color: '#5C41BD',
		marginLeft: '4px',
	},
}));
export default useStyles;
