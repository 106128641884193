import React, { FunctionComponent } from 'react';
import ItemOrderView from './view';
import { Order } from 'data/types/ordersTypes';
import useStyles from './useStyles';
export interface Props {
	order: Order;
}

export const ItemOrder: FunctionComponent<Props> = (props) => {
	const classes = useStyles();
	return <ItemOrderView order={props.order} />;
};
