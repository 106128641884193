import React from 'react';
import CategoriesVisitedView from './view';
import { FunctionComponent } from 'react';
import { Category } from 'data/types/categoriesTypes';

interface Props {
	category: Category;
}

export const CategoriesVisited: FunctionComponent<Props> = (props) => {
	return <CategoriesVisitedView category={props.category} />;
};
