import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	loading: {
		padding: '2vw',
		background: 'linear-gradient(57deg, #ff6651, #5c41bd)',
		borderRadius: '1vw',
		margin: '2vw',
		textAlign: 'center',
		color: 'white',
		fontSize: '1.5vw',
		boxShadow: '0px 0px 15px #c9c9c9',
	},
}));
export default useStyles;
