import { Fragment, FunctionComponent } from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { NavBarHome } from 'components';

type Props = {};

export const ViewTermsAndConditions: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome />
		<Grid container>
			<Grid item xs={10} m={8} mt={12}>
				<Typography
					variant='h1'
					fontSize={30}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
				>
					DEÚTILES
				</Typography>
				<Typography
					variant='h1'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mt={6}
				>
					TÉRMINOS Y CONDICIONES - USUARIOS
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					INTRODUCCIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Los presentes términos y condiciones generales (en adelante, los
					“Términos y Condiciones), regulan los derechos y obligaciones de los
					usuarios (De acuerdo a la definición brindada más adelante) en el
					marco del uso del sitio web www.deutiles.com.ar y de las aplicaciones
					móviles deÚtiles, (en adelante, indistintamente la “Aplicación”).
					YOPDEV S.A.S. (En adelante “YOPLABS”), con CUIT 30-71672766-8 y
					domicilio en la calle Echeverria 1515 en Ciudad Autonoma de Buenos
					Aires, es el administrador de la Aplicación. Es condición previa y
					esencial que el usuario lea y acepte las condiciones establecidas en
					los Términos y Condiciones y sus{' '}
					<Link
						href='/privatePolicies'
						color='inherit'
						underline='none'
						style={{ cursor: 'pointer' }}
					>
						Políticas de Privacidad
					</Link>
					, antes de usar la Aplicación. Por usuario (en adelante, el “Usuario”)
					se entiende a la persona humana que acceda a la Aplicación y realiza
					actos de reserva, compraventa y/o prestación de servicios a través de
					la Aplicación . Por Distribuidor (En adelante, los “Distribuidores”)
					se entiende a la persona humana o jurídica titular de un distribuidor
					que se registre para ofrecer y aceptar los actos de reserva y/o
					compraventa de sus productos o servicios por parte de los Usuarios. La
					Aplicación se encuentra dirigida y limitada exclusivamente a Librerías
					de la República Argentina YOPLABS pone a disposición, a través de la
					Aplicación, una plataforma que facilita la vinculación de las
					librerías con los distribuidores adheridos (En adelante, los
					“distribuidores”), quienes exhibirán sus productos y/o servicios a
					través de la misma para que los usuarios puedan celebrar actos de
					reserva, de compraventa o de prestación de servicios con dichos
					distribuidores.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					ACEPTACION DE LOS TÉRMINOS Y CONDICIONES
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={2}
				>
					El Usuario conoce y acepta que YOPLABS no es parte en las operaciones
					de reserva, compraventa o prestación de servicios que puedan acordarse
					entre las librerías y los distribuidores. En caso de reclamo o
					requerimiento, el Usuario deberá realizarlo ante el distribuidor que
					corresponda. Es requisito obligatorio para descargar y utilizar la
					Aplicación, la aceptación previa de los Términos y Condiciones, no
					pudiendo aducirse el desconocimiento de los mismos. Al aceptar estos
					Términos y Condiciones, los Usuarios declaran haberse informado de
					manera clara, comprensible e inequívoca de los mismos. Los Términos y
					Condiciones serán aplicados y se entenderán incorporados en todas las
					transacciones realizadas por los Usuarios con los distribuidores,
					mediante los sistemas de reserva y de comercialización comprendidos en
					la Aplicación. Si no está de acuerdo con todos estos Términos y
					Condiciones, los cuales son de carácter obligatorio y vinculante, no
					utilice la Aplicación.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CAPACIDAD PARA CONTRATAR
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					La Aplicación está disponible sólo para aquellas personas humanas que
					tengan capacidad legal para contratar y sean mayores de edad, según lo
					dispuesto por la legislación argentina vigente. En la República
					Argentina tal capacidad se adquiere a los 18 años de edad. Si un
					Usuario no tiene capacidad legal para contratar, debe abstenerse de
					utilizar la Aplicación. En cualquier momento se podrá suspender, de
					forma temporal o definitiva, la participación del Usuario respecto de
					los que se compruebe que carecen de capacidad legal para usar la
					Aplicación o cuando al registrarse brinden información que sea falsa,
					inexacta o fraudulenta. El distribuidor es responsable de operar
					(entregar o dispensar) en pleno cumplimiento de las leyes aplicables a
					la venta de artículos escolares y de oficina.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					REGISTRACIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El acceso a la aplicación es gratuito, a excepción del costo del
					servicio de conexión a la red de telecomunicaciones a través del
					proveedor de servicios, el cual estará a cargo del Usuario. Para la
					descarga de la Aplicación el Usuario deberá contar con un equipo de
					telefonía móvil tipo “Smartphone” con sistema operativo IOS o Android.
					Para realizar actos de reserva y compraventa a través de la Aplicación
					será necesario el registro del Usuario .A los efectos del registro del
					Usuario, se le solicitará que cree una cuenta debiendo ingresar en el
					Formulario de Registración (en adelante, el “Formulario de
					Registración”) la totalidad de los siguientes datos de carácter
					personal: Nombre y Apellido, Dirección,, Dirección de correo
					electrónico (e-mail), y Teléfono (en adelante, los “Datos
					Personales”). El Usuario declara y garantiza la veracidad, exactitud,
					vigencia y autenticidad de los Datos Personales ingresados en el
					Formulario de Registración y asume el compromiso de mantener dichos
					Datos Personales actualizados. Si no lo hace, estará incurriendo en un
					incumplimiento de estos Términos y Condiciones, lo que podría dar
					lugar, entre otras cosas, a la rescisión inmediata de estos Términos y
					Condiciones y del uso de la Aplicación. Esto no obsta a la obligación
					de YOPLABS de rectificar, actualizar o suprimir los datos incorrectos
					y demás obligaciones dispuestas en la Ley N° 25.326 y en sus Políticas
					de Privacidad. YOPLABS, y DEUTILES, como asimismo los Distribuidores,
					no serán responsables por la veracidad, exactitud, vigencia y
					autenticidad de los Datos Personales ingresados por el Usuario en la
					Aplicación. Una vez efectuado el Registro, YOPLABS otorgará al Usuario
					una cuenta personal para acceder con la contraseña que elija (en
					adelante, la “Cuenta”). Toda cuenta es personal e intransferible. El
					Usuario es responsable de mantener la confidencialidad de su cuenta,
					nombre de usuario y contraseña, y acepta asumir la responsabilidad por
					todas las actividades que efectuadas a través de la misma. Si los
					Usuarios tuvieran cuenta en Facebook, en Google o Apple ID se le podrá
					ofrecer la posibilidad de crear su Cuenta con la utilización de las
					credenciales allí incluidas. En el caso de que un Usuario haya
					olvidado su contraseña o desee cambiarla, podrá reemplazarla
					ingresando a su perfil dentro de la Aplicación, o enviando un e-mail a
					<a href='mailto:info@deutiles.com'> info@deutiles.com </a> desde la
					casilla de mail que figure en su Cuenta El Usuario se compromete a
					notificar a YOPLABS en forma inmediata cualquier sospecha, certeza o
					temor de un acceso no autorizado a su cuenta, así como del
					conocimiento por terceros de su contraseña. A tal efecto, el Usuario
					deberá ingresar a su perfil dentro de la Aplicación, o enviar un
					e-mail a <a href='mailto:info@deutiles.com'> info@deutiles.com </a>
					desde la casilla de mail que figura en su Cuenta. Al momento de su
					registración, el Usuario acepta recibir de YOPLABS correos
					electrónicos con información sobre promociones y otros productos. El
					Usuario siempre podrá solicitar el cese de envío de los correos
					electrónicos, enviando un email a la siguiente dirección de correo
					electrónico <a href='mailto:info@deutiles.com'>“info@deutiles.com”</a>
					o bien haciendo click en el botón de “Unsuscribe” al momento de
					recibir un correo. YOPLABS se reserva el derecho a rechazar cualquier
					solicitud de registración o de cancelar una registración previamente
					aceptada, sin que esté obligado a comunicar o exponer las razones de
					su decisión y sin que ello genere algún derecho a indemnización o
					resarcimiento a favor del Usuario.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
				>
					USO DE LA APLICACIÓN
				</Typography>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					sx={{ textDecoration: 'underline' }}
					mb={2}
				>
					PRECIOS Y PRODUCTOS INFORMADOS EN LA APLICACIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					YOPLABS podrá modificar en cualquier momento cualquier información
					contenida en la Aplicación, incluyendo la relacionada con productos,
					precios, disponibilidad y condiciones, respetando las compras que han
					sido aceptadas hasta dicho momento, por lo que las modificaciones solo
					se referirán a operaciones futuras. Todos los precios en la Aplicación
					estarán expresados en pesos argentinos, moneda de curso legal de
					Argentina, e incluyen IVA, salvo que se indique lo contrario. La
					información sobre los productos y precios está sujeta a cambios sin
					previo aviso. YOPLABS no se responsabiliza por modalidad, tiempos y/o
					costos de envío puesto que dicha concertación es privativa del Usuario
					y los distribuidores, no teniendo DEUTILES y su Plataforma injerencia
					alguna. En tal sentido, se aclara que algunos distribuidores podrán
					cobrar el envío. Los productos se publicarán en la Aplicación y cada
					Usuario podrá realizar compras o reservas sujeto al cumplimiento de
					los montos mínimos de compra y/o máximos en caso de aplicar alguna
					promoción. Se deja expresamente establecido que, en caso de no poder
					procesar el pago por factores no imputables a la Aplicación, se dará
					por anulada la compra, exceptuando aquellos casos donde se haya
					gestionado una reserva de producto. (i) Los descuentos sobre los
					precios de los productos, estarán sujetos a condiciones específicas de
					cada campaña, y contendrán visible en la Aplicación. Asimismo, los
					límites de descuento por usuario podrán ser aplicados por cada compra
					según su ubicación a la hora de efectuar la compra, sujeto a las
					condiciones específicas de cada campaña. (ii) Todos los descuentos
					provenientes de acciones de marketing, están sujetos a condiciones
					específicas de cada campaña, tales como vencimiento, límite de
					descuento por compra y disponibilidad según la ubicación, ya que las
					campañas son aplicadas en zonas específicas, excepto en el caso de
					medicamentos) YOPLABS podrá restringir el uso de promociones y
					descuentos cuando los Usuarios hagan uso inadecuado, ilícito o abusivo
					de las mismas.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					FACTURACIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					En cumplimiento de la normativa fiscal vigente, el Usuario debe exigir
					Factura o Ticket al distribuidor como comprobante de la operación
					realizada. La Aplicación es una plataforma que facilita la vinculación
					entre Usuarios y distribuidores, y YOPLABS no participa de las
					operaciones que realizan entre ellos, siendo los distribuidores
					responsables por las obligaciones y cargas impositivas que
					correspondan por las transacciones efectuadas. No podrá imputársele a
					YOPLABS responsabilidad alguna por incumplimientos de cualquier
					naturaleza. La factura deberá emitirse en todos los casos (aun cuando
					la Aplicación introduzca descuentos y/o promociones) por el importe
					total del precio de la venta y en el momento de la entrega del
					producto o pago del precio por parte del Usuario final, lo que sea
					anterior. Cuando la Aplicación pone a disposición de los Usuarios
					finales una promoción y/o descuento, dichos beneficios son concedidos
					directamente por YOPLABS a cada Usuario final en el marco de su
					política de comercialización y de fomento de uso de la Aplicación. En
					estos supuestos, YOPLABS será quien abone a los distribuidores, por
					cuenta y orden del Usuario final, el importe relativo a dicha
					promoción, debiendo el Usuario solo abonar el importe no comprendido
					en la promoción.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					ALCANCE TERRITORIAL
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Los productos ofrecidos por los distribuidores en la Aplicación
					estarán disponibles para su compra y entrega en Argentina; sujeto a
					que haya un distribuidor disponible en el radio de ubicación en el que
					se encuentre el Usuario al momento de realizar su pedido o reserva..
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					PROCEDIMIENTO PARA EFECTUAR COMPRAS Y RESERVAS ONLINE
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El Usuario podrá encontrar en la Aplicación productos y servicios
					ofrecidos por los distintos distribuidores . El Usuario pagará el
					precio correspondiente al distribuidor a través de la Aplicación. El
					Usuario podrá ver los productos y servicios disponibles sin estar
					registrado. Sin embargo, para poder formalizar una reserva,
					compraventa o solicitud de servicios el Usuario debe estar registrado
					con su Cuenta. Un mismo Usuario podrá solicitar o reservar hasta un
					número razonable de reservas . Los Usuarios aceptan en forma
					irrevocable que YOPLABS se reserva el derecho de limitar o bloquear, a
					su solo arbitrio, a cualquier Usuario ante cualquier sospecha de
					fraude, estafa, uso de datos ajenos, etc., o por cualquier otra
					sospecha de riesgo, cualquiera fuese su naturaleza. El Usuario
					reconoce que YOPLABS y sus afiliados no prestan servicios de
					cadetería, mensajería, transporte ni logística. Bajo ninguna
					circunstancia aquellas personas que presten este servicio serán
					considerados empleados de YOPLABS ni de ninguno de sus afiliados. El
					distribuidor presta el Servicio de Cadetería por cuenta y riesgo
					propios y liberan a YOPLABS y sus afiliados de cualquier
					responsabilidad que pudiera surgir durante la prestación del mismo. El
					Usuario tiene que confirmar que todos los datos del pedido y el método
					de pago son correctos. El Usuario recibe una confirmación de que el
					distribuidor aceptó el pedido o reserva.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CANCELACIONES
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Efectuado el pedido o reserva, el Usuario podrá cancelar la misma o el
					Servicio de Cadeteria solicitado siempre que no haya sido aceptada por
					deutiles o el distribuidor. Ante una reserva incompleta y/o incorrecta
					entregada en la dirección indicada, el Usuario deberá gestionar su
					reclamo ante el mismo distribuidor que haya gestionado su pedido, la
					que podrá reembolsar el valor de los productos faltantes y/o
					incorrectos en la tarjeta de crédito y/o débito, de conformidad con lo
					dispuesto por la Ley de Tarjetas de Crédito N° 25.065. YOPLABS se
					reserva el derecho de desconectar al Usuario que, de manera
					reincidente, no retire o reciba el pedido o reserva realizada, sin que
					ello genere algún derecho a indemnización o resarcimiento a favor del
					mismo.. YOPLABS se reserva el derecho a cancelar un pedido o reserva
					sin necesidad de alegar justa causa. En caso de cancelación realizada
					por YOPLABS, el Usuario tendrá derecho al reembolso por hasta el valor
					de la totalidad de la cotización abonada.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					GARANTÍA DE LOS PRODUCTOS
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					YOPLABS no se hace responsable por la garantía de los productos
					adquiridos por los Usuarios. El distribuidor debe responder ante el
					Usuario por cualquier defecto en la calidad de un producto. En caso de
					que un producto adquirido por el Usuario presente un problema de
					calidad, el Usuario deberá contactar directamente al distribuidor de
					la que adquirió el producto, consultar su política de garantía y hacer
					efectiva la misma ante el distribuidor.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CAMBIO Y REVOCACION
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El Usuario podrá realizar el cambio de los productos adquiridos, a
					través de la aplicación, por fallas o defectos de fabricación,
					empaque, transporte, clasificación o por falta de identidad entre lo
					adquirido y recibido. Para ello, dispondrá de un plazo máximo de 30
					(treinta) días desde su recepción. El Usuario tiene el derecho de
					revocar su aceptación de una compra dentro de los diez (10) días de
					recibido el producto, por fallas o defectos de fabricación, empaque,
					transporte, clasificación o por falta de identidad entre lo adquirido
					y recibido, notificando al distribuidor de dicha revocación. Si el
					plazo de revocación vence en un día inhábil, se prorroga hasta el
					primer día hábil siguiente. El derecho de revocación está sujeto a que
					los productos no hayan sido consumidos o abiertos por el Usuario. El
					producto deberá conservar todas sus insignias y etiquetas
					identificadoras colocadas, sin que presenten signos de rotura o
					destrucción. Además, deberá encontrarse en las condiciones en que fue
					entregado, sin haber sido usado, ni abierto. En caso de reclamo o
					requerimiento, el Usuario deberá realizarlo ante los distribuidores
					que corresponda.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					MEDIOS DE PAGO
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					La Aplicación listará los medios de pagos habilitados a tal efecto. En
					caso de medios de pagos operados por terceros, el Usuario deberá
					consultar, y acepta que resultarán aplicables, los términos y
					condiciones de dicha plataforma. En caso de abonar en efectivo, el
					Usuario deberá entregar los importes informados al momento de recibir
					el producto. El Usuario podrá abonar su reserva, a través de los
					medios electrónicos de pago listados en la Aplicación, tales como
					tarjeta de crédito o tarjeta de débito, cupones de descuentos y otros
					que a futuro se habiliten y sean informados a los Usuarios. Las
					transacciones online se confirmarán con la validación y aceptación del
					pago por parte de la entidad bancaria del Usuario o el medio de pago
					elegido. Al momento de realizar el pago online, el Usuario deberá
					cargar la información de su tarjeta de crédito o tarjeta de débito u
					otro medio de pago electrónico autorizado o seleccionar un medio de
					pago que haya sido cargado a la aplicación previamente, a fin de
					abonar el monto de la reserva realizada. Esta información es
					gestionada de forma segura y no es almacenada en los servidores de
					YOPLABS. El Usuario deberá actuar con suma prudencia al momento de
					suministrar sus datos, los cuales deberán ser completos y verídicos
					cuando haya sido efectuada la reserva. Al ingresar sus datos, el
					Usuario garantiza que los mismos son de su propiedad y que tiene
					fondos suficientes para realizar el pago, si este es su método de
					preferencia. Además, garantizara que sus datos corresponden con
					aquellos contenidos en la identificación oficial y original otorgada
					por las autoridades nacionales. El descuento promocional del monto
					total se produce en el momento en el que se realiza el pedido. Ante
					una cancelación del pedido, en aquellos casos previstos en estos
					Términos y Condiciones, la devolución se producirá de manera
					instantánea. Sin embargo, el reintegro depende de los emisores de cada
					medio de pago y sus políticas internas, sobre los cuales YOPLABS no
					tiene ninguna responsabilidad o injerencia. Para estos casos, será
					prueba suficiente del accionar diligente de YOPLABS, la presentación
					de la documentación que acredite el procesamiento de la solicitud de
					devolución del importe abonado. Para aquellos casos donde, en virtud
					de factores asociados a los emisores de los medios de pago
					electrónicos, el importe no pueda ser reintegrado, se procederá a la
					acreditación del mismo, a través de un cupón, en la cuenta del
					Usuario, notificándolo de dicha acción.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					DISPONIBILIDAD DE LA APLICACIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El Administrador del Sistema realizará sus mejores esfuerzos por
					mantener y mejorar la calidad y el funcionamiento del Sistema. Sin
					embargo, esto no garantiza la provisión continuada e ininterrumpida
					del servicio. En consecuencia, el/la usuario/a renuncia expresa e
					irrevocablemente a reclamar cualquier reparación derivada de los
					posibles fallos, lentitud, y/o errores en el acceso y uso del Sistema,
					debido a problemas de conectividad, comunicaciones, fallas o averías
					en el mismo y/o en los equipos servidores u otras contingencias.
					Tampoco será responsable el Administrador del Sistema en caso de
					interrupción del servicio o deficiencias en su prestación cuando ello
					sea consecuencia de: (i) Actos ilícitos, tales como ataques de
					denegación de servicio distribuidos, intrusión no autorizada al
					Sistema y/o cualquier otra circunstancia susceptible de ser calificada
					como un delito informático; (ii) Caso fortuito o fuerza mayor; (iii)
					Actos criminales de terceros, como sabotajes, daño a las instalaciones
					y/o interrupción de las telecomunicaciones, entre otros. YOPLABS no
					será responsable por el incumplimiento de sus obligaciones si esto
					deviniere imposible, restringido o interferido por cualquier período
					de tiempo por caso fortuito, fuerza mayor o hechos de terceros. En
					ningún caso YOPLABS será responsable por lucro cesante, pérdida de
					chance, daño indirecto, daño incidental, indemnización de
					consecuencias no patrimoniales, daño punitivo, ni por cualquier otro
					daño y/o perjuicio resarcible que hayan podido sufrir los Usuarios o
					cualquier otra persona como consecuencia o en relación con las
					operaciones realizadas o no realizadas a través de la Aplicación.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					MODIFICACIONES A LA APLICACIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					YOPLABS podrá agregar funciones o funcionalidades, mejorar, cambiar o
					modificar la Aplicación en cualquier momento a su exclusivo criterio,
					reservándose la facultad de notificar a los Usuarios cuando lo
					considere necesario.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					POLÍTICAS DE SEGURIDAD Y PROTECCIÓN DE DATOS PERSONALES
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					YOPLABS con domicilio en Echeverría 1515, piso 13, Ciudad Autónoma de
					Buenos Aires, respeta el derecho de los Usuarios de esta Aplicación a
					la protección de sus Datos Personales, los cuales están amparados por
					la Ley de Protección de Datos Personales N° 25.326. Los Datos
					Personales se procesarán y almacenarán en servidores o medios
					magnéticos que mantienen altos estándares de seguridad y protección
					tanto física como tecnológica. Dicha base de datos es administrada por
					YOPLABS. En cumplimiento del artículo 6° de la Ley N° 25.326, sus
					datos sólo serán utilizados a los efectos de perfeccionar este
					contrato, concretar las transacciones en la Aplicación, validar los
					pedidos, recibir pagos, recibir información sobre promociones y otros
					productos al momento de la registración, mejorar la labor de
					información y comercialización de los productos ofrecidos y generar
					información agregada no individualizable a favor de terceros. Se
					acepta expresamente la cesión de los Datos Personales a los
					distribuidores a los efectos del envío de los productos y su
					facturación, y a aquellos terceros indicados en la Política de
					Privacidad. El Usuario acepta expresamente la cesión de los Datos
					Personales y de su historial de compras a los fabricantes de los
					productos a los efectos de su procesamiento. El Usuario confirma y
					acepta que presta su consentimiento previo, expreso e informado para
					que YOPLABS pueda tratar la totalidad de los datos personales que
					proporcione, de acuerdo a lo dispuesto en la Política de Privacidad
					que complementa los presentes Términos y Condiciones. La inexactitud o
					falsedad de los datos suministrados por el Usuario, o la ausencia de
					alguno de ellos, invalidarán su derecho a reclamar por cualquier error
					o perjuicio. El titular de los datos personales tiene la facultad de
					ejercer el derecho de acceso a los mismos en forma gratuita a
					intervalos no inferiores a seis meses, salvo que se acredite un
					interés legítimo al efecto conforme lo establecido en el artículo 14,
					inciso 3 de la Ley Nº 25.326. La AGENCIA DE ACCESO A LA INFORMACIÓN
					PÚBLICA, Órgano de Control de la Ley Nº 25.326, tiene la atribución de
					atender las denuncias y reclamos que se interpongan con relación al
					incumplimiento de las normas sobre protección de datos personales. El
					Usuario que desee suprimir o conocer los datos personales que constan
					en la misma, actualizarlos o rectificar errores, o bien solicitar el
					cese de envío de los correos electrónicos, deberá enviar un e- mail a
					la siguiente dirección de correo electrónico
					<a href='mailto:info@deutiles.com'> info@deutiles.com </a> o bien
					haciendo click en el botón de “Unsuscribe” al momento de recibir un
					correo. La solicitud se tramitará dentro del plazo de 10 días
					corridos. Para mayor información consulte la Política de Privacidad de
					la Aplicación vigente en WWW.DEUTILES.COM La que se incorpora al
					presente documento por referencia. YOPLABS podrá modificar su Política
					de Privacidad periódicamente y tales modificaciones estarán vigentes
					según se estipule en la Política de Privacidad.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					PROPIEDAD INTELECTUAL
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El Usuario reconoce y acepta que la Aplicación, así como todos sus
					contenidos (tales como textos, material gráfico, íconos de botones,
					códigos fuente, y compilaciones de datos), es un producto de propiedad
					de DEUTILES , protegido por patentes, leyes de derechos de autor y
					tratados internacionales. Asimismo, reconoce y acepta que todos los
					derechos, títulos e intereses asociados a la Aplicación, incluyendo
					derechos de propiedad intelectual asociados y todas las mejoras,
					modificaciones, revisiones, trabajos derivados, ingeniería no
					recurrente, el producto de tareas de personalización e integración,
					las opiniones y sugerencias de clientes y aplicaciones de marcas
					blancas, son y seguirán siendo la propiedad única y exclusiva de
					DEUTILES, según corresponda. Estos Términos y Condiciones y los
					derechos otorgados en el presente no le transfieren ningún derecho
					sobre la Aplicación. Los materiales gráficos, logotipos, encabezados
					de páginas, frases publicitarias, textos escritos incluidos en la
					Aplicación son marcas comerciales, creaciones o imágenes comerciales
					propiedad de DEUTILES. Dichas marcas, creaciones e imágenes
					comerciales no se pueden usar en relación a ningún producto que pueda
					causar confusión entre los clientes y en ninguna forma que
					desprestigie o desacredite a DEUTILES, o alguna de sus compañías
					afiliadas y/o relacionadas. Tampoco se permitirá copiar o adaptar el
					código fuente o HTML de la Aplicación, ya que los mismos está
					protegidos por los derechos de autor. Ningún producto o imagen pueden
					ser reproducidos, duplicados, copiados, vendidos, revendidos,
					visitados o explotados para ningún fin, en todo o en parte, sin el
					consentimiento escrito previo de YOPLABS, en su carácter de
					licenciatario, sin perjuicio de las excepciones expresamente señaladas
					en la ley.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					RESTRICCIONES DE USO
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					La Aplicación puede ser utilizada únicamente para propósitos legales.
					Las siguientes restricciones se aplicarán al uso de la Aplicación. El
					incumplimiento de dichas restricciones constituirá un incumplimiento
					de estos Términos y Condiciones. Queda expresamente prohibido para el
					Usuario:
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(i) Modificar, alterar y/o borrar, sin las autorizaciones
						correspondientes, la información o las configuraciones de la
						Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(ii) Interferir, sin autorización, el acceso de otros/as usuarios/as
						a la Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(iii) Transgredir o eludir las verificaciones de identidad
						establecidos en la Aplicación;{' '}
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(iv) Realizar actos maliciosos o que atenten contra la Aplicación,
						que impacten directamente en el funcionamiento adecuado de la misma,
						o que de alguna forma puedan llegar a dañar, inutilizar,
						sobrecargar, deteriorar, limitar o inutilizar el normal
						funcionamiento de la Aplicación o la utilización de todas o algunas
						de las funcionalidades de la misma, entre ellos: cargar archivos que
						contengan malware, archivos dañados o cualquier otro software o
						programas similares o introducir a la Aplicación cualquier tipo de
						virus, gusano, o programa de computación cuya intención sea hostil,
						destructiva y que impidan, inutilicen, puedan dañar o efectivamente
						causen daños a la Aplicación y/o a sus usuarios/as y/o inferir
						cualquier otro daño a los equipos o a la información, las
						configuraciones del sistema operativo o los aplicativos que se
						encuentren instalados en ella;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(v) Efectuar acciones tendientes a perturbar o alterar la propiedad
						intelectual o los derechos de autor del YOPLABS y/o DEUTILES{' '}
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(vi) Realizar acciones que impongan una carga irrazonable o
						desproporcionadamente grande sobre la infraestructura de la
						Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(vii) Intentar acceder, mediante la Aplicación, a datos restringidos
						sin la debida autorización y/o transgredir las barreras de seguridad
						de la Aplicación para llegar a ellos;{' '}
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(viii) Realizar búsquedas de vulnerabilidades o explotación de las
						mismas, a través de, la Aplicación para cualquier fin, sin la debida
						autorización efectuada por YOPLABS;{' '}
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(ix) Divulgar información acerca de la detección de vulnerabilidades
						encontradas en la Aplicación a terceros no autorizados;
					</Typography>{' '}
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(x) Difundir, publicar, distribuir o difundir dentro de la
						Aplicación material o información difamatorio, transgresor, obsceno,
						indecente o ilegal;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xi) Cargar a la Aplicación archivos que contengan software u otro
						material protegido por derechos de propiedad intelectual;{' '}
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xii) Eliminar las atribuciones de autor, los avisos legales o las
						designaciones o etiquetas de propiedad de la Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xiii) Difundir, a través de la Aplicación, ideas políticas, lograr
						la adhesión a campañas o movilizaciones, captar el voto, enviar o
						reenviar comunicaciones masivas o realizar cualquier otro tipo de
						práctica de políticas partidarias;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xiv) Enviar cualquier transmisión de datos en forma fraudulenta a
						través de la Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xv) Distribuir, permutar o intercambiar con fines comerciales la
						información contenida dentro de la Aplicación.
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xvi) Modificar, copiar, transmitir, vender, ceder, distribuir,
						exhibir, publicar, licenciar, crear trabajos derivados, o usar, en
						general, el contenido disponible en o a través de la Aplicación para
						fines comerciales y otros sin la debida autorización;{' '}
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xvii) Copiar, modificar, adaptar, traducir, realizar ingeniería
						inversa, descompilar o desensamblar cualquier parte del contenido
						y/o de la Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xviii) Reproducir y/o comunicar por cualquier medio el contenido
						con fines prohibidos;
					</Typography>{' '}
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xix) Interferir o interrumpir el funcionamiento de la Aplicación;
					</Typography>{' '}
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						(xx) Vender, ceder, licenciar o explotar el contenido y/o cualquier
						tipo de acceso y/o uso de la Aplicación;
					</Typography>
					<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
						{' '}
						(xxi) Utilizar la Aplicación con fines ilícitos o inmorales
					</Typography>
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					RESPONSABILIDADES. INDEMNIDAD
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					YOPLABS se limita a poner a disposición un medio para que se unan los
					Usuarios y los distribuidores, siendo todas las ventas y entregas
					concertadas por los distribuidores. En tal sentido, la responsabilidad
					por las transacciones y envíos corresponde a los distribuidores y sus
					colaboradores o los procesadores de pagos, según corresponda. Todo
					reclamo con relación a (i) la calidad de los productos entregados por
					el distribuidor; (ii) el cumplimiento por parte de cada distribuidor
					en la calidad, cantidad y modalidad, tiempo de entrega y costos de los
					pedidos efectuados por los Usuarios; (iii) las diferencias de precio
					que pudieran existir entre lo publicado en la Aplicación y lo
					efectivamente facturado por cada distribuidor a los Usuarios; (iv) el
					procesamiento y servicios relativos a los medios de pago disponibles
					en la Aplicación; (v) la información publicada por los distribuidores
					y los Usuarios en la Aplicación; debe ser dirigido directamente al
					distribuidor o el procesador de pago (en caso de corresponder). En
					ningún caso DEUTILES, YOPLABS, sus afiliadas, directores, gerentes,
					empleados y subcontratistas asumirán responsabilidad alguna frente a
					los distribuidores, los Usuarios, los colaboradores y/o repartidores
					de los distribuidores o cualquier tercero, incluyendo sin limitación,
					por: (a) cualquier daño o perjuicio derivado del mal uso de la
					Aplicación; (b) cualquier daño, perjuicio o pérdida al Usuario
					causados por fallas en el sistema, en el servidor o en internet; (c)
					actos o hechos ilícitos imputables a, o sufridos por, el distribuidor
					y/o sus colaboradores y/o el repartidor o a un tercero que utilice la
					cuenta del distribuidor; y (d) supuestos de fuerza mayor o caso
					fortuito. El Usuario se obliga a mantener indemne a DEUTILES, YOPLABS,
					sus afiliadas, directores, gerentes, empleados y subcontratistas de
					los daños resultantes (incluyendo los costos de defensa) de cualquier
					incumplimiento a los presentes términos y condiciones o de cualquier
					acto o hecho ilícito imputable al Usuario o a un tercero que utilice
					la cuenta del Usuario.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					TERMINACIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Estos Términos y Condiciones se considerarán aceptados en cuanto usted
					haga click sobre el botón “ACEPTAR”, y/o instale o utilice la
					Aplicación, y permanecerán vigentes hasta que su cuenta sea cancelada
					o dada de baja. Los Términos y Condiciones y el uso de la Aplicación
					finalizarán en forma automática en el momento en que YOPLABS así lo
					determine, sin derecho a reclamo o indemnización alguna a favor del
					Usuario. YOPLABS se reserva el derecho de desconectar a un Usuario,
					sin expresión de causa alguna, sin derecho a reclamo o indemnización
					alguna a favor del Usuario. Sin perjuicio de los otros derechos
					indicados en estos Términos y Condiciones, en caso de incumplimiento
					por parte del Usuario, YOPLABS podrá tomar las acciones que considere
					apropiadas para lidiar con el incumplimiento, incluyendo sin
					limitación, la baja de la cuenta del Usuario, la suspensión del acceso
					a la Aplicación, la prohibición de ingreso a la Aplicación, bloqueando
					el dispositivo utilizado por sus direcciones IP para acceder a la
					Aplicación, contactándonos con su proveedor del servicio de internet
					para solicitar que bloqueen su acceso a la Aplicación y/o tomar
					acciones legales contra usted.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					SITIOS WEB Y APLICACIONES DE TERCEROS
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El establecimiento de cualquier enlace que este dirigido a la
					Aplicación, solo podrá realizarse con la expresa autorización de
					YOPLABS. YOPLABS se reserva el derecho a solicitar que se remueva o
					elimine cualquier enlace a la Aplicación que sea ajeno y no
					autorizado. La Aplicación puede contener enlaces y/o referencias a
					otras aplicaciones o sitios web de terceros, lo cual no indica que
					ellos sean propiedad de DEUTILES u operados por YOPLABS. La
					instalación de estos enlaces o referencias en la Aplicación se limita
					a facilitar al Usuario la búsqueda y acceso a la información allí
					disponible. En virtud de que YOPLABS no tiene control sobre tales
					aplicaciones y sitios, YOPLABS NO será responsable por los contenidos,
					materiales, acciones y/o servicios prestados por los mismos, ni por
					daños o pérdidas ocasionadas a los Usuarios o distribuidores por la
					utilización de los mismos. La presencia de enlaces a otras plataformas
					o sitios web no implica una sociedad, relación, aprobación y/o
					respaldo de YOPLABS a dichas plataformas o sitios y sus contenidos. Si
					el Usuario decide dejar la Aplicación y acceder a los sitios
					enlazados, entiende que lo hace bajo su propio riesgo. La conexión que
					el Usuario realice con los mismos, queda totalmente bajo su
					responsabilidad. YOPLABS no ha controlado de ninguna manera los
					enlaces y no se hace responsable por el contenido, exactitud o
					autenticidad expresados en estos sitios web. La inclusión de estos
					enlaces en la Aplicación no implica aprobación o respaldo por YOPLABS
					de los mismos, sus entidades o productos y servicios. El Usuario
					acepta que, en caso de incumplimiento o prestación defectuosa por
					parte del tercero involucrado, el Usuario solamente reclamará a dicho
					tercero, dejando indemne a YOPLABS, quien no será parte de dicha
					relación
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Los Usuarios aceptan y reconocen que YOPLABS podrá modificar estos
					Términos y Condiciones, en cualquier momento, y sin necesidad de aviso
					previo, por lo que la versión actualizada de los mismos se reputará
					conocida y aceptada por los Usuarios en cada oportunidad que utilicen
					la Aplicación. La versión actualizada de estos Términos y Condiciones
					se encontrará disponible en la Aplicación, y las modificaciones
					entrarán en vigencia a los 10 (diez) días de su publicación. El
					Usuario podrá comunicar su NO aceptación de los Términos y
					Condiciones, enviando un correo a la dirección
					<a href='mailto:info@deutiles.com'> info@deutiles.com </a>
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					LEY APLICABLE Y JURISDICCION
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Los presentes Términos y Condiciones se rigen por las leyes de la
					República Argentina. Cualquier controversia derivada de la utilización
					de la Aplicación y de los presentes Términos y Condiciones Generales,
					su existencia, validez, interpretación, alcance o cumplimiento, será
					sometida ante la Justicia Nacional Ordinaria en lo Comercial con sede
					en la Ciudad Autónoma de Buenos Aires.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					NOTIFICACIONES
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={10}
				>
					Cualquier duda que los Usuarios puedan tener en relación con los
					Términos y Condiciones podrán comunicarse al correo electrónico
					<a href='mailto:info@deutiles.com'> info@deutiles.com </a>. Se fija
					como domicilio de YOPLABS, Echeverría 1515, piso 13, Ciudad Autónoma
					de Buenos Aires.
				</Typography>
			</Grid>
		</Grid>
	</Fragment>
);

export default ViewTermsAndConditions;
