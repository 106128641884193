import { Fragment, FunctionComponent } from 'react';
import { Button } from 'UI';
import { Input } from 'UI/Input';
import { Typography, Box, Grid, CircularProgress, Alert } from '@mui/material';
import { ControlProps, FormProps } from 'models/form';
import { RegisterLibraryRequest } from 'data/types/libraryTypes';
import { GoogleMaps } from 'UI/AutocompleteMaps';
import style from './RegisterLibrary.module.css';
import { validationCUIT } from 'utils/validationRules';

type Props = {
	classes: any;
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	loading: boolean;
	registerLibrary: (
		library: RegisterLibraryRequest & {
			code: string;
			areaCode: string;
			phone: string;
		}
	) => void;
	onSaveLocation: (location: string) => void;
};

const RightSectionRegisterLibraryView: FunctionComponent<Props> = (props) => (
	<Fragment>
		{props.loading ? (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</div>
		) : (
			<Fragment>
				<Grid container pl={10}>
					<Grid item xs={12} px={2} pt={16}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.libraryName,
								name: 'libraryName',
                rules:{required:"Nombre de librería requerido"}
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='Nombre de la librería'
						/>
					</Grid>
					<Grid item xs={6} pl={2} pr={1} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.businessName,
								name: 'businessName',
                rules:{required:"Razón social requerida"}
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='Razón social'
						/>
					</Grid>
					<Grid item xs={6} pr={2} pl={1} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.cuit,
								rules: validationCUIT,
								name: 'cuit',
							}}
							type='text'
							errors={props.formProps.errors}
							placeholder='CUIT'
							inputProps={{ min: 0, maxLength:13 }}
						/>
					</Grid>
					<Grid item xs={12} mt={3} mx={2}>
              <GoogleMaps
                controllerProps={{
                  control: props.controlProps.control,
                  defaultValue: "",
                  rules: { required: "Ubicación requerida" },
                  name: 'address'
                }}
                errors={props.formProps.errors}
                setValueForm={props.formProps.setValue!}
				onSelectLocation={props.onSaveLocation}
			  />
					</Grid>
					<Grid item xs={3} pl={2} pr={0.5} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.code,
								name: 'code',
                rules:{required:"Requerido"}
							}}
							type='tel'
							errors={props.formProps.errors}
							placeholder='+54'
							borderRadius={{
								borderTopLeftRadius: '21px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderBottomLeftRadius: '21px',
							}}
						/>
					</Grid>
					<Grid item xs={3} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.areaCode,
								name: 'areaCode',
                rules:{required:"Requerido"}
							}}
							type='tel'
							errors={props.formProps.errors}
							placeholder='Código área'
							borderRadius={{
								borderTopLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderBottomLeftRadius: '0px',
							}}
						/>
					</Grid>
					<Grid item xs={6} pr={2} pl={1} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.phone,
								name: 'phone',
                rules:{required:"Teléfono requerido"}
							}}
							type='tel'
							errors={props.formProps.errors}
							placeholder='Teléfono celular'
							borderRadius={{
								borderTopLeftRadius: '0px',
								borderBottomRightRadius: '21px',
								borderTopRightRadius: '21px',
								borderBottomLeftRadius: '0px',
							}}
						/>
					</Grid>
					<Grid item xs={12} pl={2} pr={1}>
						{props?.error?.error && (
							<Alert severity='error' style={{ marginTop: '1.5em' }}>
								{props?.error?.error}
							</Alert>
						)}
					</Grid>
				</Grid>

				<Box pl={4} pt={3}>
					<Grid container justifyContent='flex-end' pr={3} pt={4}>
						<Button
							variant='contained'
							size='small'
							style={{
								textTransform: 'none',
							}}
							sx={{ borderRadius: '20px' }}
							disableElevation
							onClick={props.formProps.handleSubmit(props.registerLibrary)}
						>
							<Typography color='white' variant='body2' p={0.8}>
								Crear cuenta
							</Typography>
						</Button>
					</Grid>
				</Box>
			</Fragment>
		)}
	</Fragment>
);

export default RightSectionRegisterLibraryView;
