import { FunctionComponent, Fragment } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import cotizaciones from 'fonts/cotizaciones.svg';
import promociones from 'fonts/promociones.svg';
import tiempo from 'fonts/tiempo.svg';
import { Link } from 'react-router-dom';

type Props = { classes: any; login: string | null };

const HomeLayoutView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Grid item xs={2.5} mt='4em'>
			<Typography
				fontFamily='Krub'
				fontSize='14px'
				color='#FFFFFF'
				fontWeight='bold'
				mb='2em'
			>
				TODO PARA ABASTECER A TU COMERCIO
			</Typography>
			<Typography variant='h1' fontSize={48} color='white' fontWeight='bold'>
				Por qué DeÚtiles te conviene
			</Typography>
		</Grid>
		<Grid item xs={4} mb='6em'>
			<Grid container pt={6} alignItems='center'>
				<Grid item xs={4} display='flex' justifyContent='end' mr={4}>
					<span className={props.classes.iconSpan}>
						<img
							src={cotizaciones}
							alt='icon'
							className={props.classes.icon}
						></img>
					</span>
				</Grid>
				<Grid item xs={6}>
					<Typography
						fontFamily='Krub'
						fontSize='24px'
						color='white'
						fontWeight='bold'
					>
						Obtenés múltiples cotizaciones para un mismo producto o pedido.
					</Typography>
				</Grid>
			</Grid>
			<Grid container pt={6} alignItems='center'>
				<Grid item xs={4} display='flex' justifyContent='end' mr={4}>
					<span className={props.classes.iconSpan}>
						<img
							src={tiempo}
							alt='icono-tiempo'
							className={props.classes.icon}
						></img>
					</span>
				</Grid>
				<Grid item xs={6}>
					<Typography
						fontFamily='Krub'
						fontSize='24px'
						color='white'
						fontWeight='bold'
					>
						Ahorrás tiempo para generar tus pedidos.
					</Typography>
				</Grid>
			</Grid>
			<Grid container pt={6} alignItems='center'>
				<Grid item xs={4} display='flex' justifyContent='end' mr={4}>
					<span className={props.classes.iconSpan}>
						<img
							src={promociones}
							alt='icon'
							className={props.classes.icon}
						></img>
					</span>
				</Grid>
				<Grid item xs={6}>
					<Typography
						fontFamily='Krub'
						fontSize='24px'
						color='white'
						fontWeight='bold'
					>
						Aprovechas las promociones de los fabricantes.
					</Typography>
				</Grid>
				{props.login === null ? (
					<Grid item xs={10} display='flex' justifyContent='end' mt={12}>
						<Link
							to={'/register'}
							color='inherit'
							style={{ color: 'inherit', textDecoration: 'inherit' }}
						>
							<Button
								variant='contained'
								sx={{
									borderRadius: '20px',
									backgroundColor: '#FFFFFF',
									width: '264px',
									height: '42px',
								}}
							>
								<Typography
									fontFamily='Krub'
									fontSize={14}
									color='#3D363C'
									fontWeight='bold'
								>
									Crear una cuenta ahora
								</Typography>
							</Button>
						</Link>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Grid>
	</Fragment>
);

export default HomeLayoutView;
