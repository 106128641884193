import { useAppDispatch } from 'data/global/hooks';
import { ValidationUserAsync } from 'data/global/features/UserSlice/userSlice';
import { ValidationUserRequest } from 'data/types/userTypes';

export const useValidationUser = () => {
	const dispatch = useAppDispatch();

	const validationUser = (code: ValidationUserRequest): void => {
		dispatch(ValidationUserAsync(code));
	};

	return validationUser;
};

export default useValidationUser;
