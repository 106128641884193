import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from 'data/global/store';
import { Provider } from 'react-redux';
import 'lang';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme';
import CssBaseline from '@mui/material/CssBaseline';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Provider store={store}>
				<App />
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
