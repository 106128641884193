import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import {
	GetMyCartRequest,
	GetMyCartResponse,
	// SendOrderRequest,
	// SendOrderResponse,
	AddCartRequest,
	AddCartResponse,
	QttyMasterProductInCartRequest,
	QttyMasterProductInCartResponse,
} from 'data/types/cartTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import CartApi from 'data/api/cartApi';

type TCartSlice = {
	qttyCart?: QttyMasterProductInCartResponse;
	getMyCartStatusRequest: StatusRequest;
	getMyCartStatusError?: Failure;
	getMyCartResponse?: GetMyCartResponse;
	sendOrderStatusRequest: StatusRequest;
	sendOrderStatusError?: Failure;
	AddCartStatusRequest: StatusRequest;
	AddCartStatusError?: Failure;
	qttyCartStatusRequest: StatusRequest;
	qttyCartStatusError?: Failure;
};

const initialState: TCartSlice = {
	qttyCart: undefined,
	getMyCartStatusRequest: StatusRequest.initial,
	getMyCartStatusError: undefined,
	sendOrderStatusRequest: StatusRequest.initial,
	sendOrderStatusError: undefined,
	AddCartStatusRequest: StatusRequest.initial,
	AddCartStatusError: undefined,
	qttyCartStatusRequest: StatusRequest.initial,
	qttyCartStatusError: undefined,
};

export const GetMyCartAsync = createAsyncThunk<
	GetMyCartResponse,
	GetMyCartRequest,
	{ rejectValue: Failure }
>('cart/GetMyCartAsync', async (request: GetMyCartRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetMyCartResponse> =
			await CartApi.getCart();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

// export const SendOrderAsync = createAsyncThunk<
// 	SendOrderResponse,
// 	SendOrderRequest,
// 	{ rejectValue: Failure }
// >('cart/SendOrderAsync', async (request: SendOrderRequest, thunkAPI) => {
// 	try {
// 		const eitherResponse: Either<Failure, SendOrderResponse> =
// 			await CartApi.sendOrder(request);
// 		if (isRight(eitherResponse)) {
// 			const response = unwrapEither(eitherResponse);
// 			return response;
// 		}
// 		const error = unwrapEither(eitherResponse);
// 		return thunkAPI.rejectWithValue(error);
// 	} catch (e) {
// 		const error: Failure = {
// 			error: true,
// 			message: (e as Error).toString(),
// 		};
// 		return thunkAPI.rejectWithValue(error);
// 	}
// });

export const AddCartAsync = createAsyncThunk<
	AddCartResponse,
	AddCartRequest,
	{ rejectValue: Failure }
>('cart/AddCartAsync', async (request: AddCartRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddCartResponse> =
			await CartApi.addCart(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const QttyCartAsync = createAsyncThunk<
	QttyMasterProductInCartResponse,
	QttyMasterProductInCartRequest,
	{ rejectValue: Failure }
>(
	'cart/QttyCartAsync',
	async (request: QttyMasterProductInCartRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, QttyMasterProductInCartResponse> =
				await CartApi.getQttyCart(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				return response;
			}
			const error = unwrapEither(eitherResponse);
			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const CartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(GetMyCartAsync.pending, (state) => {
			state.getMyCartStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetMyCartAsync.fulfilled, (state, action) => {
			state.getMyCartStatusRequest = StatusRequest.fulfilled;
			state.getMyCartResponse = action.payload;
		});
		builder.addCase(GetMyCartAsync.rejected, (state, action) => {
			state.getMyCartStatusRequest = StatusRequest.rejected;
			state.getMyCartStatusError = action.payload;
		});
		// builder.addCase(SendOrderAsync.pending, (state) => {
		// 	state.sendOrderStatusRequest = StatusRequest.pending;
		// });
		// builder.addCase(SendOrderAsync.fulfilled, (state, action) => {
		// 	state.sendOrderStatusRequest = StatusRequest.fulfilled;
		// });
		// builder.addCase(SendOrderAsync.rejected, (state, action) => {
		// 	state.sendOrderStatusRequest = StatusRequest.rejected;
		// 	state.sendOrderStatusError = action.payload;
		// });
		builder.addCase(AddCartAsync.pending, (state) => {
			state.AddCartStatusRequest = StatusRequest.pending;
		});
		builder.addCase(AddCartAsync.fulfilled, (state, action) => {
			state.AddCartStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddCartAsync.rejected, (state, action) => {
			state.AddCartStatusRequest = StatusRequest.rejected;
			state.AddCartStatusError = action.payload;
		});
		builder.addCase(QttyCartAsync.pending, (state) => {
			state.qttyCartStatusRequest = StatusRequest.pending;
		});
		builder.addCase(QttyCartAsync.fulfilled, (state, action) => {
			state.qttyCartStatusRequest = StatusRequest.fulfilled;
			state.qttyCart = action.payload;
		});
		builder.addCase(QttyCartAsync.rejected, (state, action) => {
			state.qttyCartStatusRequest = StatusRequest.rejected;
			state.qttyCartStatusError = action.payload;
		});
	},
});

export const selectCart = (state: RootState) => state.cart;
export const {} = CartSlice.actions;
export default CartSlice.reducer;
