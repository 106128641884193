import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	iconSpan: {
		background: '#5C41BD 0% 0% no-repeat padding-box',
		width: '62px',
		height: '62px',
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		background: '#5C41BD 0% 0% no-repeat padding-box',
		color: 'white',
		width: '40px',
	},
}));
export default useStyles;
