import {
	Grid,
	Link,
	Popover,
	Typography,
	Tooltip,
	tooltipClasses,
	Box,
} from '@mui/material';
// import { Toast } from 'UI';
import { NavBarHome, CardCart, QuantitySelector } from 'components';
import { Fragment, FunctionComponent } from 'react';

type Props = {
	cartRef: any;
	classes: any;
	cartItems: any;
	distributors: any;
	loading: boolean;
	onClickPickup: (e: any, index: number) => void;
	onClickDelivery: (e: any, index: number) => void;
	updateItem: (qtty: number, cpid: any) => void;
	generateOrden: (e: any) => void;
	deliveryState: Array<Boolean>;
	open?: any;
	anchorEl?: any;
	handleClose?: (e: any) => void;
	id?: any;
	unCompletedTotalDistribuitors?: any;
	distributorsDeliveryPrices: number[];
	distributorsDeliveryAllowed: boolean[];
	distributorFullPrice: number[];
	distribuitorsSeleted: string[];
	toast?: any;
	scrollDirection: string;
	handleRight: (e: any) => void;
};

export const ViewCart: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome />
		{props.cartItems?.data != null && !props.loading ? (
			<Grid
				container
				sx={{
					backgroundColor: '#F7F5F7',
					maxWidth: '1920px',
					overflow: 'scroll',
					overflowX:'hidden',
					height:"calc(100% - 100px)"
				}}
				ref={props.cartRef}
				display='flex'
				justifyContent='start'
			>
				<Grid item display='flex' justifyContent='start' flexDirection='column'>
					<header className={props.classes.header}>
						<aside className={props.classes.aside}>
							Mi lista{' '}
							<Box
								sx={{ display: { xs: 'grid', xxl: 'none' } }}
								className={props.classes.scrollButton}
								onClick={props.handleRight}
							>
								<i
									className={`icon-arrow-${props.scrollDirection}`}
									aria-hidden='true'
								></i>
							</Box>
							<span className={props.classes.headerSpan}>
								{props.cartItems.data.items} ítems
							</span>
						</aside>
						<nav className={props.classes.nav}>
							{props.distributors.map((distributor: any) => (
								<div
									className={props.classes.navCtrl}
									key={distributor.distributorID}
								>
									{distributor.distributorName}
									{props.unCompletedTotalDistribuitors?.some(
										(unCompleted: any) =>
											unCompleted.id === distributor.distributorID
									) && (
										<button className={props.classes.rFButtonSecondary}>
											<Link href='/catalog' color='inherit' underline='none'>
												Seguir comprando
											</Link>
										</button>
									)}
								</div>
							))}
						</nav>
					</header>
					<section className={props.classes.rSection}>
						{props.cartItems.data.itemList.map((item: any) => (
							<div
								className={props.classes.row}
								key={item.masterProduct.masterProductId}
							>
								<aside className={props.classes.rAside}>
									<div>
										<img
											src={item.masterProduct.photo || ''}
											alt={item.masterProduct.name || ''}
											className={props.classes.image}
										/>
									</div>
									<div className={props.classes.description}>
										{item.masterProduct.name}
									</div>
									<div className={props.classes.actions}>
										<i
											onClick={() =>
												props.updateItem(
													item.qtty - 1,
													item.catalogProductList.find(
														(cp: any) => cp.selected
													) || {}
												)
											}
											className='icon-remove'
											style={{
												color: '#5c41bd',
												fontSize: '25px',
												cursor: 'pointer',
											}}
										></i>

										<input
											onKeyPress={(evento) => {
												const patron = /^[0-9]+$/;

												// Prevenir entrada de letras
												if (!patron.test(evento.key)) {
													evento.preventDefault();
												}
											}}
											className={props.classes.number}
											type='text'
											defaultValue={item.qtty}
											onBlur={(e) =>
												props.updateItem(
													parseInt(e.target.value),
													item.catalogProductList.find(
														(cp: any) => cp.selected
													) || {}
												)
											}
										/>

										<i
											onClick={() =>
												props.updateItem(
													item.qtty + 1,
													item.catalogProductList.find(
														(cp: any) => cp.selected
													) || {}
												)
											}
											className='icon-add'
											style={{
												color: '#5c41bd',
												fontSize: '25px',
												cursor: 'pointer',
											}}
										></i>

										<i
											onClick={() =>
												props.updateItem(
													0,
													item.catalogProductList.find(
														(cp: any) => cp.selected
													) || {}
												)
											}
											className='icon-trash'
											style={{
												color: '#5c41bd',
												fontSize: '25px',
												cursor: 'pointer',
												paddingLeft: '8px',
											}}
										></i>
									</div>
								</aside>
								<div className={props.classes.rContent}>
									{item.catalogProductList.map(
										(itemProductList: any, idx: number) => (
											<div
												className={props.classes.rCtrl}
												key={itemProductList.catalogProductId || idx}
												onClick={() =>
													props.updateItem(item.qtty, itemProductList)
												}
											>
												<CardCart
													selected={itemProductList?.selected || false}
													price={itemProductList.price}
													void={itemProductList.empty}
												></CardCart>
											</div>
										)
									)}
								</div>
							</div>
						))}
					</section>
					<footer className={props.classes.footer}>
						<aside className={props.classes.fAside}>
							<div className={props.classes.dTotal}>Total por mayorista</div>
							<div className={props.classes.dDelivery}>Delivery</div>
						</aside>
						{props.distributors.map((distributor: any, index: number) => (
							<nav
								className={props.classes.fNav}
								key={distributor.distributorID || index}
							>
								{distributor.distributorID ? (
									<div className={props.classes.fNavCtrl}>
										<div className={props.classes.fHeader}>
											<div
												className={
													!props.deliveryState[index]
														? props.classes.fSwitch
														: `${props.classes.fSwitch} ${props.classes.fDelivery}`
												}
											>
												<div
													className={props.classes.fSBtn}
													onClick={(e) => props.onClickPickup(e, index)}
													id={distributor.distributorID}
												>
													Pickup
												</div>
												<div
													id={distributor.distributorID}
													className={props.classes.fSBtn}
													onClick={(e) => props.onClickDelivery(e, index)}
												>
													Delivery
												</div>
											</div>
										</div>
										<span className={props.classes.price}>
											{props.distributorFullPrice[index] !== undefined
												? `$ ${props.distributorFullPrice[index].toFixed(2)}`
												: 'No disponible'}
										</span>
										{props.deliveryState[index] ? (
											<div className={props.classes.saving}>
												$ {props.distributorsDeliveryPrices[index]}
											</div>
										) : (
											<></>
										)}
									</div>
								) : (
									<div className={props.classes.fNavCtrl}></div>
								)}
							</nav>
						))}
					</footer>
					<div className={props.classes.realFooter}>
						<button className={props.classes.rFButton}>
							<Link href='/catalog' color='inherit' underline='none'>
								Seguir comprando
							</Link>
						</button>
						{props.unCompletedTotalDistribuitors?.length == 0 ? (
							<>
								<Tooltip
									title={` Los distribuidores con los que va a cotizar son: ${props.distribuitorsSeleted}`}
								>
									<aside
										className={props.classes.rFAside}
										onClick={props.generateOrden}
									>
										<span className={props.classes.rFGO}>
											Generar cotización
										</span>
										<div className={props.classes.rFCTCTVLASDOQWEPPPP}>
											<span className={props.classes.rFTag}>Total</span>
											<span className={props.classes.rFTag}>$</span>
											<span className={props.classes.rFPrice}>
												{props.cartItems.data.fullCartPrice !== undefined
													? props.cartItems.data.fullCartPrice.toFixed(2)
													: 'No disponible'}
											</span>
										</div>
									</aside>
								</Tooltip>
							</>
						) : (
							<aside className={props.classes.rFAside}>
								<span className={props.classes.rFGO}>
									Se requieren cumplir los montos minimos de cada distribuidor
									para generar la cotizacion
								</span>
							</aside>
						)}
						<Popover
							id={props.id}
							open={props.open}
							anchorEl={props.anchorEl}
							onClose={props.handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
						>
							<Typography sx={{ p: 2 }}>Enviando cotización...</Typography>
						</Popover>
					</div>
				</Grid>
			</Grid>
		) : (
			<div className={props.classes.loading}>Cargando carrito...</div>
		)}
	</Fragment>
);
