import React from 'react';
import NavBarRegisterView from './view';
import { FunctionComponent } from 'react';

interface Props {
	navRegisterLibrary?: Boolean;
}

export const NavBarRegister: FunctionComponent<Props> = ({
	navRegisterLibrary,
}) => {
	return <NavBarRegisterView navRegisterLibrary={navRegisterLibrary} />;
};

export default NavBarRegister;
