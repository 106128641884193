import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';

const useStyles = makeStyles((theme: Theme) => ({
	createPassword: {
		font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 14px/18px var(--unnamed-font-family-krub)',
		letterSpacing: 'var(--unnamed-character-spacing-0)',
		color: 'var(---5c41bd-violeta)',
		textAlign: 'right',
		opacity: '1',
		fontWeight: 'normal',
	},
}));
export default useStyles;
