import { useAppDispatch } from 'data/global/hooks';
import { RegisterLibraryAsync } from 'data/global/features/LibrarySlice/librarySlice';
import { RegisterLibraryRequest } from 'data/types/libraryTypes';

export const useRegisterLibrary = () => {
	const dispatch = useAppDispatch();

	const registerLibrary = (library: RegisterLibraryRequest & {
		code: string,
		areaCode: string,
		phone: string,
	}): void => {
		console.log(library)
		dispatch(RegisterLibraryAsync({
			libraryName: library.libraryName,
			businessName: library.businessName,
			cuit: library.cuit,
			address: library.address, 
			cellPhone: (library.code + library.areaCode + library.phone)
		}));
	};

	return registerLibrary;
};

export default useRegisterLibrary;
