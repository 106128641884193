import React, { useRef } from 'react';
import { FunctionComponent } from 'react';
import SliderView from './view';

interface Props {
	title?: string;
	children?: any;
	settings: Object;
}

export const Slider: FunctionComponent<Props> = (props) => {
	const slider = useRef<any>(null);

	const next = () => {
		slider.current.slickNext();
	};
	const previous = () => {
		slider.current.slickPrev();
	};
	return (
		<SliderView
			settings={props.settings}
			slider={slider}
			previous={previous}
			next={next}
			title={props.title}
		>
			{props.children}
		</SliderView>
	);
};
