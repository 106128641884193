import React, { useEffect, useRef, useState } from 'react';
import FormLoginView from './view';
import useStyles from './useStyles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rulesEmail, rulesPassword } from 'utils/validationRules';
import { useSignIn } from './hooks';
import { useAppSelector } from 'data/global/hooks';
import { selectAuth } from 'data/global/features/AuthSlice/authSlice';
import { StatusRequest } from 'data/types';
import { useNavigate } from 'react-router-dom';
import { VerifyEmail } from '../VerifyEmail';
import VerifyEmailView from 'components/VerifyEmail/view';
import { RightFormRegister } from '../RightFormRegister';
import { Layout } from '../Layout';
import { LeftSection } from '../../pages/Register/view';

export const FormLogin: React.FC = () => {
	const classes = useStyles();
	const signIn = useSignIn();
	const { t } = useTranslation();
	const emailRef = useRef<HTMLInputElement>(null);
	const { signInStatusError, signInStatusRequest, isLogin } =
		useAppSelector(selectAuth);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onBlur' });

	const defaultValue = { email: '', password: '' };

	const rules = {
		rulesEmail: rulesEmail(t),
		rulesPassword: rulesPassword(t),
	};
	useEffect(() => {
		if (signInStatusRequest == StatusRequest.fulfilled) {
			navigate('/');
		} else if (signInStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [signInStatusRequest]);


	const recoverFunction = () => {
		if (emailRef.current !== null) {
			emailRef.current.focus();
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			const email: string = emailRef.current.value;
			const isValidEmail = emailRegex.test(email);
			if (!email || !isValidEmail) {
				emailRef.current.blur();
			} else {
				navigate('/recoverPassword', { state: { email: email } });
			}
		}
	};


	if (signInStatusRequest == StatusRequest.rejected && signInStatusError?.error) {
		if (signInStatusError?.message === 'Usuario no activado') {
			return (<VerifyEmail />);
		}
	}

	return (
		<FormLoginView
			recoverFunction={recoverFunction}
			signIn={signIn}
			classes={classes}
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			error={signInStatusError}
			refInputs={{ emailRef }}
			controlProps={{ control, defaultValue, rules }}
			loading={loading}
		/>
	);
};
