import { Fragment, FunctionComponent } from 'react';
import {
	AppBar,
	Grid,
	Avatar,
	Typography,
	Box,
	Link,
	Menu,
	MenuItem,
} from '@mui/material';
import { SearchBar, Button } from 'UI';
import { MenuCategories } from 'components';
import logo from 'fonts/logo.svg';

type Props = {
	handleClick?: (e: any) => void;
	handleClose?: (e: any) => void;
	onSearch: (query: string) => void;
	open?: any;
	anchorEl?: any;
	handleClickMenu?: (e: any) => void;
	handleCloseMenu?: (e: any) => void;
	openMenu?: any;
	anchorElMenu?: any;
	login?: string | null;
	query?: string;
};

const NavBarHomeView: FunctionComponent<Props> = (props) => (
	<AppBar
		position='static'
		color='transparent'
		elevation={0}
		sx={{ backgroundColor: '#F7F5F7', border: '1px solid #DEDCDD' }}
	>
		<Grid
			container
			display='flex'
			justifyContent='center'
			alignItems='center'
			mt={3}
			mb={3}
		>
			<Grid item xs={2} ml={4}>
				<a href='/'>
					<img
						src={logo}
						alt='logo'
						style={{
							width: '158px',
							height: '35px',
						}}
					></img>
				</a>
			</Grid>
			<Grid
				item
				xs={2}
				ml={4}
				display='flex'
				alignItems='center'
				justifyContent='flex-end'
			>
				<Button
					onClick={props.handleClick}
					style={{
						textTransform: 'none',
					}}
				>
					<Typography
						fontFamily={'Krub'}
						fontWeight='bold'
						fontSize={20}
						color='#3D363C'
						marginRight='10px'
					>
						Productos
					</Typography>
					<span
						style={{
							background: '#FF6651 0% 0% no-repeat padding-box',
							width: '32px',
							height: '32px',
							borderRadius: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<i
							className='icon-catalog'
							style={{
								color: 'white',
								fontSize: '18px',
							}}
						></i>
					</span>
				</Button>
				<MenuCategories
					handleClose={props.handleClose}
					open={props.open}
					anchorEl={props.anchorEl}
				/>
			</Grid>
			<Grid item xs={4} marginLeft='2em'>
				<SearchBar onSend={props.onSearch} value={props.query} />
			</Grid>
			<Grid item xs={2} display='flex' justifyContent='flex-end'>
				{props.login === null ? (
					<Link href='/register' color='inherit' underline='none'>
						<i
							className='icon-account'
							style={{ color: '#FF6651', fontSize: '22px' }}
						></i>
					</Link>
				) : (
					<Fragment>
						<Avatar
							sx={{ backgroundColor: '#5C41BD' }}
							onClick={props.handleClickMenu}
							id='basic-button'
							aria-controls={props.openMenu ? 'basic-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={props.openMenu ? 'true' : undefined}
						>
							<i
								className='icon-account'
								style={{ color: 'white', fontSize: '19px' }}
							></i>
						</Avatar>
						<Menu
							id='basic-menu'
							anchorEl={props.anchorElMenu}
							open={props.openMenu}
							onClose={props.handleCloseMenu}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem onClick={props.handleCloseMenu}>
								<Link href={`/orders`} underline='none' color='inherit'>
									Mis cotizaciones
								</Link>
							</MenuItem>
							<MenuItem
								onClick={(e) => {
								    localStorage.removeItem('jwt');
									localStorage.removeItem('distributorsSelected');
                                    localStorage.removeItem('email');
									window.location.href='/';
								}}
							>
								<Link underline='none' color='inherit'>
									Salir del sitio
								</Link>
							</MenuItem>
						</Menu>
					</Fragment>
				)}
			</Grid>
		</Grid>
	</AppBar>
);

export default NavBarHomeView;
