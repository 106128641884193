import React, { Fragment, useEffect, useState } from 'react';
import useStyles from './useStyles';
import { useForm } from 'react-hook-form';
import VerifyEmailView from '../VerifyEmail/view';

import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { StatusRequest } from '../../data/types';
import namesRoutes from '../../routes/namesRoutes';
import { useAppSelector } from '../../data/global/hooks';
import { selectUser } from '../../data/global/features/UserSlice/userSlice';
import useRecoverPassword from './hooks/useRecoverPassword';
import PasswordRecoveryView from './view';
import { rulesPassword } from '../../utils/validationRules';



interface LocationState {
	email: string;
}
export const PasswordRecovery: React.FC = () => {const classes = useStyles();
	const recoverPassword = useRecoverPassword();
	const location = useLocation();
	const state = location.state as LocationState; // Type Casting, then you can get the params passed via router
	const email = state? state.email : '';
	console.log('email',email);
	const [loading, setLoading] = useState(true);
	const { recoverPasswordStatusRequest, recoverPasswordStatusError } = useAppSelector(selectUser);
	const navigate = useNavigate();
	useEffect(() => {
		recoverPassword({
			email: email? email : '',
		});
	}, []);

	useEffect(() => {
		if (recoverPasswordStatusRequest === StatusRequest.rejected) {
			alert("Error enviando el email de recuperación de contraseña");
			navigate(namesRoutes.home);
		} else if (recoverPasswordStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else if (recoverPasswordStatusRequest === StatusRequest.fulfilled ) {
			setLoading(false);
		}
	}, [recoverPasswordStatusRequest]);
	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const rules = {};
	const defaultValue = {
		email:email,
	};

	return (<Fragment>{loading ? (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<CircularProgress />
		</div>
	) : (
		<Fragment>
			<PasswordRecoveryView
				classes={classes}
				recoverEmail={recoverPassword}
				formProps={{ handleSubmit, errors, isSubmitting, isValid }}
				email={email}
				controlProps={{ control, defaultValue, rules }}
			/>
		</Fragment>
	)}
	</Fragment>

	);
};
