import { Fragment, FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import { NavBarHome } from 'components';
type Props = {};

export const ViewPrivacyPolicies: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome />
		<Grid container>
			<Grid item xs={10} m={8} mt={12}>
				<Typography
					variant='h1'
					fontSize={30}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
				>
					DEÚTILES
				</Typography>
				<Typography
					variant='h1'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mt={6}
				>
					Política de Privacidad
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					INTRODUCCIÓN
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					YOPLABS respeta el derecho de los usuarios de la Aplicación, a la
					protección integral de sus Datos Personales, los cuales están
					amparados por la Ley de Protección de Datos Personales N° 25.326, sus
					normas complementarias y reglamentarias.
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					{' '}
					YOPLABS se compromete a mantener la confidencialidad y la seguridad de
					la información personal proporcionada por los usuarios de la
					Aplicación, a cuyo efecto aplica las siguientes Políticas de
					Privacidad (las “Políticas”).
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					{' '}
					Las Políticas constituyen una parte integrante de los Términos y
					Condiciones de la Aplicación los que el Usuario ha revisado y
					aceptado. Asimismo, el Usuario reconoce que con su registración como
					Usuario de la Aplicación presta su consentimiento para que se utilicen
					sus Datos Personales de conformidad con estas Políticas.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					INFORMACIÓN QUE RECOLECTAMOS
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					2.1 Para el funcionamiento de las Aplicación, YOPLABS recolecta la
					siguiente información:
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
				>
					● Si el Usuario lo permite, YOPLABS podrá conocer la ubicación
					geográfica del Usuario.
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					2.2. En caso de ser Usuario registrado de DEUTILES:
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
				>
					● Nombre y Apellido, Dirección, Documento Nacional de Identidad,
					Dirección de correo electrónico (e-mail), y Teléfono Móvil
					(incluyendo, número, modelo y operador). ● Historial de Compras.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
					mb={4}
				>
					● Si el Usuario lo permite, YOPLABS podrá conocer su ubicación
					geográfica
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					Los datos e información listados en 2.1 y 2.2 se definen como Datos
					Personales.
				</Typography>
				<Typography fontSize={20} fontFamily={'Krub'} textAlign={'justify'}>
					El Usuario declara y garantiza la veracidad, exactitud, vigencia y
					autenticidad de los Datos Personales ingresados en los Formularios de
					Registración de la Aplicación y asume el compromiso de mantener dichos
					Datos Personales actualizados. Si no lo hace, estará incurriendo en un
					incumplimiento de las Políticas y de los respectivos Términos y
					Condiciones, lo que podría dar lugar, entre otras cosas, a la
					rescisión inmediata de los Términos y Condiciones y del uso de la
					respectiva Aplicación.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					El Usuario es responsable de mantener la confidencialidad de su
					cuenta, nombre de usuario y contraseña, y asume la responsabilidad por
					todas las actividades que realicen en su cuenta.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS no será responsable por la veracidad, exactitud, vigencia y
					autenticidad de los Datos Personales ingresados por el Usuario.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Se deja expresamente establecido que los datos recabados por YOPLABS
					en ningún momento excederán el ámbito y la finalidad para los que se
					hubieren obtenido, de conformidad con el art. 4 de la Ley N° 25.326.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Los Datos Personales se procesarán y almacenarán en servidores o
					medios magnéticos que mantienen altos estándares de seguridad y
					protección tanto física como tecnológica. Dicha base de datos es
					administrada por YOPLABS.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					USO DE LA INFORMACIÓN RECOLECTADA
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Sus Datos Personales serán utilizados a los efectos de prestar los
					servicios correspondientes a las Aplicación, incluyendo sin
					limitación:
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
				>
					● Poner en contacto a los Usuarios con los distribuidores;
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
				>
					● Generar información sobre ventas;
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
				>
					● Ofrecer promociones y descuentos;
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					ml={2}
					mb={1}
				>
					● Enviar información sobre nuevos productos y ofertas, ya sea mediante
					correo electrónico, mensaje de texto o notificaciones en la
					Aplicación;
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Se acepta expresamente la cesión de los Datos Personales a DEUTILES,
					YOPLABS y sus afiliadas, proveedores de servicios, los distribuidores,
					los Proveedores, y los fabricantes y/o titulares y/o licenciatarios de
					las marcas de los productos disponibles a través de la Aplicación a
					los efectos de su procesamiento para los propósitos indicados
					anteriormente. Las personas indicadas no podrán ceder los Datos
					Personales a terceros.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS no cederá ni transferirá sus Datos Personales a otras personas
					físicas o jurídicas distintas de las indicadas.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Asimismo, se hace saber que en caso de que YOPLABS deje de tener
					derechos suficientes para operar la Aplicación, las bases de datos
					serán transferidas al titular de la Aplicación.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					A fin de detectar comportamientos incompatibles con los parámetros que
					hacen al buen uso de la Aplicación, YOPLABS podrá utilizar el
					identificador de publicidad del Usuario. YOPLABS respetará la
					configuración del identificador de publicidad elegida por el Usuario.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CONSENTIMIENTO
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Presto mi consentimiento para que mis datos personales, sean tratados
					en Argentina, y cedidos en caso de ser necesario para su procesamiento
					en la Unión Europea, Uruguay o Estados Unidos de conformidad con la
					legislación vigente en Argentina. Asimismo, presto mi consentimiento
					para que me hagan llegar vía correo electrónico u otro medio de
					comunicación, información sobre promociones y novedades relacionadas
					con la Aplicación, decisión que puedo revocar personalmente en todo
					momento a través de la configuración personal de mi cuenta.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					DERECHOS DEL TITULAR DE DATOS PERSONALES
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					El titular de los datos podrá ejercitar los derechos de acceder,
					cancelar y actualizar su Información Personal, incluyendo su dirección
					de e-mail, así como a oponerse al tratamiento de la misma y a ser
					informado de las cesiones y/o transferencias internacionales de la
					Información Personal llevadas a cabo, todo ello de conformidad a lo
					dispuesto en la normativa aplicable. Conforme se establece en el
					artículo 14, inciso 3 de la Ley Nº 25.326, el Titular de los datos
					tiene la facultad de ejercer el derecho de acceso a los mismos dentro
					de los 10 (diez) días corridos desde la acreditación de su identidad,
					en forma gratuita a intervalos no inferiores a seis meses, salvo que
					se acredite un interés legítimo al efecto. Asimismo, el Titular de los
					datos tiene derecho a que sean rectificados, actualizados y -cuando
					corresponda- suprimidos o sometidos a confidencialidad los datos
					personales de los que sea titular, dentro de los 5 (cinco) días
					hábiles de acreditada su identidad y de requerida la medida en
					cuestión.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					El titular de los datos garantiza y responde, en cualquier caso, de la
					veracidad, exactitud, vigencia y autenticidad de la Información
					Personal facilitada, y se compromete a mantenerla debidamente
					actualizada. YOPLABS no garantiza la certeza de los datos consignados
					por el los Titulares de los datos. Esto no obsta a la obligación de
					YOPLABS de rectificar, actualizar o suprimir los datos incorrectos y
					demás obligaciones dispuestas en la Ley N° 25.326 y lo aquí dispuesto.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA se encuentra en Av. Pte.
					Gral. Julio A. Roca 710, piso 3, Ciudad Autónoma de Buenos Aires, y su
					correo electrónico es info@aaip.gob.ar y es el órgano de control de la
					Ley Nº 25.326, contando con atribución suficiente para atender las
					denuncias y reclamos que se interpongan con relación al incumplimiento
					de las normas sobre protección de datos personales.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					El titular que desee suprimir o conocer los datos personales que
					constan en la misma, actualizarlos o rectificar errores, o bien
					solicitar el cese de envío de los correos electrónicos, deberá enviar
					un e-mail a la siguiente dirección de correo electrónico
					“info@deutiles.com". Si desea dejar de recibir mails de YOPLABS deberá
					hacer click en el botón de “Unsuscribe” al momento de recibir un
					correo.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					La solicitud será evacuada dentro de los diez días corridos de
					efectuada en forma fehaciente.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS conservará la Información Personal de los Titulares de los
					datos por un periodo de diez (10) años a fin de resolver disputas o
					reclamos, detectar problemas o incidencias y solucionarlos, y dar
					cumplimiento a lo dispuesto en los Términos y Condiciones, así como
					para cumplir con sus obligaciones legales. Ello, sin perjuicio al
					cumplimiento de su deber de supresión previo al plazo referido, en
					caso de corresponder, de oficio o a pedido de parte en los términos de
					los artículos 4 y 16 de la Ley N° 25.326.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Una vez finalizado el procedimiento de eliminación de sus datos
					personales, YOPLABS no tendrá registro alguno de los datos personales
					del titular en sus bases de datos, quedando únicamente los registros
					de pedidos realizados por el mismo, a los solos efectos de análisis de
					prevención de fraude y estadísticos de la plataforma, sin ser
					asociados a ninguna cuenta.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					COOKIES
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					La Aplicación utiliza cookies, pequeños archivos informáticos que se
					instalan en su dispositivo (computadora personal, Tablet, teléfono
					móvil, etc.), por un tiempo limitado a fin de personalizar la
					experiencia del usuario. Las cookies se pueden utilizar para recordar
					sus credenciales de acceso, registrar sus preferencias y otorgarle un
					acceso más rápido a la Aplicación, personalizar los servicios y
					ofertas disponibles en la Aplicación poniendo a disposición
					información que pueda resultar de su interés, personalizar
					publicidades en la Aplicación.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Usted puede desactivar el uso de cookies en su navegador, en cuyo caso
					no se mantendrán sus preferencias y algunas funcionalidades de la
					Aplicación no estarán disponibles. A continuación encontrará los
					vínculos a las páginas de ayuda de los principales navegadores
					explicando cómo desactivar las cookies:
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Usted puede desactivar el uso de cookies en su navegador, en cuyo caso
					no se mantendrán sus preferencias y algunas funcionalidades de la
					Aplicación no estarán disponibles. A continuación encontrará los
					vínculos a las páginas de ayuda de los principales navegadores
					explicando cómo desactivar las cookies:
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Chrome:{' '}
					<a href='https://support.google.com/chrome/answer/95647?hl=es '>
						https://support.google.com/chrome/answer/95647?hl=es{' '}
					</a>
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Mozilla Firefox:{' '}
					<a href='https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies'>
						https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectloc
						ale=en-US&redirectslug=Cookies{' '}
					</a>
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Edge:
					<a href='https://support.microsoft.com/es-ar/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy'>
						https://support.microsoft.com/es-ar/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy
					</a>
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Explorer:
					<a href='https://support.microsoft.com/es-ar/help/17442/windows-internet-explorer-delete-manage-cookies '>
						https://support.microsoft.com/es-ar/help/17442/windows-internet-explorer-delete-manage-cookies
					</a>
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Safari:
					<a href='https://support.apple.com/es-es/HT201265 '>
						https://support.apple.com/es-es/HT201265
					</a>
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					SITIOS EXTERNOS
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					La Aplicación puede contener enlaces y/o referencias a otras
					aplicaciones o sitios web de terceros, lo cual no indica que ellos
					sean propiedad de DEUTILES u operados por YOPLABS.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					La instalación de estos enlaces o referencias en la Aplicación se
					limita a facilitar al Usuario la búsqueda y acceso a la información
					allí disponible. En virtud de que YOPLABS no tiene control sobre tales
					aplicaciones y sitios, YOPLABS NO será responsable por los contenidos,
					materiales, acciones y/o servicios prestados por los mismos, ni por
					daños o pérdidas ocasionadas a los Usuarios o Distribuidores por la
					utilización de los mismos.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Por esta razón, YOPLABS recomienda que, cuando se redirija a sitios
					externos, los usuarios siempre consulten las respectivas políticas de
					privacidad de los sitios externos antes de dar su consentimiento para
					la recopilación / tratamiento de sus datos o información.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					WEB BEACONS
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					La Aplicación también usa web beacons (o pixels) que transmiten
					información de su dispositivo a un servidor, de forma similar a las
					cookies. Los web beacons pueden estar insertados en contenido online,
					videos y emails, y permiten leer cierto tipo de información de su
					dispositivo, en qué momento se ha accedido a determinado contenido o
					email, y la dirección IP del dispositivo. Los web beacons se utilizan
					en forma conjunta con las cookies y con el mismo propósito y para
					medir patrones de tráfico en la Web.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					SEGURIDAD DE LA INFORMACIÓN
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Toda información recabada en la Aplicación será tratada en
					cumplimiento de la Normativa y los datos personales serán utilizados
					únicamente para los fines aquí consentidos o los que consienta
					oportunamente.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS expresa su compromiso de proteger la seguridad de la
					información personal de los usuarios. Con ese fin, YOPLABS usa una
					amplia variedad de tecnologías y procedimientos de seguridad para
					proteger la información personal contra un acceso, uso, modificación o
					una divulgación no autorizados.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Sin perjuicio de esto, le recomendamos que no incluya información
					confidencial, secreta, comprometedora, datos sensibles o información
					personal delicada que usted no desea revelar en estas comunicaciones
					(incluyendo correos electrónicos). Asimismo, usted es responsable de
					proteger la confidencialidad de sus credenciales de acceso a la
					aplicación.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Usted reconoce y acepta que, a pesar de todos nuestros esfuerzos,
					ninguna medida de seguridad de datos puede garantizar la absoluta
					protección de los sistemas informáticos.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					El Titular de los datos conoce y acepta expresamente que YOPLABS, a su
					exclusivo criterio, recabe, almacene y eventualmente monitoree el
					intercambio de mensajes y correos electrónicos entre sus Titulares de
					los datos, efectuado dentro de la Aplicación de LABBI, con el objetivo
					de contribuir a la seguridad de las relaciones y comunicaciones que se
					desarrollan a través de la Aplicación.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CONFIDENCIALIDAD
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS y los cedentes autorizados tratarán en forma confidencial
					todos los Datos Personales a los que se tenga acceso en virtud del uso
					de la Aplicación y no los revelarán a terceros no autorizados.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS y los cedentes autorizados no estarán obligados a mantener la
					confidencialidad de los datos personales que se obtengan de fuentes de
					acceso público irrestricto.La Aplicación procesa los datos de los
					titulares principalmente dentro de la República Argentina. Sin
					embargo, algunos de nuestros proveedores de servicios tienen su sede
					en Estados Unidos y cumplen con los principios establecidos por la
					normativa aplicable para países con niveles no adecuados de
					legislación conforme la Ley 25.326.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					ÓRDENES DE AUTORIDADES COMPETENTES
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					YOPLABS podrá revelar Datos Personales ante la recepción de un
					requerimiento de las autoridades judiciales o gubernamentales
					competentes al efecto, incluyendo sin limitación, en caso de litigios
					penales, civiles o comerciales.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					El titular acepta y autoriza expresamente a YOPLABS a revelar los
					Datos Personales ante la recepción de dichos requerimientos.
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Asimismo, YOPLABS podrá, y el titular acepta y autoriza expresamente a
					YOP-DEV, revelar Datos Personales de la Farmacia a las autoridades
					competentes en caso de detectar operaciones sospechosas o criminales o
					en infracción de los Términos y Condiciones.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CAMBIO DE CONTROL SOCIETARIO/CORPORATIVO
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={1}
				>
					Si YOPLABS decide vender o transferir el control o los activos
					relacionados con la Aplicación a terceros, la Información / Datos
					personales de los Usuario , como el nombre, la dirección de correo
					electrónico, y otra información ingresada o disponible en la
					Aplicación puede estar entre los activos vendidos o transferidos a
					terceros, sin ninguna remuneración o pago a los Usuario . No obstante,
					YOPLABS se esforzará por garantizar que el tercero adquirente
					implemente una política de privacidad similar a esta.
				</Typography>
			</Grid>
			<Grid item xs={10} ml={8} mb={2}>
				<Typography
					variant='h2'
					fontSize={25}
					color='black'
					fontWeight='bold'
					fontFamily={'Krub'}
					mb={2}
					sx={{ textDecoration: 'underline' }}
				>
					CAMBIOS Y MODIFICACIONES
				</Typography>
				<Typography
					fontSize={20}
					fontFamily={'Krub'}
					textAlign={'justify'}
					mb={10}
				>
					YOPLABS podrá modificar las Políticas periódicamente. La versión
					actualizada de las Políticas se encontrará disponible en la Aplicación
					y en la web www.deutiles.com y las modificaciones entrarán en vigencia
					a los 10 (diez) días de su publicación.
				</Typography>
			</Grid>
		</Grid>
	</Fragment>
);

export default ViewPrivacyPolicies;
