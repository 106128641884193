import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import registerLibrary from 'data/api/libraryApi';
import { Failure, StatusRequest } from 'data/types';
import {
	RegisterLibraryRequest,
	RegisterLibraryResponse,
} from 'data/types/libraryTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TuserSlice = {
	registerLibraryStatusRequest?: StatusRequest;
	registerLibraryStatusError?: Failure;
};

const initialState: TuserSlice = {
	registerLibraryStatusRequest: StatusRequest.initial,
	registerLibraryStatusError: undefined,
};

export const RegisterLibraryAsync = createAsyncThunk<
	RegisterLibraryResponse,
	RegisterLibraryRequest,
	{ rejectValue: Failure }
>(
	'user/RegisterDistributorAsync',
	async (request: RegisterLibraryRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, RegisterLibraryResponse> =
				await registerLibrary.registerLibrary(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response post');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);
export const LibrarySlice = createSlice({
	name: 'library',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(RegisterLibraryAsync.pending, (state) => {
			state.registerLibraryStatusRequest = StatusRequest.pending;
			state.registerLibraryStatusError = undefined;
		});
		builder.addCase(RegisterLibraryAsync.fulfilled, (state, action) => {
			state.registerLibraryStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(RegisterLibraryAsync.rejected, (state, action) => {
			state.registerLibraryStatusRequest = StatusRequest.rejected;
			state.registerLibraryStatusError = action.payload;
		});
	},
});

export const selectLibrary = (state: RootState) => state.library;
export const {} = LibrarySlice.actions;
export default LibrarySlice.reducer;
