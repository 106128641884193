import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import {
	GetBannersRequest,
	GetBannersResponse,
} from 'data/types/bannersTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import BannersApi from 'data/api/bannersApi';

type TBannersSlice = {
	banners: GetBannersResponse | null;
	getBannersStatusRequest: StatusRequest;
	getBannersStatusError?: Failure;
};

const initialState: TBannersSlice = {
	banners: null,
	getBannersStatusRequest: StatusRequest.initial,
	getBannersStatusError: undefined,
};

export const GetBannersAsync = createAsyncThunk<
	GetBannersResponse,
	GetBannersRequest,
	{ rejectValue: Failure }
>('banners/GetBannersAsync', async (request: GetBannersRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetBannersResponse> =
			await BannersApi.getBanners();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const BannersSlice = createSlice({
	name: 'banners',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(GetBannersAsync.pending, (state) => {
			state.getBannersStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetBannersAsync.fulfilled, (state, action) => {
			state.getBannersStatusRequest = StatusRequest.fulfilled;
			state.banners = action.payload;
		});
		builder.addCase(GetBannersAsync.rejected, (state, action) => {
			state.getBannersStatusRequest = StatusRequest.rejected;
			state.getBannersStatusError = action.payload;
		});
	},
});

export const selectBanners = (state: RootState) => state.banners;
export const { } = BannersSlice.actions;
export default BannersSlice.reducer;
