import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetProductsRequest,
	GetProductsResponse,
	GetProductRequest,
	GetProductResponse,
	GetHighlightProductRequest,
	GetHighlightProductResponse,
} from 'data/types/productsTypes';
import { Either } from 'models/either';

export const getProducts = async (): Promise<
	Either<Failure, GetProductsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetProductsRequest, GetProductsResponse>({
			url: '/api/public/v1/products',
			secure: false
		})
	);
};

export const getProduct = async (
	request: GetProductRequest
): Promise<
	Either<Failure, GetProductResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetProductRequest, GetProductResponse>({
			url: `/api/public/v1/products/${request.masterProductID}`,
			secure: true
		})
	);
};

export const getHighlightProduct = async (): Promise<
	Either<Failure, GetHighlightProductResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetHighlightProductRequest, GetHighlightProductResponse>({
			url: '/api/public/v1/products/highlight',
			secure: false
		})
	);
};

const productsApi = {
	getProducts,
	getProduct,
	getHighlightProduct,
};

export default productsApi;
