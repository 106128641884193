import { Box, Divider, Grid, Typography } from '@mui/material';
import { ProductDescriptionOrder } from 'components/ModalDescriptionOrder/view';
import { FunctionComponent, Fragment } from 'react';

type Props = {
	product: ProductDescriptionOrder;
};

const ItemProductModalView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Grid container width={400} direction='column' mx={3} mt={2}>
			<Box>{props.product.itemName}</Box>
			<Box color='#80777E'>{props.product.catalogProductID}</Box>
			<Grid
				container
				direction='row'
				mt={2}
				justifyContent='space-between'
				pb={1}
			>
				<Box>
					<Box mr={1} display='inline'>
						<i
							className='icon-cart'
							style={{
								color: '#5C41BD',
							}}
						></i>
					</Box>
					{props.product.qtty}
				</Box>
				<Box>
					<Typography display='inline' mr={1} fontWeight={600}>
						$
					</Typography>
					<Typography display='inline' mr={1} fontWeight={600} fontSize={20}>
						{props.product.price}
					</Typography>
					<Typography display='inline' mr={1}>
						C/U
					</Typography>
				</Box>
			</Grid>
			<Divider />
		</Grid>
	</Fragment>
);

export default ItemProductModalView;
