import React from 'react';
import QuantitySelectorView from './view';
import QuantitySelectorWithButtonView from './view-with-button';

interface Props {
  handleQuantityConfirm: () => void;
  value: number;
  quantitySetter: React.Dispatch<React.SetStateAction<number>>;
  setEditingQuantity: React.Dispatch<React.SetStateAction<boolean>>;
  withButton: boolean;
}

export const QuantitySelector: React.FC<Props> = (props) => {

  const handleQuantityChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    props.setEditingQuantity(true);
    const newValue = ev.target.value;
    if (!newValue) {
      return props.quantitySetter(0);
    }
    const isAnumber = /^\d{0,4}$/.test(newValue);
    if (!isAnumber) {
      return;
    }
    const newQuantity = parseInt(newValue) || 0;
    if (newQuantity >= 0) {
      props.quantitySetter(newQuantity);
    }
  }

  const handleQuantityAdd = () => {
    props.setEditingQuantity(true);
    if (props.value < 9999) {
      props.quantitySetter(props.value + 1)
    }
  }

  const handleQuantityRemove = () => {
    props.setEditingQuantity(true);
    if (props.value > 0) {
      props.quantitySetter(props.value - 1)
    }
  }

  if (props.withButton) {
    return <QuantitySelectorWithButtonView
      handleQuantityRemove={handleQuantityRemove}
      handleQuantityAdd={handleQuantityAdd}
      handleQuantityChange={handleQuantityChange}
      {...props} />;
  } else {
    return <QuantitySelectorView
      handleQuantityRemove={handleQuantityRemove}
      handleQuantityAdd={handleQuantityAdd}
      handleQuantityChange={handleQuantityChange}
      {...props} />;
  }
};
