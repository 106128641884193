import {
	GetOrdersAsync,
	GetSubOrderAsync,
	selectOrders,
} from 'data/global/features/OrdersSlice/ordersSlice';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import React, { useEffect } from 'react';
import useStyles from './useStyles';
import { ViewOrders } from './view';
import { useParams } from 'react-router-dom';

export const Orders: React.FC = () => {
	const dispatch = useAppDispatch();
	const params = useParams();

	useEffect(() => {
		dispatch(GetOrdersAsync({}));
	}, []);

	const { getOrdersResponseRequest } = useAppSelector(selectOrders);

	const classes = useStyles();
	return <ViewOrders orders={getOrdersResponseRequest} classes={classes} />;
};
