import { FunctionComponent, Fragment } from 'react';
import { Badge, Fab } from '@mui/material';
type Props = { cartItem: any };

const CartButtonView: FunctionComponent<Props> = (props) => (
	<Fragment>
		{/* badgeContent={props.cartItem.data.items} */}
		<Badge color='success'>
			<a
				href='/cart'
				color='inherit'
				style={{ cursor: 'pointer', textDecoration: 'none' }}
			>
				<Fab
					size='small'
					style={{
						background: '#FFFFFF',
					}}
				>
					<i
						className='icon-cart'
						style={{ fontSize: '18px', color: '#FF6651' }}
					></i>
				</Fab>
			</a>
		</Badge>
	</Fragment>
);

export default CartButtonView;
