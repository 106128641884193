import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import style from './QuantitySelector.module.css';

type QuantitySelectorProps = {
  handleQuantityRemove: () => void,
  handleQuantityAdd: () => void,
  handleQuantityChange: (ev: React.ChangeEvent<HTMLInputElement>) => void,
  handleQuantityConfirm: () => void,
  value: number
};

const QuantitySelectorWithButtonView: FunctionComponent<QuantitySelectorProps> = ({
  handleQuantityRemove,
  handleQuantityAdd,
  handleQuantityChange,
  handleQuantityConfirm,
  value }) => (
  <div className={style.qttyWrapper}>
    <div className={style.qtty}>
      <div className={style.flexRow}>
        <i className={`icon-chevron-left ${style.chevronLeft}`} onClick={handleQuantityRemove}  ></i>
        <input className={style.inputQuantity} type="text" value={value} onChange={handleQuantityChange} />
        <i className={`icon-chevron-right ${style.chevronRight}`} onClick={handleQuantityAdd}  ></i>
      </div>
    </div>

    <Button
      variant='contained'
      color='primary'
      size='large'
      sx={{ borderRadius: '50px', marginLeft: '20px' }}
      disableElevation
      onClick={handleQuantityConfirm}
    >
      <i
        className={`icon-cart ${style.iconCheck}`}
      ></i>
    </Button>

  </div>
);

export default QuantitySelectorWithButtonView;
