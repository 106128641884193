import React, { useEffect, useState } from 'react';
import useStyles from '../../components/RightSectionRegisterLibrary/useStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ResetPasswordView } from './view';
import { StatusRequest } from '../../data/types';
import { useAppSelector } from '../../data/global/hooks';
import { selectUser } from '../../data/global/features/UserSlice/userSlice';
import useValidationCode from '../../components/ResetPassword/hooks/useValidationCode';
import namesRoutes from '../../routes/namesRoutes';

export const ResetPassword: React.FC = () => {

	const classes = useStyles();
	const { t } = useTranslation();
	const validationCode = useValidationCode();
	const params = useParams();
	const navigate = useNavigate();
	const { validationCodeStatusRequest, validationCodeStatusError } = useAppSelector(selectUser);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		validationCode({ code: params.code });
	}, []);

	useEffect(() => {
		if (validationCodeStatusRequest === StatusRequest.rejected) {
			alert(validationCodeStatusError?.message);
			navigate(namesRoutes.home);
		} else if (validationCodeStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else if (validationCodeStatusRequest === StatusRequest.fulfilled ) {
			setLoading(false);
		}
	}, [validationCodeStatusRequest]);

	return <ResetPasswordView loading={loading}  />;
};
