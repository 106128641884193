var api_url = process.env.REACT_APP_API_URL_FRONTEND || 'http://localhost:4000';

const config = {
	baseURL: api_url,
	headers: {
		accessToken: localStorage.getItem('accessToken'),
	},
};

export default config;
