import { Fragment, FunctionComponent } from 'react';
import ResetPassword from '../../components/ResetPassword';
import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material';

type Props = { loading: boolean };

export const ResetPasswordView: FunctionComponent<Props> = (props) => (
	<Fragment>{props.loading ? (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<CircularProgress />
		</div>
	) : (
		<Fragment>
			<ResetPassword></ResetPassword>
		</Fragment>
	)}
	</Fragment>

);

