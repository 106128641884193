import { FunctionComponent } from 'react';
import { TextField as TextFieldMUI, TextFieldProps } from '@mui/material';

type Props = {
} & TextFieldProps;

export const TextField: FunctionComponent<Props> = (props) => (
	<TextFieldMUI
		fullWidth
		{...props}
	>
		{props.children}
	</TextFieldMUI>
);
