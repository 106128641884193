import { CatalogProduct } from 'data/types/catalogTypes';
import { GetProductResponse } from 'data/types/productsTypes';
import { NavBarHome, CartButton, QuantitySelector } from 'components';
import { Fragment, FunctionComponent } from 'react';
import style from './ViewItem.module.css';
import { Button, Typography, Grid } from '@mui/material';

type Props = {
	classes: any;
	dataViewItem: GetProductResponse | null;
	loading: boolean;
	onClick: (e: any) => void;
	addProduct: boolean;
	updateItem: (qtty: number, cpid: any) => void;
	seletedDistributor: (distributor: any, index: number) => void;
	selectDistributor: any;
	qttyInCart: number;
	disabled: boolean;
  handleQuantityConfirm: () => void;
  quantityValue: number;
  quantitySetter: React.Dispatch<React.SetStateAction<number>>;
};

export const ViewViewItem: FunctionComponent<Props> = (props) => (
	<Fragment>
		<NavBarHome />
		{props.dataViewItem != null && !props.loading ? (
			<main className={style.main}>
				<Grid
					container
					display='flex'
					ml={6}
					justifyContent='end'
					pt={2}
					pb={2}
				>
					<CartButton />
				</Grid>
				<section>
					<aside>
						<div className={style.imageSection}>
							<img
								src={props.dataViewItem?.masterProductFrontend.photo}
								alt='Product'
							/>
						</div>
						<div className={style.aboutSection}>
							<h2>Acerca de este producto</h2>
							<p>{props.dataViewItem?.masterProductFrontend.description}</p>
						</div>
					</aside>
					<aside>
						<div className={style.infoSection}>
							<h4>{props.dataViewItem?.masterProductFrontend.brandName}</h4>
							<h3>{props.dataViewItem?.masterProductFrontend.name}</h3>
							<div className={style.contentDescription}>
								<span>Seleccioná tu mayorista y precio de conveniencia.</span>

								<nav className={style.optionsNav}>
									{props.dataViewItem?.catalogProductFrontendList.map(
										(distributor: CatalogProduct, index: number) => {
											return (
												<div
													className={style.navItem}
													onClick={() =>
														props.seletedDistributor(distributor, index)
													}
													key={distributor.catalogProductId}
												>
													{distributor.catalogProductId ===
													props.selectDistributor.catalogProductId ? (
														<aside className={props.classes.cardAside}>
															<i
																className='icon-check'
																style={{
																	color: 'white',
																	fontSize: '15px',
																	position: 'absolute',
																	top: '3px',
																	right: '3px',
																	zIndex: '2',
																}}
															></i>
														</aside>
													) : (
														<></>
													)}
													<span className={style.catalogProductName}>
														{distributor.distributorName}
													</span>
													<span className={style.catalogProductPrice}  >
														<b>{localStorage.getItem('jwt') ? "$" + distributor.price?.toFixed(2) : <span style={{ fontSize: '0.9rem' }}>Registrate para ver este precio</span>} </b> {localStorage.getItem('jwt') ? "c/u" : ""}
														<span className={style.catalogProductPriceIva} > {localStorage.getItem('jwt') ? "(incluye IVA)" : ""}</span>
													</span>
												</div>
											);
										}
									)}
								</nav>
								<div className={style.cartStatus}>
									{props.addProduct === false ? (
										<span>
											<Button
												variant='contained'
												size='small'
												sx={{ borderRadius: '50px' }}
												disableElevation
												onClick={() =>
													props.onClick(
														props.selectDistributor.catalogProductId
													)
												}
											>
												<Typography
													color='white'
													p={0.8}
													display='flex'
													alignItems='center'
													fontFamily='Krub'
													fontWeight='bold'
													fontSize='14px'
												>
													Agregar
													<i
														className='icon-add'
														style={{ fontSize: '28px', marginLeft: '20px' }}
													></i>
												</Typography>
											</Button>
										</span>
									) : (
                      <QuantitySelector
                        handleQuantityConfirm={props.handleQuantityConfirm}
                        value={props.quantityValue}
                        quantitySetter={props.quantitySetter}
                        setEditingQuantity={() => { } } 
                        withButton={true}                      />
									)}

									<div className={style.cartPrice}>
										$
										<span>
											{props.selectDistributor.price
												? (
														props.selectDistributor.price * props.qttyInCart
												  ).toFixed(2)
												: 0}
										</span>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</section>
			</main>
		) : (
			<div className={props.classes.loading}>Cargando Item...</div>
		)}
	</Fragment>
);
