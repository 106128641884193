import {
	GetBrandsAsync,
	selectBrands,
} from 'data/global/features/BrandsSlice/brandsSlice';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import { Brand } from 'data/types/brandsTypes';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SideBarCatalogView from './view';

interface Props {
	items: number;
	itemsByBrand: {[key: string]: number} | undefined
	category?: string;
	onUpdateFilters: (ids: Brand[]) => void;
}

export const SideBarCatalog: FunctionComponent<Props> = ({
	onUpdateFilters,
	items,
	itemsByBrand,
	category,
}) => {
	items = items ? items : 0;
	category = category ? category : 'Catalogo completo';

	const { brands } = useAppSelector(selectBrands);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	const initialBrandsSelected: { idx: number; name: string }[] = [];
	if (params.brand && brands) {
		params.brand.split(',').forEach((brandID) => {
			const index = brands.findIndex((brand) => brand.brandID === brandID);
			if (index >= 0) {
				initialBrandsSelected.push({
					idx: index,
					name: brands[index].brandName,
				});
			}
		});
	}

	const [checked, setChecked] = useState([]);

	useEffect(() => {
		setChecked(initialBrandsSelected as any);
	}, [brands]);

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		let newStateChecked: any[] = [...checked];
		const idxFdn = newStateChecked.findIndex((i: any) => i.idx === index);
		if (idxFdn >= 0) {
			newStateChecked.splice(idxFdn, 1);
		} else {
			newStateChecked.push({
				idx: index,
				name: event.target.name,
			});
		}
		setChecked(newStateChecked as any);
		const fb = brands?.filter(
			(brand, index) =>
				newStateChecked.findIndex((state) => state.idx === index) >= 0
		);
		onUpdateFilters(fb as Brand[]);
	};

	// todavia esto no esta funcionando bien
	const handleDelete = async (e: any, index: number) => {
		let newStateChecked: any[] = [...checked];
		const idxFdn = newStateChecked.findIndex((i: any) => i.idx === index);
		if (idxFdn >= 0) {
			newStateChecked.splice(idxFdn, 1);
			setChecked(newStateChecked as any);
			const fb = brands?.filter(
				(brand, index) =>
					newStateChecked.findIndex((state) => state.idx === index) >= 0
			);
			onUpdateFilters(fb as Brand[]);
		}
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			GetBrandsAsync({ showInHome: false, categoryId: params.category })
		);
	}, []);

	return (
		<SideBarCatalogView
			brands={brands}
			handleDelete={handleDelete}
			checked={checked}
			handleChange={handleChange}
			items={items}
			itemsByBrand={itemsByBrand}
			category={category}
		/>
	);
};

export default SideBarCatalog;
