import React, { FunctionComponent } from 'react';
import ButtonAddProductsView from './view';
import useStyles from './useStyles';

type Props = { qtty: number, catalogProductID: string, clickUpdateProduct: (qtty: number) => void; };

export const ButtonAddProducts: FunctionComponent<Props> = (props) => {
	const classes = useStyles();
	return <ButtonAddProductsView classes={classes} qtty={props.qtty} catalogProductID={props.catalogProductID} clickUpdateProduct={props.clickUpdateProduct} />;
};

// export default ButtonAddProducts;