import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { Order } from 'data/types/ordersTypes';
import { FunctionComponent, Fragment } from 'react';

type Props = {
	order: Order;
};

const ItemOrderView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Grid direction='row' container mx={10} mt={1} bgcolor='white'>
			<Grid item xs={3} pl={5} bgcolor='#5C41BD' color='white' height={200}>
				<Box mt={4} style={{ fontFamily: 'Krub' }}>
					Cotización
				</Box>

				<Typography
					fontSize={25}
					fontFamily='Krub'
					fontWeight='700'
					variant='body1'
					noWrap
				>
					{props.order.orderID}
				</Typography>

				<Box mt={6}>
					<Box display='inline' mr={1}>
						<i
							className='icon-cart'
							style={{
								color: 'white',
							}}
						></i>
					</Box>
					<Typography display='inline' fontFamily='Krub'>
						{props.order.itemsInOrder}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={3} pl={4}>
				<Box mt={4} style={{ fontFamily: 'Krub' }}>
					Creación
				</Box>
				<Typography fontSize={15} fontWeight={700} fontFamily='Krub'>
					{(new Date(props.order.creationDate)).toLocaleString('es-AR')}
				</Typography>
			</Grid>
			<Grid item xs={6} pl={4}>
				<Box mt={4} style={{ fontFamily: 'Krub' }}>
					Mayorista
				</Box>
				<Typography fontSize={15} fontWeight={700} fontFamily='Krub'>
					{props.order.distributorList.map((distributor: string) => {
						return <>{distributor}</>;
					})}
				</Typography>

				<Grid container direction='row' mt={2} justifyContent='space-between'>
					<Box>
						<Box mt={4} style={{ fontFamily: 'Krub' }}>
							Total Cotización
						</Box>
						<Box color='#5C41BD'>
							<Typography
								fontSize={15}
								mr={0.5}
								display='inline'
								fontFamily='Krub'
							>
								$
							</Typography>
							<Typography fontWeight={600} fontSize={20} display='inline'>
								{props.order.totalPrice.toFixed(2)}
							</Typography>
						</Box>
					</Box>

					<Box mt={3} mr={4}>
						<Link
							href={`/orderDetail/${props.order.orderID}`}
							underline='none'
							color='inherit'
						>
							<Button
								variant='contained'
								size='large'
								sx={{ borderRadius: '21px' }}
							>
								<Typography
									color='white'
									variant='body2'
									fontFamily='Krub'
									fontWeight='bold'
								>
									Ver detalle
								</Typography>
							</Button>
						</Link>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	</Fragment>
);

export default ItemOrderView;
