import { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, InputBase, InputLabel, MenuItem, NativeSelect, Select as SelectMUI, SelectProps } from '@mui/material';
type Props = {} & SelectProps;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	'& .MuiInputBase-input': {
		borderRadius: 21,
		position: 'relative',
		padding: '10px 26px 10px 12px',
		backgroundColor: '#E8E3FA',
		fontSize: 16,
		'& fieldset': {
			borderColor: 'transparent',
		},
		'&:focus': {
			borderRadius: 21,
			borderColor: '#5C41BD',
			boxShadow: '0 0 0 0.1rem rgba(92,65,189,1)',
		},
	},
	'& .MuiMenuItem-root': {
		padding: 2,
	  },
}));


export const Select: FunctionComponent<Props> = ({
	size = 'small',
	color = 'secondary',
	...props
}) => (
	<FormControl fullWidth>
		<NativeSelect
			id="demo-customized-select-native"
			value={"1"}
			onChange={() => { }}
			input={<BootstrapInput/>}
		>
			<option aria-label="None" value="1" style={{backgroundColor: '#E8E3FA', padding: "10px 26px 10px 12px"}}/>
			<option value={10}>Ten</option>
			<option value={20}>Twenty</option>
			<option value={30}>Thirty</option>
		</NativeSelect>
	</FormControl>
);
