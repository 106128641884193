import { Routes, Route, BrowserRouter } from 'react-router-dom';
import routes from 'routes';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const RoutesApp = () => {
	return (
		<BrowserRouter>
			<Routes>
				{routes.map((route, index) => (
					<Route path={route.path} element={<route.component />} key={index} />
				))}
			</Routes>
		</BrowserRouter>
	);
};

const App = () => {
    useEffect(() => {
		TagManager.initialize({
			gtmId: process.env.REACT_APP_GTM_ID || '',
		});
    },[])

	return <RoutesApp />;
};

export default App;
