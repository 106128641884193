import { useAppDispatch } from 'data/global/hooks';
import { signInUserAsync } from 'data/global/features/AuthSlice/authSlice';
import { SignInRequest } from 'data/types/authTypes';

const useSignIn = () => {
	const dispatch = useAppDispatch();

	const signIn = (user: SignInRequest): void => {
		dispatch(signInUserAsync(user));
	};

	return signIn;
};

export default useSignIn;
