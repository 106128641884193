import { useAppDispatch } from 'data/global/hooks';
import { ValidationCodeAsync } from 'data/global/features/UserSlice/userSlice';
import { ValidationCodeRequest} from 'data/types/userTypes';

export const useValidationCode = () => {
	const dispatch = useAppDispatch();

	const validationCode = (code: ValidationCodeRequest): void => {
		dispatch(ValidationCodeAsync(code));
	};

	return validationCode;
};

export default useValidationCode;
