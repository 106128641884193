import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	styleCardActions: {
		display: 'flex',
		justifyContent: 'center',
		marginRight: '1em',
		marginBottom: '1em',
		height: '60px',
	},
	button: { textTransform: 'none' },
}));
export default useStyles;
