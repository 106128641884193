import React from 'react';
import RigthFormView from './view';
import useStyles from './useStyles';
import {useLocation} from "react-router-dom";
interface LocationState {
	tabIndex: number;
}
export const RightFormRegister: React.FC = () => {
	const classes = useStyles();

	const location = useLocation();
	const state = location.state as LocationState; // Type Casting, then you can get the params passed via router
	const tabIndex = state? state.tabIndex : 0;
	//logica de la Tab
	const [value, setValue] = React.useState(tabIndex);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const a11yProps = (index: number) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};

	return (
		<RigthFormView
			classes={classes}
			handleChange={handleChange}
			value={value}
			a11yProps={a11yProps}
		/>
	);
};
