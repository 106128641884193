import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetBannersRequest,
	GetBannersResponse,
} from 'data/types/bannersTypes';
import { Either } from 'models/either';

export const getBanners = async (): Promise<
	Either<Failure, GetBannersResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetBannersRequest, GetBannersResponse>({
			url: '/api/public/v1/banners',
			secure: false
		})
	);
};


const BannersApi = {
	getBanners,
};

export default BannersApi;
