import { FunctionComponent, Fragment } from 'react';
import { Box, Grid, Typography, Button, Paper } from '@mui/material';

import Slider from 'react-slick';

type Props = {
	settings: Object;
	previous: (e: any) => void;
	next: (e: any) => void;
	slider: any;
	title?: string;
	children?: any;
};

const SliderView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Grid container>
			<Grid item xs={10}>
				<Typography
					fontFamily='Krub'
					fontWeight='lighter'
					fontSize={30}
					color='#5C41BD'
					mb={4}
					ml={1}
				>
					{props.title}
				</Typography>
			</Grid>
			<Grid item xs={2} display='flex' justifyContent='flex-end'>
				<Button onClick={props.previous}>
					<i className='icon-arrow-back' style={{ fontSize: '25px' }}></i>
				</Button>
				<Button onClick={props.next}>
					<i className='icon-arrow-forward' style={{ fontSize: '25px' }}></i>
				</Button>
			</Grid>
		</Grid>
		<Slider ref={props.slider} {...props.settings}>
			{props.children}
		</Slider>
	</Fragment>
);

export default SliderView;
