import React, { useEffect, useState } from 'react';
import RightSectionRegisterLibraryView from './view';
import useStyles from './useStyles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'data/global/hooks';
import { useRegisterLibrary, useValidationUser } from './hooks';
import { useParams } from 'react-router-dom';
import {selectUser, setUserLocation} from 'data/global/features/UserSlice/userSlice';
import { StatusRequest } from 'data/types';
import { rulesCUIT } from 'utils/validationRules';
import { useNavigate } from 'react-router-dom';
import namesRoutes from 'routes/namesRoutes';
import { selectLibrary } from 'data/global/features/LibrarySlice/librarySlice';
import {useDispatch} from "react-redux";

export const RightSectionRegisterLibrary: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const registerLibrary = useRegisterLibrary();
	const validationUser = useValidationUser();
	const params = useParams();
	const navigate = useNavigate();
	const { validationStatusRequest } = useAppSelector(selectUser);
	const { registerLibraryStatusRequest, registerLibraryStatusError } =
		useAppSelector(selectLibrary);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		validationUser({ code: params.code });
	}, []);

	useEffect(() => {
		if (registerLibraryStatusRequest === StatusRequest.fulfilled) {
			navigate('/registerDistributors');
		} else if (registerLibraryStatusRequest === StatusRequest.pending) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [registerLibraryStatusRequest]);

	if (validationStatusRequest === StatusRequest.rejected) {
		// navigate(namesRoutes.home);
	}

	const rules = {
		rulesEmail: rulesCUIT(t),
	};

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onBlur' });

	const handleSaveLocation = (location: string) => {
		dispatch(setUserLocation(location));
	};

	const defaultValue = {
		libraryName: '',
		businessName: '',
		cuit: '',
		address: {
			city: '',
			lat: '',
			lng: '',
			street: '',
		},
		code: '',
		areaCode: '',
		phone: '',
	};
	return (
		<RightSectionRegisterLibraryView
			classes={classes}
			formProps={{ handleSubmit, errors, isSubmitting, isValid, setValue }}
			controlProps={{ control, defaultValue, rules }}
			registerLibrary={registerLibrary}
			loading={loading}
			error={registerLibraryStatusError}
			onSaveLocation={handleSaveLocation}
		/>
	);
};
