import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetCategoriesRequest,
	GetCategoriesResponse,
	GetCategoryRequest,
	GetCategoryResponse
} from 'data/types/categoriesTypes';
import { Either } from 'models/either';

export const getCategories = async (): Promise<
	Either<Failure, GetCategoriesResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetCategoriesRequest, GetCategoriesResponse>({
			url: '/api/public/v1/categories',
			secure: false
		})
	);
};

export const getCategory = async (): Promise<
	Either<Failure, GetCategoryResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetCategoryRequest, GetCategoryResponse>({
			//TODO usar request
			url: '/api/public/v1/categories/{id}',
		})
	);
};


const categoriesApi = {
	getCategories,
	getCategory,
};

export default categoriesApi;