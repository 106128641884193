import React, { FunctionComponent, Fragment } from 'react';
import { Button } from 'UI';
import { Input } from 'UI/Input';
import { Typography, Grid, Box, Alert, CircularProgress } from '@mui/material';
import { ControlProps, FormProps } from 'models/form';
import { SignInRequest } from 'data/types/authTypes';
import { Link } from 'react-router-dom';

type Props = {
	classes: any;
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	signIn: (user: SignInRequest) => void;
	loading: boolean;
	refInputs: any;
	recoverFunction: any;
};


const FormLoginView: FunctionComponent<Props> = (props) => (
	<Fragment>
		{props.loading ? (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</div>
		) : (
			<Fragment>
				<Grid container>
					<Grid item xs={12} px={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email,
								name: 'email',
								rules: props.controlProps.rules.rulesEmail,
							}}
							inputRef={props.refInputs.emailRef}
							errors={props.formProps.errors}
							type='text'
							placeholder='Email'
						/>
					</Grid>
					<Grid item xs={12} mt={2} px={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email,
								name: 'password',
								rules: props.controlProps.rules.rulesPassword,
							}}
							errors={props.formProps.errors}
							type='password'
							placeholder='Contraseña'
						/>
					</Grid>
				</Grid>
				<Box pl={4} pt={3}>
					{ <Typography
				variant='subtitle2'
				fontSize={16}
				color='#80777E'
				fontFamily={'var(--unnamed-font-family-krub)'}
				fontWeight={'bold'}
			>
				¿Olvidaste tu contraseña?
				<span className={props.classes.createPassword}><Button onClick={props.recoverFunction}>Creá una nueva</Button>  </span>
			</Typography> }
					{props?.error?.error && (
						<Alert severity='error' style={{ marginTop: '1.5em' }}>
							{props?.error?.error}
						</Alert>
					)}
					<Grid container justifyContent='flex-end' pr={3} pt={4}>
						<Button
							variant='contained'
							size='small'
							style={{
								textTransform: 'none',
							}}
							sx={{ borderRadius: '20px' }}
							disableElevation
							onClick={props.formProps.handleSubmit(props.signIn)}
						>
							<Typography color='white' variant='body2' p={0.8}>
								Aceptar
							</Typography>
						</Button>
					</Grid>
				</Box>
			</Fragment>
		)}
	</Fragment>
);

export default FormLoginView;
