import React, { FunctionComponent } from 'react';
import { Typography, Box } from '@mui/material';
import { ControlProps, FormProps } from 'models/form';
import { ResendEmailRequest } from 'data/types/userTypes';
import { Button } from '../../UI';
import { Input } from '../../UI/Input';

type Props = {
	classes: any;
	resendEmail: (resendEmail: any) => void;
	formProps:  FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	email: any;
};

const VerifyEmailView: FunctionComponent<Props> = (props) => (
	<Box ml={2} mt={20} className={props.classes.container}>
		<Input
			controllerProps={{
				control: props.controlProps?.control,
				defaultValue:props.email,
				name: 'email',
			}}
			type='hidden'
			errors={props.formProps?.errors}/>
		<Typography
			fontFamily={'Krub'}
			fontSize={24}
			color='#FF6651'
			fontWeight='bold'
			variant='h1'
			align='left'
		>
			Por favor, verificá tu email
		</Typography>
		<Typography
			fontFamily={'Krub'}
			fontSize={18}
			color='#80777E'
			fontWeight='bold'
			variant='body2'
			align='left'
			mt={4}
		>
			Te enviamos un mensaje a
		</Typography>
		<Typography
			fontFamily={'Krub'}
			fontSize={20}
			color='#FF6651'
			variant='body2'
			align='left'
			mt={1}
		>
			{props.email}
		</Typography>
		<Typography
			fontFamily={'Krub'}
			fontSize={16}
			color='#80777E;'
			fontWeight='normal'
			variant='body2'
			align='left'
			width={320}
			mt={2}
		>
			Validá tu cuenta haciendo click en el enlace que aparece en el email.
		</Typography>

		{<Typography
			fontFamily={'Krub'}
			fontSize={18}
			color='#80777E;'
			fontWeight='bold'
			variant='body2'
			align='left'
			width={320}
			mt={4}
		>
			¿No recibiste el email?
			<span
				className={props.classes.linkEmail}
			>
				<Button 	className={props.classes.linkEmail} onClick={props.formProps.handleSubmit(props.resendEmail)}>Reenviar el email</Button>
			</span>
		</Typography>}
	</Box>
);

export default VerifyEmailView;
