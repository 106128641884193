import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetOrdersRequest,
	GetOrderRequest,
	SendOrderRequest,
	GetOrdersResponse,
	GetOrderResponse,
	SendOrderResponse,
	GetSubOrderRequest,
	GetSubOrderResponse,
} from 'data/types/ordersTypes';
import { Either } from 'models/either';

export const getOrders = async (
	request: GetOrdersRequest
): Promise<Either<Failure, GetOrdersResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetOrdersRequest, GetOrdersResponse>({
			url: '/api/v1/orders',
			request,
			secure: true,
		})
	);
};

export const getOrder = async (
	request: GetOrderRequest
): Promise<Either<Failure, GetOrderResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetOrderRequest, GetOrderResponse>({
			url: '/api/v1/orders/detail/' + request.id,
			secure: true,
		})
	);
};

export const sendOrder = async (
	request: SendOrderRequest
): Promise<Either<Failure, SendOrderResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<SendOrderRequest, SendOrderResponse>({
			url: '/api/v1/orders',
			request,
			secure: true,
		})
	);
};

export const getSubOrder = async (
	request: GetSubOrderRequest
): Promise<Either<Failure, GetSubOrderResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetSubOrderRequest, GetSubOrderResponse>({
			url: '/api/v1/orders/suborder/' + request.subOrderId,
			secure: true,
		})
	);
};

const libraryApi = {
	sendOrder,
	getOrder,
	getOrders,
	getSubOrder,
};

export default libraryApi;
