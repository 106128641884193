import React, { useEffect } from 'react';
import VerifyEmailView from './view';
import useStyles from './useStyles';
import { useForm } from 'react-hook-form';
import { useResendEmail } from './hooks';
import { StatusRequest } from '../../data/types';
import { useAppSelector } from '../../data/global/hooks';
import { selectAuth } from '../../data/global/features/AuthSlice/authSlice';
import { selectUser } from '../../data/global/features/UserSlice/userSlice';
import { CircularProgress } from '@mui/material';

export const VerifyEmail: React.FC = () => {
	const classes = useStyles();
	const resendEmailUser = useResendEmail();
	const email = localStorage.getItem('email');
	const { resendEmailStatusError, resendEmailStatusRequest } = useAppSelector(selectUser);
	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	if (resendEmailStatusRequest == StatusRequest. pending) {

			return (<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</div>);

	}else{
		return (

			<VerifyEmailView
				classes={classes}
				resendEmail={resendEmailUser}
				formProps={{  handleSubmit, errors, isSubmitting, isValid }}
				controlProps={{ control }}
				email={email}
			/>
		);
	}
};
