import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import ordersApi from 'data/api/ordersApi';
import { Failure, StatusRequest } from 'data/types';
import {
	GetOrdersRequest,
	GetOrderRequest,
	SendOrderRequest,
	GetOrdersResponse,
	GetOrderResponse,
	SendOrderResponse,
	GetSubOrderRequest,
	GetSubOrderResponse,
} from 'data/types/ordersTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TordersSlice = {
	getOrdersResponseRequest: GetOrdersResponse | null;
	getOrderResponseRequest: GetOrderResponse | null;
	getOrdersStatusRequest?: StatusRequest;
	getOrdersStatusError?: Failure;
	getOrderStatusRequest?: StatusRequest;
	getOrderStatusError?: Failure;
	sendOrdersStatusRequest?: StatusRequest;
	sendOrdersStatusResponse?: SendOrderResponse;
	sendOrdersStatusError?: Failure;
	getSubOrderStatusRequest: StatusRequest;
	getSubOrderResponseRequest?: GetSubOrderResponse | null;
	getSubOrderStatusError?: Failure;
};

const initialState: TordersSlice = {
	getOrdersResponseRequest: null,
	getOrderResponseRequest: null,
	getSubOrderResponseRequest: null,
	getOrdersStatusRequest: StatusRequest.initial,
	getOrdersStatusError: undefined,
	getOrderStatusRequest: StatusRequest.initial,
	getOrderStatusError: undefined,
	sendOrdersStatusRequest: StatusRequest.initial,
	sendOrdersStatusError: undefined,
	getSubOrderStatusRequest: StatusRequest.initial,
	getSubOrderStatusError: undefined,
};

export const GetOrdersAsync = createAsyncThunk<
	GetOrdersResponse,
	GetOrdersRequest,
	{ rejectValue: Failure }
>('orders/GetOrdersAsync', async (request: GetOrdersRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetOrdersResponse> =
			await ordersApi.getOrders(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetOrderAsync = createAsyncThunk<
	GetOrderResponse,
	GetOrderRequest,
	{ rejectValue: Failure }
>('orders/GetOrderAsync', async (request: GetOrderRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetOrderResponse> =
			await ordersApi.getOrder(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetSubOrderAsync = createAsyncThunk<
	GetSubOrderResponse,
	GetSubOrderRequest,
	{ rejectValue: Failure }
>('orders/GetSubOrderAsync', async (request: GetSubOrderRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetSubOrderResponse> =
			await ordersApi.getSubOrder(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const SendOrderAsync = createAsyncThunk<
	SendOrderResponse,
	SendOrderRequest,
	{ rejectValue: Failure }
>('orders/SendOrderAsync', async (request: SendOrderRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, SendOrderResponse> =
			await ordersApi.sendOrder(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const OrdersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(GetOrdersAsync.pending, (state) => {
			state.getOrdersStatusRequest = StatusRequest.pending;
			state.getOrdersStatusError = undefined;
		});
		builder.addCase(GetOrdersAsync.fulfilled, (state, action) => {
			state.getOrdersStatusRequest = StatusRequest.fulfilled;
			state.getOrdersResponseRequest = action.payload;
		});
		builder.addCase(GetOrdersAsync.rejected, (state, action) => {
			state.getOrdersStatusRequest = StatusRequest.rejected;
			state.getOrdersStatusError = action.payload;
		});
		builder.addCase(GetOrderAsync.pending, (state) => {
			state.getOrderStatusRequest = StatusRequest.pending;
			state.getOrderStatusError = undefined;
		});
		builder.addCase(GetOrderAsync.fulfilled, (state, action) => {
			state.getOrderStatusRequest = StatusRequest.fulfilled;
			state.getOrderResponseRequest = action.payload;
		});
		builder.addCase(GetOrderAsync.rejected, (state, action) => {
			state.getOrderStatusRequest = StatusRequest.rejected;
			state.getOrderStatusError = action.payload;
		});
		builder.addCase(GetSubOrderAsync.pending, (state) => {
			state.getSubOrderStatusRequest = StatusRequest.pending;
			state.getSubOrderStatusError = undefined;
		});
		builder.addCase(GetSubOrderAsync.fulfilled, (state, action) => {
			state.getSubOrderStatusRequest = StatusRequest.fulfilled;
			state.getSubOrderResponseRequest = action.payload;
		});
		builder.addCase(GetSubOrderAsync.rejected, (state, action) => {
			state.getSubOrderStatusRequest = StatusRequest.rejected;
			state.getSubOrderStatusError = action.payload;
		});
		builder.addCase(SendOrderAsync.pending, (state) => {
			state.sendOrdersStatusRequest = StatusRequest.pending;
			state.sendOrdersStatusError = undefined;
		});
		builder.addCase(SendOrderAsync.fulfilled, (state, action) => {
			state.sendOrdersStatusRequest = StatusRequest.fulfilled;
			state.sendOrdersStatusResponse = action.payload;
		});
		builder.addCase(SendOrderAsync.rejected, (state, action) => {
			state.sendOrdersStatusRequest = StatusRequest.rejected;
			state.sendOrdersStatusError = action.payload;
		});
	},
});

export const selectOrders = (state: RootState) => state.orders;
export const {} = OrdersSlice.actions;
export default OrdersSlice.reducer;
