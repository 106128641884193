import { FunctionComponent, Fragment } from 'react';
import {
	FormGroup,
	FormControlLabel,
	Checkbox,
	Typography,
	Box,
	Chip,
} from '@mui/material';
import { GetBrandsResponse } from 'data/types/brandsTypes';

type Props = {
	brands: GetBrandsResponse | null;
	handleDelete: (e: any, index: number) => void;
	handleChange: (e: any, index: number) => void;
	checked: { name: string; idx: number }[];
	items: number;
	itemsByBrand: { [key: string]: number } | undefined;
	category: string;
};

const SideBarCatalogView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Box ml={8} mr={4}>
			{/* ver de que endpoint sacamos esta categoria */}
			<Typography
				mb={4}
				marginTop={4}
				fontFamily='Krub'
				fontSize={30}
				color='#FF6651'
				fontWeight='bold'
			>
				{props.category}{' '}
				<span
					style={{
						fontFamily: 'Krub',
						fontSize: '18px',
						color: '#3D363C',
						fontWeight: '700',
					}}
				>
					{props.items} items
				</span>
			</Typography>
			{props.checked ? (
				<div>
					{props.checked.map((value) => {
						return (
							<div>
								{props.itemsByBrand && (
									<div key={value.name}>
										<Chip
											label={value.name}
											variant='outlined'
											onDelete={(e) => props.handleDelete(e, value.idx)}
											style={{ margin: '0px 3px', marginBottom: '3px' }}
										/>
										<span style={{ margin: '0px 3px', marginBottom: '3px' }}>
											{props.itemsByBrand[value.name] > 0 ? props.itemsByBrand[value.name] : 0} items
										</span>
									</div>
								)}
							</div>
						);
					})}
				</div>
			) : null}
			<FormGroup>
				<Typography
					marginTop={6}
					mb={2}
					fontFamily='Krub'
					fontSize={16}
					color='#3D363C'
					fontWeight='bold'
				>
					MARCAS
				</Typography>
				{props.brands &&
					props.brands.map((brand: any, index: number) => {
						return (
							<FormControlLabel
								key={brand.brandID}
								control={
									<Checkbox
										onChange={(e) => props.handleChange(e, index)}
										name={brand.brandName}
										value={brand.brandID}
										checked={
											props.checked.find((ch) => ch.idx === index) !== undefined
										}
										sx={{
											color: '#DEDCDD',
											'&.Mui-checked': {
												color: '#27C451',
											},
										}}
									/>
								}
								label={brand.brandName}
							/>
						);
					})}
			</FormGroup>
		</Box>
	</Fragment>
);

export default SideBarCatalogView;
