import React, { useEffect, useRef, useState } from 'react';
import { ViewCart } from './view';
import useStyles from './useStyles';
import { useGetMyCart } from './hooks';
import { useAppSelector } from 'data/global/hooks';
import { selectCart } from 'data/global/features/CartSlice/cartSlice';
import { StatusRequest } from 'data/types';
import { GetMyCartResponse } from 'data/types/cartTypes';
import useAddToMyCart from './hooks/useAddToCart';
import useSendCart from './hooks/useSendCart';
import { selectOrders } from 'data/global/features/OrdersSlice/ordersSlice';
import useEvaluateCartMinimumByDistributor from './hooks/useEvaluateCartMinimumByDistributor';

export const Cart: React.FC = () => {
	const getMyCart = useGetMyCart();
	const addToCart = useAddToMyCart();
	const sendCart = useSendCart();
    
	const {
        getMyCartStatusRequest,
		getMyCartResponse,
		getMyCartStatusError,
		AddCartStatusRequest,
	} = useAppSelector(selectCart);

	const {
		sendOrdersStatusRequest,
		sendOrdersStatusResponse,
		sendOrdersStatusError,
	} = useAppSelector(selectOrders);

	if (!localStorage.getItem('jwt')) {
		window.location.href = '/register';
	}

    const unCompletedTotalDistribuitors = useEvaluateCartMinimumByDistributor(getMyCartResponse)

	// // manejo de Toast
	// const toast = useRef<any>(null);

	let CartItems = undefined;
	let Distributors: any = [];
	if (getMyCartStatusRequest === StatusRequest.fulfilled) {
		if (
			(getMyCartResponse as GetMyCartResponse).distributorEntities.length < 4
		) {
			const newFakeItems =
				4 - (getMyCartResponse as GetMyCartResponse).distributorEntities.length;
			Distributors = Object.assign(
				[],
				(getMyCartResponse as GetMyCartResponse).distributorEntities
			);
			for (let i = 0; i < newFakeItems; i++) {
				Distributors.push({
					deleted: false,
					delivery: false,
					distributorID: undefined,
					distributorName: '',
					distributorPhoto: '',
					maxDistributionDistance: 0,
				});
			}
		} else {
			Distributors = (getMyCartResponse as GetMyCartResponse)
				.distributorEntities;
		}
		CartItems = { data: getMyCartResponse };
	} else if (getMyCartStatusRequest === StatusRequest.rejected) {
		console.error('Error loading cart', getMyCartStatusError);
	}

	if (AddCartStatusRequest === StatusRequest.fulfilled) {
		// reload cart?
		window.location.reload();
	}

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [scrollDirection, setScrollDirection] =
		React.useState<string>('forward');

	const [deliveryState, setDeliveryState] = useState([
		false,
		false,
		false,
		false,
	]);

	const onClickPickup = (e: any, index: number) => {
		let newDeliveryState = [...deliveryState];
		newDeliveryState[index] = false;
		setDeliveryState(newDeliveryState);
	};

	const onClickDelivery = (e: any, index: number) => {
		let newDeliveryState = [...deliveryState];
		newDeliveryState[index] = true;
		setDeliveryState(newDeliveryState);
	};

	useEffect(() => {
		getMyCart({});
	}, []);

	const [distDP, setDistDP] = useState([0, 0, 0, 0]);

	const [distDA, setDistDA] = useState([false, false, false, false]);

	const [distFP, setDistFP] = useState([0, 0, 0, 0]);
	const [distSeleted, setDistSeleted] = useState(['']);

	useEffect(() => {
		if (getMyCartStatusRequest === 1) {
			const distributorsDeliveryPrices = [0, 0, 0, 0];
			const distributorsDeliveryAllowed = [false, false, false, false];
			const distributorFullPrice = [0, 0, 0, 0];
			let arrayDistributorsSelect: any[] = [];
			getMyCartResponse?.distributorEntities.forEach((dist, idx) => {
				distributorsDeliveryPrices[idx] = dist.deliveryPrice
					? dist.deliveryPrice
					: 0;
				distributorsDeliveryAllowed[idx] = dist.delivery;
				getMyCartResponse.itemList.forEach((item) => {
					const catalogProduct = item.catalogProductList[idx];
					if (catalogProduct && catalogProduct.selected) {
						arrayDistributorsSelect.push(Distributors[idx].distributorName);
						distributorFullPrice[idx] += catalogProduct.price * item.qtty;
					}
				});
			});
			setDistDP(distributorsDeliveryPrices);
			setDistDA(distributorsDeliveryAllowed);
			setDistFP(distributorFullPrice);
			setDistSeleted(arrayDistributorsSelect);
		}
	}, [getMyCartStatusRequest]);

	const [loading, setLoading] = useState(false);

	const updateItem = (quantity: number, catalogProduct: any) => {
		addToCart({
			catalogProductId: catalogProduct.catalogProductId,
			quantity,
		});
		setLoading(true);
	};

	const generateOrden = (event: React.MouseEvent<HTMLElement>) => {
		sendCart({
			distributorsDelivery: [true, true, true, true],
		});
		setAnchorEl(event.currentTarget);
	};

	if (sendOrdersStatusRequest === StatusRequest.fulfilled) {
		if (sendOrdersStatusResponse?.created) {
			window.location.href = `/OrderDetail/${sendOrdersStatusResponse?.orderID}`;
		} else {
			window.location.reload();
		}
	} else if (
		sendOrdersStatusRequest === StatusRequest.rejected &&
		!sendOrdersStatusError?.message?.includes('minimo de compra')
	) {
		alert('Ocurrio un error'); // TODO: cambiar esto por un popup.
	}

	const cartRef = useRef<HTMLDivElement>(null);

	const handleScrollRight = () => {
		if (cartRef.current) {
			const scrollWidth = cartRef.current.scrollWidth;
			const scrollLeft = cartRef.current.scrollLeft;
			const clientWidth = cartRef.current.clientWidth;

			if (scrollLeft + clientWidth >= scrollWidth) {
				cartRef.current.scrollTo({ left: 0, behavior: 'smooth' });
				setScrollDirection('forward');
			} else {
				cartRef.current.scrollTo({ left: scrollWidth, behavior: 'smooth' });
				setScrollDirection('back');
			}
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	let DistriSeleted = distSeleted.filter((valor, indice) => {
		return distSeleted.indexOf(valor) === indice;
	});

    

	return (
		<ViewCart
			cartRef={cartRef}
			scrollDirection={scrollDirection}
			classes={classes}
			cartItems={CartItems}
			distributors={Distributors}
			loading={loading}
			onClickPickup={onClickPickup}
			onClickDelivery={onClickDelivery}
			deliveryState={deliveryState}
			updateItem={updateItem}
			generateOrden={generateOrden}
			open={open}
			handleClose={handleClose}
			anchorEl={anchorEl}
			id={id}
            unCompletedTotalDistribuitors={unCompletedTotalDistribuitors}
			distributorsDeliveryPrices={distDP}
			distributorsDeliveryAllowed={distDA}
			distributorFullPrice={distFP}
			distribuitorsSeleted={DistriSeleted}
			handleRight={handleScrollRight}
		></ViewCart>
	);
};
