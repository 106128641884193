import { useState, useEffect } from 'react';
const useEvaluateCartMinimumByDistributor = (cartData: any) => {
	const [unCompletedTotalDistributors, setUnCompletedTotalDistributors] =
		useState<any[]>([]);
	useEffect(() => {
		const evaluateCartByDistributor = () => {
			if (!cartData) return null;
			const distributorsBelow: any[] = [];
			const distributorTotals: any = {};
			cartData.itemList.forEach((item: any) => {
				const selectedProduct = item.catalogProductList.find(
					(p: any) => p.selected
				);
				if (selectedProduct && selectedProduct.distributorID) {
					if (!distributorTotals[selectedProduct.distributorID]) {
						distributorTotals[selectedProduct.distributorID] = 0;
					}
					distributorTotals[selectedProduct.distributorID] +=
						item.price * item.qtty;
				}
			});
			cartData.distributorEntities.forEach((distributor: any) => {
				const total = distributorTotals[distributor.distributorID] || 0;
				if (
					total > 0 &&
					(total < distributor.minimumDeliveryPrice ||
						total < distributor.minimumPickUpPrice)
				) {
					distributorsBelow.push({
						id: distributor.distributorID,
						name: distributor.distributorName,
					});
				}
			});
			return distributorsBelow;
		};
		const distributorsBelow = evaluateCartByDistributor();
		if (distributorsBelow) {
			setUnCompletedTotalDistributors(distributorsBelow);
		}
	}, [cartData]);
	return unCompletedTotalDistributors;
};
export default useEvaluateCartMinimumByDistributor;
