import React from 'react';
import { ViewPasswordRecovery } from './view';
import useStyles from '../Register/useStyles';
import { useAppSelector } from '../../data/global/hooks';
import { selectUser } from '../../data/global/features/UserSlice/userSlice';

export const PasswordRecovery: React.FC = () => {
	const classes = useStyles();

	const { createUserStatusRequest } =
		useAppSelector(selectUser);
	return <ViewPasswordRecovery classes={classes} createUserStatusRequest={createUserStatusRequest!} />;
};
