import { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
	Layout,
	NavBarRegister,
	RightSectionRegisterLibrary,
} from 'components';
type Props = {};

export const RegisterLibraryView: FunctionComponent<Props> = (props) => (
	<Layout
		leftSection={LeftSection}
		rightSection={<RightSectionRegisterLibrary />}
		backgroundImage='Background03'
		leftSize={5}
		rightSize={7}
	>
		<NavBarRegister navRegisterLibrary={true}></NavBarRegister>
	</Layout>
);
export const LeftSection = (
	<Box>
		<Grid container pt={16}>
			<Grid item xs={11}>
				<Typography
					variant='h4'
					color='white'
					fontFamily={'Krub'}
					fontWeight={'bold'}
				>
					Estás muy cerca de finalizar tu registro en DeÚtiles
				</Typography>
			</Grid>
		</Grid>
		<Grid item xs={11} mt={4}>
			<Typography
				variant='h6'
				color='white'
				fontFamily={'Krub'}
				fontWeight={'bold'}
			>
				¡Completá los siguientes datos… y listo!
			</Typography>
		</Grid>
	</Box>
);
