import { FunctionComponent } from 'react';
import { Toast as ToastPrime, ToastProps } from 'primereact/toast';

type Props = {
	toast: any;
} & ToastProps;

export const Toast: FunctionComponent<Props> = ({ ...props }) => (
	<ToastPrime ref={props.toast}>{props.children}</ToastPrime>
);
