import { Fragment, FunctionComponent } from 'react';
import { Button, CloseButtonInput } from 'UI';
import { Input } from 'UI/Input';
import { Typography, Box, Grid, Alert, CircularProgress } from '@mui/material';
import { ControlProps, FormProps } from 'models/form';
import { CreateUserRequest } from 'data/types/userTypes';
import { AttachMoney, Close } from '@mui/icons-material';

type Props = {
	classes: any;
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	createUser: (user: CreateUserRequest) => void;
	isValidPassword: {
		hasNumber: boolean;
		hasSpecialCharacter: boolean;
		lengthValid: boolean;
	};
	refInputs: any;
	loading: boolean;
};

const RegisterFormView: FunctionComponent<Props> = (props) => (
	<Fragment>
		{props.loading ? (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</div>
		) : (
			<Fragment>
				<Grid container>
					<Grid item xs={6} pr={1}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.name,
								name: 'name',
							}}
							getValues={props.formProps.getValues}
							setValue={props.formProps.setValue}
							inputRef={props.refInputs.nameRef}
							errors={props.formProps.errors}
							type='text'
							placeholder='Nombre'
							autoComplete='new-password'
						/>
					</Grid>
					<Grid item xs={6} pl={1}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.lastName,
								name: 'lastName',
							}}
							getValues={props.formProps.getValues}
							setValue={props.formProps.setValue}
							inputRef={props.refInputs.lastNameRef}
							errors={props.formProps.errors}
							type='text'
							placeholder='Apellido'
							autoComplete='new-password'
						/>
					</Grid>
					<Grid item xs={12} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email,
								name: 'email',
								rules: props.controlProps.rules.rulesEmail,
							}}
							getValues={props.formProps.getValues}
							setValue={props.formProps.setValue}
							inputRef={props.refInputs.emailRef}
							errors={props.formProps.errors}
							type='email'
							placeholder='Email'
							autoComplete='new-password'
						/>
					</Grid>
					<Grid item xs={12} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.password,
								name: 'password',
							}}
							getValues={props.formProps.getValues}
							setValue={props.formProps.setValue}
							inputRef={props.refInputs.passwordRef}
							errors={props.formProps.errors}
							type='password'
							placeholder='Contraseña'
							autoComplete='new-password'
						/>
					</Grid>
					<Grid item xs={12} mt={2}>
						<Input
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.repeatPassword,
								name: 'password2',
							}}
							getValues={props.formProps.getValues}
							setValue={props.formProps.setValue}
							inputRef={props.refInputs.password2Ref}
							errors={props.formProps.errors}
							type='password'
							placeholder='Repetir Contraseña'
							autoComplete='new-password'
						/>
						{props?.error?.error && (
							<Alert severity='error' style={{ marginTop: '1.5em' }}>
								{props?.error?.error}
							</Alert>
						)}
					</Grid>
				</Grid>
				<Box pt={3}>
					<Typography
						variant='body2'
						fontFamily={'var(--unnamed-font-family-krub)'}
						fontWeight={'bold'}
						color={'#FF6651'}
					>
						La contraseña debe tener
					</Typography>
					<Grid container direction='row'>
						<i
							className='icon-check'
							style={{
								color: props.isValidPassword.lengthValid ? 'green' : 'grey',
							}}
						></i>
						<Typography
							variant='body2'
							fontFamily={'var(--unnamed-font-family-krub)'}
							fontWeight={'normal'}
							color={'#80777E'}
							pl={0.5}
						>
							Al menos 6 caracteres
						</Typography>
					</Grid>
					<Grid container direction='row'>
						<i
							className='icon-check'
							style={{
								color: props.isValidPassword.hasNumber ? 'green' : 'grey',
							}}
						></i>
						<Typography
							variant='body2'
							fontFamily={'var(--unnamed-font-family-krub)'}
							fontWeight={'normal'}
							color={'#80777E'}
							pl={0.5}
						>
							Incluir al menos un número
						</Typography>
					</Grid>
					<Typography
						variant='body2'
						fontFamily={'var(--unnamed-font-family-krub)'}
						fontWeight={'normal'}
						color={'#80777E'}
						mt={4}
					>
						Creando una cuenta, estas aceptando los
						<span className={props.classes.policies}>
							{' '}
							Términos y condiciones{' '}
						</span>{' '}
						y las
						<span className={props.classes.policies}>
							Políticas de privacidad y Cookies.
						</span>
					</Typography>
					<Grid container justifyContent='flex-end' pr={3} pt={4}>
						<Button
							variant='contained'
							size='small'
							style={{
								textTransform: 'none',
							}}
							sx={{ borderRadius: '20px' }}
							disableElevation
							onClick={props.formProps.handleSubmit(props.createUser)}
						>
							<Typography color='white' variant='body2' p={0.8}>
								Verificar Email
							</Typography>
						</Button>
					</Grid>
				</Box>
			</Fragment>
		)}
	</Fragment>
);

export default RegisterFormView;
