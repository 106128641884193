import { useAppDispatch } from 'data/global/hooks';
import { QttyCartAsync } from 'data/global/features/CartSlice/cartSlice';

export const useGetQttyCart = () => {
	const dispatch = useAppDispatch();

	const getQttyCart = (id: string): void => {
		dispatch(QttyCartAsync({ masterProductId: id }));
	};

	return getQttyCart;
};

export default useGetQttyCart;
