import { useAppDispatch } from 'data/global/hooks';
import { GetMyCartAsync } from 'data/global/features/CartSlice/cartSlice';

export const useGetMyCart = () => {
	const dispatch = useAppDispatch();

	const getMyCart = (req: {}): void => {
		dispatch(GetMyCartAsync(req));
	};

	return getMyCart;
};

export default useGetMyCart;
