import { useAppDispatch } from 'data/global/hooks';
import { PutResendEmailAsync } from 'data/global/features/UserSlice/userSlice';
import { ResendEmailRequest } from 'data/types/userTypes';

export const useResendEmail = () => {
	const dispatch = useAppDispatch();

	const resendEmail = (resend: ResendEmailRequest): void => {
		dispatch(PutResendEmailAsync(resend));
	};

	return resendEmail;
};

export default useResendEmail;
