import { FunctionComponent } from 'react';
import { Layout, NavBarRegister, RightFormRegister, VerifyEmail } from '../../components';
import { StatusRequest } from '../../data/types';
import { LeftSection } from '../Register/view';
import { PasswordRecovery } from '../../components/PasswordRecovery';

type Props = {
	classes: any;
	createUserStatusRequest: StatusRequest
};

export const ViewPasswordRecovery: FunctionComponent<Props> = (props) => (
	<Layout
		leftSection={LeftSection}
		rightSection={<PasswordRecovery />}
		leftSize={8}
		rightSize={4}
		backgroundImage='Background01'
	>
		<NavBarRegister></NavBarRegister>
	</Layout>
);
