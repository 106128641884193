import { useAppDispatch } from 'data/global/hooks';
import { RecoverUserPasswordAsync } from 'data/global/features/UserSlice/userSlice';
import { RecoverPasswordUserRequest } from 'data/types/userTypes';

export const useRecoverPassword = () => {
	const dispatch = useAppDispatch();

	const recoverPassword = (request: RecoverPasswordUserRequest): void => {
		dispatch((RecoverUserPasswordAsync(request)));
	};

	return recoverPassword;
};

export default useRecoverPassword;
