import {
	GetProductCatalogAsync,
	selectCatalog,
} from 'data/global/features/CatalogSlice/catalogSlice';
import { useAppDispatch, useAppSelector } from 'data/global/hooks';
import { Brand } from 'data/types/brandsTypes';
import { GetProductCatalogRequest } from 'data/types/catalogTypes';
import React, { useEffect, useState } from 'react';
import { CatalogView } from './view';

export const Catalog: React.FC = () => {
	const dispatch = useAppDispatch();

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	const { getProductCatalogStatusRequest } = useAppSelector(selectCatalog);

	const [loadingCart, setLoadingCart] = useState(false);

	const [page, setPage] = useState(1);

	const qParam: GetProductCatalogRequest = {};

	qParam.page = page;

	if (params.category) {
		qParam.category = params.category; // 634858bd3b8e950f52ac8f6f
	}
	if (params.brand) {
		qParam.brand = params.brand; // 634858bd3b8e950f52ac8f6f
	}
	if (params.q) {
		qParam.query = params.q; // 634858bd3b8e950f52ac8f6f
	}

	useEffect(() => {
		//TODO: Filtrado
		dispatch(GetProductCatalogAsync(qParam));
		setLoadingCart(true);
		if (params.page) {
			setPage(page);
		}
	}, []);

	useEffect(() => {
		dispatch(GetProductCatalogAsync(qParam));
	}, [page]);

	const onUpdateFilters = (filters: Brand[]) => {
		qParam.brand = filters.map((filter) => filter.brandID).join(',');
		dispatch(GetProductCatalogAsync(qParam));
		composeUrl(qParam);
		setLoadingCart(true);
	};

	useEffect(() => {
		if (getProductCatalogStatusRequest === 1) {
			setLoadingCart(false);
		}
	}, [getProductCatalogStatusRequest]);

	const composeUrl = (params: GetProductCatalogRequest) => {
		let baseURL = '/catalog';
		let paramList = [];
		if (params.category) {
			paramList.push(`category=${params.category}`);
		}
		if (params.brand) {
			paramList.push(`brand=${params.brand}`);
		}
		if (params.query) {
			paramList.push(`query=${params.query}`);
		}
		paramList.push(`page=${page - 1}`);
		if (paramList.length > 0) {
			baseURL += `?${paramList.join('&')}`;
		}
		window.history.pushState('', '', baseURL);
	};

	const { getProductCatalogResponseRequest } = useAppSelector(selectCatalog);

	return (
		<CatalogView
			query={qParam.query}
			loadingCart={loadingCart}
			onUpdateFilters={onUpdateFilters}
			catalogResponse={getProductCatalogResponseRequest}
			page={page}
			setPage={setPage}
		/>
	);
};
