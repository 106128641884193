import React from 'react';
import { ViewRegister } from './view';
import useStyles from './useStyles';
import { useAppSelector } from 'data/global/hooks';
import { selectUser } from 'data/global/features/UserSlice/userSlice';

export const Register: React.FC = () => {
	const classes = useStyles();

	const { createUserStatusRequest } =
		useAppSelector(selectUser);
		

	return <ViewRegister classes={classes} createUserStatusRequest={createUserStatusRequest!} />;
};
