import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllDistributorsRequest,
	GetAllDistributorsResponse,
	PutDistributorRequest,
	PutDistributorResponse,
} from 'data/types/distributorsTypes';
import { Either } from 'models/either';

export const getAllDistributors = async (): Promise<
	Either<Failure, GetAllDistributorsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllDistributorsRequest, GetAllDistributorsResponse>({
			url: '/api/v1/distributors',
			secure: true,
		})
	);
};

export const putDistributor = async (
	request: PutDistributorRequest
): Promise<Either<Failure, PutDistributorResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutDistributorRequest, PutDistributorResponse>({
			url: `/api/v1/distributors`,
			request,
			secure: true,
		})
	);
};

const DistributorsApi = {
	getAllDistributors,
	putDistributor,
};

export default DistributorsApi;
