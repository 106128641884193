import React from 'react';
import CartButtonView from './view';

const cartItem = {
	data: {
		cartID: 'string',
		created: '2022-09-29T18:14:02.585Z',
		itemList: [
			{
				cartItem: {
					cartID: 'string',
					cartItemID: 'string',
					catalogProductID: 'string',
					masterProductID: 'string',
					price: 0,
					qtty: 0,
				},
				masterProduct: {
					brandID: 'string',
					categoryID: 'string',
					created: '2022-09-29T18:14:02.585Z',
					deleted: true,
					description: 'string',
					ean: 'string',
					highlight: true,
					indexStatus: 'string',
					indexed: true,
					masterProductId: 'string',
					name: 'string',
					photo: 'string',
					tags: ['string'],
				},
			},
		],
		items: 12,
	},
	error: true,
	errorName: 'string',
	message: 'string',
	path: 'string',
	status: 0,
	timestamp: 'string',
};

export const CartButton: React.FC = () => {
	return <CartButtonView cartItem={cartItem} />;
};

export default CartButton;
