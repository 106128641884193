import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import {
	Distributor,
	GetAllDistributorsRequest,
	GetAllDistributorsResponse,
	PutDistributorRequest,
	PutDistributorResponse,
} from 'data/types/distributorsTypes';
import { Either, isRight, unwrapEither } from 'models/either';
import DistributorsApi from 'data/api/distributorsApi';

type TdistributorSlice = {
	distributors: Distributor[]
	distributorStatusRequest?: StatusRequest;
	distributorStatusError?: Failure;
	putDistributorStatusRequest?: StatusRequest;
	putDistributorStatusError?: Failure;
};

const initialState: TdistributorSlice = {
	distributors: [],
	distributorStatusRequest: StatusRequest.initial,
	distributorStatusError: undefined,
	putDistributorStatusRequest: StatusRequest.initial,
	putDistributorStatusError: undefined,
};

export const GetAllDistributorsAsync = createAsyncThunk<
	GetAllDistributorsResponse,
	GetAllDistributorsRequest,
	{ rejectValue: Failure }
>(
	'auth/GetAllDistributorsAsync',
	async (request: GetAllDistributorsRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, GetAllDistributorsResponse> =
				await DistributorsApi.getAllDistributors();
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response getAllDistributors');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const PutDistributorAsync = createAsyncThunk<
	PutDistributorResponse,
	PutDistributorRequest,
	{ rejectValue: Failure }
>(
	'user/PutDistributorAsync',
	async (request: PutDistributorRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, PutDistributorResponse> =
				await DistributorsApi.putDistributor(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response putDistributor');
				localStorage.setItem('distributorsSelected','true');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const DistributorSlice = createSlice({
	name: 'distributor',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(GetAllDistributorsAsync.pending, (state) => {
			state.distributorStatusRequest = StatusRequest.pending;
			state.distributorStatusError = undefined;
		});
		builder.addCase(GetAllDistributorsAsync.fulfilled, (state, action) => {
			state.distributorStatusRequest = StatusRequest.fulfilled;
			state.distributors = action.payload;
		});
		builder.addCase(GetAllDistributorsAsync.rejected, (state, action) => {
			state.distributorStatusRequest = StatusRequest.rejected;
			state.distributorStatusError = action.payload;
		});
		builder.addCase(PutDistributorAsync.pending, (state) => {
			state.putDistributorStatusRequest = StatusRequest.pending;
			state.putDistributorStatusError = undefined;
		});
		builder.addCase(PutDistributorAsync.fulfilled, (state, action) => {
			state.putDistributorStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutDistributorAsync.rejected, (state, action) => {
			state.putDistributorStatusRequest = StatusRequest.rejected;
			state.putDistributorStatusError = action.payload;
		});
	},
});

export const selectDistributor = (state: RootState) => state.distributor;
export const {} = DistributorSlice.actions;
export default DistributorSlice.reducer;
