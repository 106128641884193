import { FunctionComponent, Fragment } from 'react';
import {
	Typography,
	CardMedia,
	CardActions,
	CardContent,
	CircularProgress,
} from '@mui/material';
import { Card, Button } from 'UI';
import { CatalogProduct, MasterPodruct } from 'data/types/catalogTypes';
import style from './Card.module.css';
import { QuantitySelector } from 'components';
import { Box } from '@mui/system';

type Props = {
	catalogProduct: CatalogProduct;
	masterPodruct: MasterPodruct;
	onClick?: (e: any) => void;
	clickUpdateProduct: (qtty: number, cpid: any) => void;
	qttyInCart: number;
	qttyCartCatalog?: any;
	priceInCart?: any;
	classes: any;
	catalogProductID: string;
	loading: boolean;
  handleQuantityConfirm:()=> void;
  quantityValue: number;
  quantitySetter: React.Dispatch<React.SetStateAction<number>>;
  setEditingQuantity: React.Dispatch<React.SetStateAction<boolean>>;
  editingQuantity: boolean;
};

const CardView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Card sx={{ margin: '1vw', width: '300px', height: '500px' }}>
			<CardMedia
				component='img'
				alt='Product-Highlight'
				height='220'
				image={props.masterPodruct.photo}
        sx={{ marginTop: '20px', objectFit: "contain" }}
			/>
			<CardContent>
				<a
					href={`/viewItem/${props.masterPodruct.masterProductId}`}
					style={{ textDecoration: 'none' }}
				>
					<Typography
						fontFamily='Krub'
						fontSize='12px'
						color='#5C41BD'
						fontWeight='bold'
					>
						{props.masterPodruct.brandName}
					</Typography>
					<Typography
						fontFamily='Krub'
						fontSize='20px'
						color='#3D363C'
						mb={2}
						height='60px'
					>
						{props.masterPodruct.name}
					</Typography>
					<Typography
						fontFamily='Krub'
						fontSize='12px'
						color='#80777E'
						fontWeight='bold'
					>
						{props.catalogProduct.distributorName}
					</Typography>
					<Typography
						fontFamily='Krub'
						fontSize='20px'
						color='#3D363C'
						fontWeight='bold'
					>
						{localStorage.getItem('jwt') ? "$" + props.catalogProduct.price.toFixed(2) : <span style={{ fontSize: '0.9rem' }} >Registrate para ver este precio</span>}
					</Typography>
				</a>
			</CardContent>
			{props.loading ? (
				<CardActions className={props.classes.styleCardActions}>
					<CircularProgress />
				</CardActions>
			) : (
				<CardActions className={props.classes.styleCardActions}>
					{((!props.qttyInCart|| props.qttyInCart <= 0) && !props.editingQuantity) ? (
						<Button
							variant='contained'
							size='small'
							sx={{ borderRadius: '20px' }}
							disableElevation
							onClick={props.onClick}
							className={props.classes.button}
						>
							<Typography
								color='white'
								p={0.8}
								display='flex'
								alignItems='center'
								fontFamily='Krub'
								fontWeight='bold'
								fontSize='18px'
							>
								Agregar
								<i
									className='icon-add'
									style={{ fontSize: '28px', marginLeft: '20px' }}
								></i>
							</Typography>
						</Button>
					) : (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                  <QuantitySelector
                    handleQuantityConfirm={props.handleQuantityConfirm}
                    value={props.quantityValue}
                    quantitySetter={props.quantitySetter}
                    setEditingQuantity={props.setEditingQuantity} 
                    withButton={false}                  />
                </Box>
					)}
				</CardActions>
			)}
		</Card>
	</Fragment>
);

export default CardView;
