import {
	Box,
	Container,
	Drawer,
	Grid,
	Typography,
	CircularProgress,
} from '@mui/material';
import { ItemProductModal } from 'components/ItemProductModal';
import { Fragment, FunctionComponent } from 'react';

export type ProductDescriptionOrder = {
	itemName: string;
	catalogProductID: string;
	qtty: number;
	price: number;
};

type Props = {
	products: any;
	openModal: boolean;
	setOpenModal: any;
	classes: any;
	items: number;
};

export const ViewModalDescriptionOrder: FunctionComponent<Props> = (props) => (
	<Fragment>
		{props.products ? (
			<Drawer
				anchor={'right'}
				open={props.openModal}
				onClose={() => {
					props.setOpenModal(false);
				}}
			>
				<Box bgcolor={'#0000000D'} py={5} px={2}>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Box>
							<Typography display='inline' mr={1}>
								<i
									className='icon-cart'
									style={{
										color: '#FF6651',
									}}
								></i>
							</Typography>

							<Typography
								display='inline'
								mr={1}
								fontWeight={600}
								fontSize={20}
							>
								{props.products.distributorName}
							</Typography>

							<Typography display='inline' mr={1}>
								{props.items} Items
							</Typography>
						</Box>

						<Box>
							<i
								className='icon-clear'
								onClick={() => {
									props.setOpenModal(false);
								}}
								style={{
									color: '#80777E',
								}}
							></i>
						</Box>
					</Grid>
				</Box>
				<Box>
					{props.products.items.map((product: ProductDescriptionOrder) => {
						return (
							<ItemProductModal
								product={product}
								key={product.catalogProductID}
							/>
						);
					})}
				</Box>
			</Drawer>
		) : (
			<CircularProgress />
		)}
	</Fragment>
);
