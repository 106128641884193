import { AddCartAsync } from "data/global/features/CartSlice/cartSlice";
import { useAppDispatch } from "data/global/hooks";
import { AddCartRequest } from "data/types/cartTypes";

export const useAddToMyCart = () => {
	const dispatch = useAppDispatch();

	const getMyCart = (req: AddCartRequest): void => {
		dispatch(AddCartAsync(req));
	};

	return getMyCart;
};

export default useAddToMyCart;