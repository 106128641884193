import {
	Typography,
	Stepper,
	Step,
	StepLabel,
	Divider,
	Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { ModalDescriptionOrder } from 'components/ModalDescriptionOrder';
import { FunctionComponent } from 'react';

type Props = {
	openModal: boolean;
	setOpenModal: any;
	subOrder?: any;
	status?: string;
	facturacion?: string;
	items: number;
	total?: any;
	entrega?: string;
	getSuborderItems: (subOrder: string) => any;
};

const CustomStepIcon = (props: { active: boolean; completed: boolean }) => {
	const { active, completed } = props;

	return (
		<Box
			height={10}
			width={10}
			bgcolor={active || completed ? '#5C41BD' : '#DEDCDD'}
			sx={{ borderRadius: 20 }}
		></Box>
	);
};

export const ViewColumnOrderDetailDistributor: FunctionComponent<Props> = (
	props
) => (
	<Box height='60vh'>
		<Typography>Sub Cotización</Typography>

		<Typography color={'#5C41BD'} fontSize={16} fontWeight={600}>
			{props.subOrder.slice(-10)}
		</Typography>

		<Typography mt={2}>Estado</Typography>

		<Box mt={1} mb={1}>
			<Stepper activeStep={1}>
				<Step>
					<StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
				</Step>
			</Stepper>
		</Box>

		<Typography color={'#5C41BD'} fontSize={15} fontWeight={600} mb={4}>
			{props.status}
		</Typography>

		<Divider></Divider>

		<Typography mt={2}>Entrega</Typography>

		<Typography color={'#5C41BD'} fontSize={15} fontWeight={600} mb={2}>
			{props.entrega}
		</Typography>

		<Divider></Divider>

		<Typography mt={2}>Facturación</Typography>

		<Typography color={'#5C41BD'} fontSize={15} fontWeight={600} mb={2}>
			{props.facturacion}
		</Typography>

		<Divider></Divider>

		<Grid container direction='row' justifyContent='space-between' pr={2}>
			<Typography mt={2}>Items</Typography>
			<Box mt={2.3}>
				<i
					className='icon-eye'
					onClick={() => {
						props.setOpenModal(true);
						props.getSuborderItems(props.subOrder);
					}}
					style={{
						color: '#5C41BD',
						width: 20,
					}}
				></i>
			</Box>
		</Grid>

		<Typography color='#FF6651' fontWeight={600}>
			{props.items}
		</Typography>

		<ModalDescriptionOrder
			setOpenModal={props.setOpenModal}
			openModal={props.openModal}
			items={props.items}
		/>
	</Box>
);
