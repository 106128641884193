import React from 'react';
import HomeLayoutView from './view';
import useStyles from './useStyles';
import { FunctionComponent } from 'react';

interface Props {
	login: string | null;
}

export const HomeLayout: FunctionComponent<Props> = ({ login }) => {
	const classes = useStyles();
	return <HomeLayoutView classes={classes} login={login} />;
};
