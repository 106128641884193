import { Fragment, FunctionComponent } from 'react';
import { Button, Card, Icon, TextField } from 'UI';
import { ControlProps, FormProps } from 'models/form';
import { Input } from 'UI/Input';

import { Typography, Box, CardContent, Grid, CardHeader, Alert, CircularProgress } from '@mui/material';
import { ChangePasswordRequest } from '../../data/types/userTypes';
import { Failure } from '../../data/types';
type Props = {
	classes: any;
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	response?: Failure;
	changePassword:(passwordRequest: ChangePasswordRequest) => void;
	refInputs: any;
	isValidPassword: {
		hasNumber: boolean;
		hasSpecialCharacter: boolean;
		lengthValid: boolean;
	};
	loading: boolean;
};

const ResetPasswordView: FunctionComponent<Props> = (props) => (

	<Fragment>
		{props.loading ? (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</div>
		) : (
			<Fragment>
	<Box>
		<Grid container justifyContent='center'>
			<Grid item xs={4}>
				<Card variant='outlined'>
					<CardHeader></CardHeader>
					<CardContent sx={{ mt: 10, mb: 6 }}>
						<Box pl={12}>
							<Typography
								fontFamily={'var(--unnamed-font-family-krub)'}
								fontSize={22}
								color='#FF6651'
								fontWeight='bold'
								align='left'
								mb={2}
							>
								Reestablecé tu contraseña
							</Typography>
							<Typography
								fontFamily={'var(--unnamed-font-family-krub)'}
								fontSize={16}
								color='#3D363C'
								fontWeight='normal'
								align='left'
								mb={4}
								variant='body2'
							>
								Generá una nueva contraseña para tu cuenta.
							</Typography>
						</Box>
						<Grid container justifyContent='center'>
							<Grid item xs={8}>
								<Input
									controllerProps={{
										control: props.controlProps.control,
										defaultValue: props.controlProps.defaultValue.newPassword,
										name: 'password',
									}}
									getValues={props.formProps.getValues}
									setValue={props.formProps.setValue}
									inputRef={props.refInputs.passwordRef}
									errors={props.formProps.errors}
									type='password'
									placeholder='Nueva Contraseña'
									autoComplete='new-password'
								/>
							</Grid>
							<Grid item xs={8} mt={2}>
								<Input
									controllerProps={{
										control: props.controlProps.control,
										defaultValue: props.controlProps.defaultValue.repeatPassword,
										name: 'repeatPassword',
									}}
									getValues={props.formProps.getValues}
									setValue={props.formProps.setValue}
									inputRef={props.refInputs.password2Ref}
									errors={props.formProps.errors}
									type='password'
									placeholder='Repetir nueva contraseña'
									autoComplete='new-password'
								/>
								{props?.response?.error && (
									<Alert severity='error' style={{ marginTop: '1.5em' }}>
										{props?.response?.message}
									</Alert>
								)}

								<Input
									controllerProps={{
										control: props.controlProps.control,
										defaultValue:
										props.controlProps.defaultValue.code,
										name: 'code',
									}}
									type='hidden'
								 errors={props.formProps.errors}/>
							</Grid>
						</Grid>
						<Box pl={12} pt={2}>
								<Typography
									variant='body2'
									fontFamily={'var(--unnamed-font-family-krub)'}
									fontWeight={'bold'}
									color={'#FF6651'}
								>
									La contraseña debe tener
								</Typography>
								<Grid container direction='row'>
									<i
										className='icon-check'
										style={{
											color: props.isValidPassword.lengthValid ? 'green' : 'grey',
										}}
									></i>
									<Typography
										variant='body2'
										fontFamily={'var(--unnamed-font-family-krub)'}
										fontWeight={'normal'}
										color={'#80777E'}
										pl={0.5}
									>
										Al menos 6 caracteres
									</Typography>
								</Grid>
								<Grid container direction='row'>
									<i
										className='icon-check'
										style={{
											color: props.isValidPassword.hasNumber ? 'green' : 'grey',
										}}
									></i>
									<Typography
										variant='body2'
										fontFamily={'var(--unnamed-font-family-krub)'}
										fontWeight={'normal'}
										color={'#80777E'}
										pl={0.5}
									>
										Incluir al menos un número
									</Typography>
								</Grid>
						</Box>
						<Grid container justifyContent='flex-end' pr={16} pt={6}>
							<Button
								variant='contained'
								size='large'
								style={{
									textTransform: 'none',
								}}
								sx={{ borderRadius: '20px' }}
								disableElevation
								onClick={props.formProps.handleSubmit(props.changePassword)}
							>
								<Typography color='white' variant='body2' p={0.8}>
									Confirmar
								</Typography>
							</Button>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	</Box>
			</Fragment>
		)}
			</Fragment>
);

export default ResetPasswordView;
