import { FunctionComponent, Fragment } from 'react';
import { Typography, Box } from '@mui/material';
import { Button } from 'UI';

type Props = { classes: any, qtty: number, catalogProductID: string, clickUpdateProduct: (qtty: number) => void; };

const ButtonAddProductsView: FunctionComponent<Props> = (props) => (
	<Fragment>
		<Box className={props.classes.containerButton}>
			<Button
					onClick={() => {props.clickUpdateProduct(-1)}}
				>
				<i
					className='icon-remove'
					style={{ fontSize: '28px', color: 'white', marginRight: '2em' }}
				></i>
			</Button>
			<Typography color='white'>{props.qtty}</Typography>
			<Button
				onClick={() => {props.clickUpdateProduct(1)}}
			>
				<i
					className='icon-add'
					style={{ fontSize: '28px', color: 'white', marginLeft: '2em' }}
				></i>
			</Button>
		</Box>
	</Fragment>
);

export default ButtonAddProductsView;
