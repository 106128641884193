import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	loading: {
		padding: '2vw',
		background: 'linear-gradient(57deg, #ff6651, #5c41bd)',
		borderRadius: '1vw',
		margin: '2vw',
		textAlign: 'center',
		color: 'white',
		fontSize: '1.5vw',
		boxShadow: '0px 0px 15px #c9c9c9',
	},

	cardAside: {
		top: '0px',
		right: '0px',
		zIndex: 1,
		position: 'absolute',
		width: '50px',
		textAlign: 'right',
		color: 'white',
		height: '55px',
		overflow: 'hidden',
		lineHeight: 1,
		display: 'flex',
		justifyContent: 'right',
		alignItems: 'end',
		'&::after': {
			top: '-25px',
			right: '-25px',
			width: '50px',
			height: '50px',
			content: '""',
			display: 'block',
			zIndex: '-1',
			position: 'absolute',
			transform: 'rotate(45deg)',
			background: '#FF6651',
		},
	},
}));
export default useStyles;
